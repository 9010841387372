import { Injectable, inject } from '@angular/core';
import {
  CreatePlaylistGQL,
  CreatePlaylistInput,
  DeletePlaylistsGQL,
  GetPlaylistDetailedGQL,
  GetProfilePlaylistsGQL,
  Maybe,
  Playlist,
  PlaylistDetailedFragment,
  PlaylistStatus,
  UpdatePlaylistGQL,
  UpdatePlaylistInput,
} from '@designage/gql';
import { localStorageKeys } from '@desquare/constants';
import { CurrentUserService } from '@desquare/services';
import { GraphQLFormattedError } from 'graphql';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, lastValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlaylistService {
  private localStorageService = inject(LocalStorageService);

  statuses: PlaylistStatus[] = [
    PlaylistStatus.Draft,
    PlaylistStatus.Published,
    PlaylistStatus.ReadyToPublish,
  ];

  private getProfilePlaylistsGql = inject(GetProfilePlaylistsGQL);
  private getProfilePlaylistsGql$ = this.getProfilePlaylistsGql.watch(
    {
      profileId: inject(CurrentUserService).getCurrentProfileId(),
      statuses: this.statuses,
      onlyParentPlaylist: true,
    },
    { fetchPolicy: 'cache-and-network' }
  );
  private getPlaylistDetailedGql = inject(GetPlaylistDetailedGQL);

  getDetailedPlaylist$(id: string): Observable<{
    playlist: Playlist;
    loading: boolean;
    errors: readonly GraphQLFormattedError<Record<string, any>>[] | undefined;
  }> {
    return this.getPlaylistDetailedGql.fetch({ id }).pipe(
      map(({ data, loading, errors }) => {
        const playlist = data.playlist as Playlist;
        return { playlist, loading, errors };
      })
    );
  }

  getProfilePlaylistsFromApi$(): Observable<{
    playlists: Playlist[];
    loading: boolean;
    errors: readonly GraphQLFormattedError<Record<string, any>>[] | undefined;
  }> {
    return this.getProfilePlaylistsGql$.valueChanges.pipe(
      map(({ data, loading, errors }) => {
        const playlists = data.profile?.playlists as Playlist[];
        return { playlists, loading, errors };
      })
    );
  }

  refreshPlaylistsFromApi() {
    this.getProfilePlaylistsGql$.refetch();
  }

  private deletePlaylistGQL = inject(DeletePlaylistsGQL);
  async deletePlaylists(ids: string[]) {
    const { data } = await lastValueFrom(
      this.deletePlaylistGQL.mutate(
        { ids },
        {
          awaitRefetchQueries: true,
          refetchQueries: [this.getProfilePlaylistsGql.document],
        }
      )
    );
    if (data && data.deletePlaylists.isSuccessful) {
      this.refreshPlaylistsFromApi();
      return true;
    }
    return false;
  }

  private createPlaylistGQL = inject(CreatePlaylistGQL);
  private updatePlaylistGQL = inject(UpdatePlaylistGQL);

  async createPlaylist(input: CreatePlaylistInput) {
    const { data } = await lastValueFrom(
      this.createPlaylistGQL.mutate({ input })
    );
    if (data && data.createPlaylist) {
      this.refreshPlaylistsFromApi();
      return data.createPlaylist;
    }
  }
  async updatePlaylist(input: UpdatePlaylistInput) {
    const { data } = await lastValueFrom(
      this.updatePlaylistGQL.mutate({ input })
    );
    if (data && data.updatePlaylist) {
      // technically there's no need to refresh data since data contains already the updated values
      this.refreshPlaylistsFromApi();
      return data.updatePlaylist;
    }
  }

  private getPendingPlaylistKey(id: string) {
    if (id && id !== '') {
      return `${localStorageKeys.PENDING_PLAYLISTS}/${id}`;
    }
    return undefined;
  }
  getPendingPlaylist(id: string) {
    const key = this.getPendingPlaylistKey(id);
    if (key) {
      const p: Maybe<Playlist> = this.localStorageService.retrieve(key);
      return p;
    }
  }
  deletePendingPlaylist(id: string) {
    const key = this.getPendingPlaylistKey(id);
    if (key) {
      this.localStorageService.clear(key);
    }
  }
  savePendingPlaylist(p: Playlist) {
    const key = this.getPendingPlaylistKey(p.id);
    if (key) {
      this.localStorageService.store(key, p);
    }
  }
}

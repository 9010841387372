import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  inject,
  viewChild,
  signal,
  ChangeDetectionStrategy,
} from '@angular/core';
import { SubSink } from 'subsink';
import { ApolloError } from '@apollo/client/errors';
import { Router } from '@angular/router';
import {
  CreateProfileGQL,
  GetOrganizationsForProfileCreatePageGQL,
  MeProfileFragment,
  Organization,
} from '@designage/gql';
import {
  ToasterService,
  CurrentUserService,
  RoutingStateService,
  ProfileService,
} from '@desquare/services';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileFormComponent } from '../profile-form/profile-form.component';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ProfileFormComponent,
    LoaderComponent,
  ],
  selector: 'app-profile-create',
  template: `
    @if (loading) {
      <app-loader [message]="loaderMessage" />
    } @else if (showGotoProfile) {
      <div class="modal-container">
        <div class="modal-header">
          <h3>
            {{ 'PROFILE' | translate }}
            <span>{{ createdProfile()?.name }}</span>
            {{ 'CREATED' | translate }}
          </h3>
        </div>

        <div class="modal-body content-container-card">
          <p>{{ 'PROFILE_CREATED_MESSAGE' | translate }}</p>
        </div>

        <div class="modal-footer">
          <div class="w-100 d-flex justify-content-between">
            <button
              (click)="activeModal.dismiss()"
              type="button"
              class="btn btn-outline-danger "
            >
              {{ 'CLOSE' | translate }}
            </button>
            <button class="btn btn-outline-success" (click)="gotoProfile()">
              {{ 'GOTO_PROFILE' | translate }}
            </button>
          </div>
        </div>
      </div>
    } @else {
      <div class="modal-container">
        <div class="modal-header">
          <h3>{{ 'ADD_NEW_PROFILE' | translate }}</h3>
        </div>

        <div class="modal-body content-container-card">
          <app-profile-form
            [formId]="'createProfileForm'"
            [parentFormGroup]="createProfileForm"
            [activeOrganizations]="activeOrganizations"
            (valid)="isFormValid = $event"
            (pristine)="isFormPristine = $event"
            [isCreate]="true"
          />
        </div>

        <div class="modal-footer">
          <div class="w-100 d-flex justify-content-between">
            <button
              (click)="activeModal.dismiss()"
              type="button"
              class="btn btn-outline-danger float-end"
            >
              {{ 'CANCEL' | translate }}
            </button>
            <button
              form="createProfileForm"
              [disabled]="!isFormValid"
              type="submit"
              [class.btn-success]="!isFormPristine"
              [class.btn-outline-success]="isFormPristine"
              class="btn"
              (click)="createProfile()"
            >
              {{ 'CREATE' | translate }}
            </button>
          </div>
        </div>
      </div>
    }
  `,
  styles: [
    `
      .create-profile-container {
        button.float-end {
          margin-left: 10px;
        }

        .selected-users-container {
          max-height: 400px;
          overflow: auto;
        }

        .default-selected-user {
          padding-left: 40px;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ProfileCreateComponent implements OnInit, OnDestroy {
  @Output() ownerIdChange = new EventEmitter<string>();
  profileFormChild = viewChild(ProfileFormComponent);
  activeModal = inject(NgbActiveModal);

  private subs = new SubSink();

  isFormValid = false;
  isFormPristine = false;
  ownerId!: string;
  loaderMessage!: string;
  loading = false;
  activeOrganizations!: Organization[];
  console = console;
  createProfileForm: FormGroup = new FormGroup({});
  showGotoProfile = false;
  createdProfile = signal<MeProfileFragment | undefined>(undefined);

  constructor(
    private profileService: ProfileService,
    private toasterService: ToasterService,
    private currentUserService: CurrentUserService,
    private getActiveOrganizationsGQL: GetOrganizationsForProfileCreatePageGQL,
  ) {}

  ngOnInit() {
    this.setProfileOwner();
    this.getActiveOrganizations();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  setProfileOwner() {
    if (this.currentUserService.currentUser) {
      const { id, displayName, auth0Profile } =
        this.currentUserService.currentUser;
      if (id && displayName && auth0Profile && auth0Profile.email) {
        this.ownerId = id;
      }
    }
  }

  // TODO: fix form type later
  async createProfile() {
    const { profile } = this.createProfileForm.value;
    this.loading = true;
    this.loaderMessage = 'CREATING_PROFILE';
    profile.userIds = [this.profileFormChild()?.currentUser?.id];
    profile.ownerId = this.profileFormChild()?.currentUser?.id;

    await this.profileService.createProfile(profile).then((createdProfile) => {
      this.toasterService.success('SWITCH_PROFILE_MESSAGE', undefined, {
        profile: profile.name,
      });

      this.createdProfile.set(createdProfile);
    });
    this.loading = false;
    this.showGotoProfile = true;
  }

  gotoProfile() {
    this.currentUserService.setActiveProfile(this.createdProfile()!);
    window.location.href = '/';
    // this.modalService.dismissAll();
  }

  getActiveOrganizations() {
    this.subs.sink = this.getActiveOrganizationsGQL
      .watch(
        {
          active: true,
        },
        {
          fetchPolicy: 'network-only',
        },
      )
      .valueChanges.subscribe({
        next: ({ data, loading }) => {
          const { organizations } = data;
          if (organizations) {
            this.activeOrganizations = organizations.results.map((org) => ({
              ...org,
              createdAt: new Date(),
              updatedAt: new Date(),
            }));
          } else {
            this.activeOrganizations = [];
          }
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('getActiveOrganizations', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });
        },
      });
  }
}

import { Directive, Input, Output, inject, signal } from '@angular/core';
import { NgForm } from '@angular/forms';
import { debounceTime, map, tap } from 'rxjs';
import { SuiteResult } from 'vest';

@Directive({
  standalone: true,
  selector: 'form',
})
export class FormDirective<T, P> {
  public readonly ngForm = inject(NgForm, { self: true });

  @Input() public formValue: T | null = null;
  @Input() public suite:
    | ((formValue: T, field: string) => SuiteResult<string, string>)
    | null = null;

  @Output() public readonly formValueChange =
    this.ngForm.form.valueChanges.pipe(
      debounceTime(0),
      // tap(() => {
      //   console.log(this.ngForm.form.controls['name'].errors);
      // }),
    );

  @Output() public readonly dirtyChange = this.ngForm.form.statusChanges.pipe(
    map(() => this.ngForm.form.dirty),
  );

  @Output() public readonly validChange = this.ngForm.form.statusChanges.pipe(
    map(() => this.ngForm.form.valid),
  );

  constructor() {
    this.ngForm.ngSubmit.subscribe(() => {
      this.ngForm.form.markAllAsTouched();
    });
  }
}

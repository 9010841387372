<div
  style="opacity: inherit"
  class="py-3 d-flex flex-row justify-content-around"
>
  <div class="btn-group">
    <button
      class="btn"
      [ngClass]="{
        'btn-primary': isPlaying,
        'btn-danger': !isPlaying,
      }"
      (click)="onPlay()"
      ngbTooltip="{{
        isPlaying ? ('PAUSE' | translate) : ('PLAY' | translate)
      }}"
    >
      <i
        class="ti"
        [ngClass]="{
          'ti-control-play': isPlaying,
          'ti-control-stop': !isPlaying,
        }"
      ></i>
    </button>

    <button
      class="btn"
      [ngClass]="{ 'btn-primary': !isMuted, 'btn-danger': isMuted }"
      (click)="onMute()"
      ngbTooltip="{{
        isMuted ? ('MUTED' | translate) : ('PLAYING_AUDIO' | translate)
      }}"
    >
      <i class="ti ti-volume"></i>
    </button>
  </div>
  <div class="btn-group">
    <button
      class="btn"
      [ngClass]="{ 'btn-primary': !isFullscreen, 'btn-warning': isFullscreen }"
      (click)="onFullScreen()"
      ngbTooltip="{{ 'TOGGLE_FULL_SCREEN' | translate }}"
    >
      <i class="ti ti-fullscreen"></i>
    </button>
  </div>
</div>

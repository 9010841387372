<!-- users table -->
<h5 class="px-2 pt-2 card-title">
  {{ 'USERS' | translate }}
</h5>
@if (resourceGroupUsers$ | async; as resourceGroupUsers) {
  @if (resourceGroupUsers.length > 0) {
    <designage-data-table
      [data]="resourceGroupUsers"
      style="max-height: 30vh"
      [columns]="userTableColumns"
      [alwaysSort]="true"
      [showFilter]="false"
      [columnSelector]="false"
      [showMultiSelect]="false"
      [showFooter]="false"
      [showDeleteRow]="true"
      [readOnly]="!isEditing"
      [tableElementId]="(userTableId$ | async) ?? ''"
      (dataDropped)="onUserDataDropped($event)"
      (rowDelete)="onUserTableRowDelete($event)"
      (rowCheckboxChange)="onUserTableRowCheckboxChange($event)"
    />
  } @else {
    <div
      class="card p-5"
      cdkDropList
      [id]="(userTableId$ | async) ?? ''"
      (cdkDropListDropped)="onUserDataDropped($event)"
    >
      {{ 'DRAG_AND_DROP_USER_HERE' | translate }}
    </div>
  }
}

<hr />

<!-- playlist table -->
<h5 class="px-2 pt-2 card-title">
  {{ 'PLAYLISTS' | translate }}
</h5>

@if (resourceGroupPlaylists$ | async; as resourceGroupPlaylists) {
  @if (resourceGroupPlaylists.length > 0) {
    <designage-data-table
      [data]="resourceGroupPlaylists"
      style="max-height: 30vh"
      [columns]="playlistTableColumns"
      [alwaysSort]="true"
      [showFilter]="false"
      [columnSelector]="false"
      [showMultiSelect]="false"
      [showFooter]="false"
      [showDeleteRow]="true"
      [readOnly]="!isEditing"
      [tableElementId]="(playlistTableId$ | async) ?? ''"
      (dataDropped)="onPlaylistDataDropped($event)"
      (rowDelete)="onPlaylistTableRowDelete($event)"
    />
  } @else {
    <div
      class="card p-5"
      cdkDropList
      [id]="(playlistTableId$ | async) ?? ''"
      (cdkDropListDropped)="onPlaylistDataDropped($event)"
    >
      {{ 'DRAG_AND_DROP_PLAYLIST_HERE' | translate }}
    </div>
  }
}

<!-- button group -->
<div class="d-flex w-100 py-4 flex-row-reverse justify-content-between">
  <!-- delete resource group button -->
  <div class="">
    <button
      class="btn btn-outline-danger mx-2"
      (click)="onDelete(); $event.stopPropagation()"
    >
      {{ 'RESOURCE_GROUP_DELETE' | translate }}
    </button>
  </div>

  <!-- edit resource group button -->
  <div class="">
    <!-- <button
      class="btn mx-2"
      [ngClass]="isEditing ? 'btn-primary' : 'btn-outline-primary'"
      (click)="onEdit(); $event.stopPropagation()"
    >
      {{
        (isEditing ? 'RESOURCE_GROUP_EDITING' : 'RESOURCE_GROUP_EDIT')
          | translate
      }}
    </button> -->
    @if (isEditing) {
      <button
        class="btn btn-outline-primary mx-2"
        (click)="onEditCancel(); $event.stopPropagation()"
      >
        {{ 'RESOURCE_GROUP_EDIT_CANCEL' | translate }}
      </button>
      <button
        class="btn btn-outline-success mx-2"
        (click)="onEditSave(); $event.stopPropagation()"
      >
        {{ 'RESOURCE_GROUP_EDIT_SAVE' | translate }}
      </button>
    } @else {
      <button
        class="btn btn-outline-primary mx-2"
        (click)="onEdit(); $event.stopPropagation()"
        [disabled]="!enableEditing"
      >
        {{ 'RESOURCE_GROUP_EDIT' | translate }}
      </button>
    }
  </div>
</div>

import { NgClass, SlicePipe, TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
import { ApolloError } from '@apollo/client';
import { ThumbnailPreviewDialogComponent } from '@designage/app/playlist-sequence/thumbnail-preview-dialog/thumbnail-preview-dialog.component';
import { AssetItem, GetPlaylistsGQL, Media, Playlist } from '@designage/gql';
import { CloudinaryMediaComponent } from '@desquare/components/common/src/cloudinary/cloudinaryMedia.component';
import { MediaReadableType } from '@desquare/enums';
import { IMediaForMediaList } from '@desquare/interfaces';
import {
  CurrentUserService,
  EncryptionService,
  MediaService,
  PlaylistEditorService,
  ResponsiveUiService,
  ToasterService,
} from '@desquare/services';
import { getImageSquaredThumbUrl, getRandomString } from '@desquare/utils';
import {
  NgbDropdownModule,
  NgbModal,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    NgClass,
    TranslateModule,
    NgbTooltipModule,
    SlicePipe,
    NgbDropdownModule,
    TitleCasePipe,
    CloudinaryMediaComponent,
  ],
  selector: 'media-grid-card',
  template: `
    @defer (on viewport) {
      <div class="content-wrapper shadowed-box" [id]="mediaItem().id">
        <div
          class="d-flex align-items-center justify-content-center h-100 pb-4"
          (click)="
            mediaGallery() ? openPreviewDialog(mediaItem()) : onMediaClick();
            $event.stopPropagation()
          "
        >
          @switch (mediaItem().type) {
            @case ('IMAGE') {
              <cl-media
                ngbTooltip="{{ mediaItem().name }}"
                class="cl-media-image"
                [public-id]="mediaItem().publicId"
                mediaType="image"
                loading="lazy"
                [width]="responsiveUiService.xl() ? '160' : '140'"
                [height]="responsiveUiService.xl() ? '140' : '120'"
                resizeMethod="limit"
                quality="auto"
                fetch-format="webp"
              ></cl-media>
              @if (mediaItem().library === 'IMAGE') {
                <div
                  class="p-2 position-absolute top-0 start-0"
                  ngbTooltip="{{ MediaReadableType.IMAGE | titlecase }}"
                >
                  <i class="ri-image-fill"></i>
                </div>
              }
              @if (mediaItem().library === 'IMAGE_TEMPLATE') {
                <div
                  class="p-2 position-absolute top-0 start-0 d-flex"
                  ngbTooltip="{{
                    MediaReadableType.IMAGE_TEMPLATE | titlecase
                  }}"
                >
                  <img
                    src="assets/images/template-icon.svg"
                    alt="{{ MediaReadableType.IMAGE_TEMPLATE | titlecase }}"
                  />
                  <i class="ri-image-fill"></i>
                </div>
              }
              @if (mediaItem().library === 'PLAYLIST_IMAGE') {
                <div
                  class="p-2 position-absolute top-0 start-0 d-flex"
                  ngbTooltip="{{
                    MediaReadableType.PLAYLIST_IMAGE | titlecase
                  }}"
                >
                  <i class="ri-play-list-line"></i>
                  <i class="ri-image-fill"></i>
                </div>
              }
            }
            @case ('VIDEO') {
              <cl-media
                ngbTooltip="{{ mediaItem().name }}"
                class="cl-media-video"
                [public-id]="mediaItem().publicId"
                mediaType="videoPoster"
                loading="lazy"
                [width]="responsiveUiService.xl() ? '160' : '140'"
                [height]="responsiveUiService.xl() ? '140' : '120'"
                resizeMethod="limit"
                quality="auto"
                fetch-format="webp"
              ></cl-media>
              @if (mediaItem().library === 'VIDEO') {
                <div
                  class="p-2 position-absolute top-0 start-0"
                  ngbTooltip="{{ MediaReadableType.VIDEO | titlecase }}"
                >
                  <i class="ri-video-fill"></i>
                </div>
              }
              @if (mediaItem().library === 'VIDEO_TEMPLATE') {
                <div
                  class="p-2 position-absolute top-0 start-0 d-flex"
                  ngbTooltip="{{
                    MediaReadableType.VIDEO_TEMPLATE | titlecase
                  }}"
                >
                  <img
                    src="assets/images/template-icon.svg"
                    alt="{{ MediaReadableType.VIDEO_TEMPLATE | titlecase }}"
                  />
                  <i class="ri-video-fill"></i>
                </div>
              }
              @if (mediaItem().library === 'PLAYLIST_VIDEO') {
                <div
                  class="p-2 position-absolute top-0 start-0 d-flex"
                  ngbTooltip="{{
                    MediaReadableType.PLAYLIST_VIDEO | titlecase
                  }}"
                >
                  <i class="ri-play-list-line"></i>
                  <i class="ri-video-fill"></i>
                </div>
              }
            }
            @default {
              <h5>N/A</h5>
            }
          }
        </div>
        <div class="media-title" ngbTooltip="{{ mediaItem().name }}">
          {{ mediaItem().name }}
        </div>
      </div>
    } @placeholder {
      <div class="d-flex justify-content-center align-items-center h-100">
        <div
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        >
          <span class="text-secondary visually-hidden">
            {{ 'LOADING' | translate }}...
          </span>
        </div>
      </div>
    }
  `,
  styleUrls: ['./media-grid-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaGridCardComponent {
  mediaItem = input.required<IMediaForMediaList>();
  selectedFolderId = input.required<string | null>();
  mediaGallery = input<boolean>(false);
  onMediaCardClick = output<Media>();
  responsiveUiService = inject(ResponsiveUiService);

  currentUserService = inject(CurrentUserService);
  getPlaylistsGQL = inject(GetPlaylistsGQL);
  toasterService = inject(ToasterService);
  mediaService = inject(MediaService);
  playlistEditorService = inject(PlaylistEditorService);
  modalService = inject(NgbModal);
  encryptionService = inject(EncryptionService);
  router = inject(Router);
  profileId = this.currentUserService.getCurrentProfileId();

  loadingDelete = signal<boolean>(false);
  loaderMessage = signal<string>('');
  playlists = signal<Playlist[]>([]);
  MediaReadableType = MediaReadableType;

  onMediaClick() {
    this.onMediaCardClick.emit(this.mediaItem());
  }

  async getPlaylistsByMediaItem(mediaItem: Media) {
    if (mediaItem?.id && this.profileId) {
      await lastValueFrom(
        this.getPlaylistsGQL.fetch({
          contentId: mediaItem.id,
        }),
      )
        .then(({ data, errors }) => {
          if (data) {
            this.playlists.set(data?.playlists as Playlist[]);
          } else {
            this.playlists.set([]);
          }

          if (errors) {
            this.toasterService.error('NO_PLAYLISTS_FOUND');
          }
        })
        .catch((error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('getPlaylistsByMediaItem', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });
        });
    }
  }

  openPreviewDialog(mediaItem: Media) {
    const modalRef = this.modalService.open(ThumbnailPreviewDialogComponent, {
      windowClass: 'custom-centered-modal',
      size: 'lg',
    });

    const componentInput =
      modalRef.componentInstance as ThumbnailPreviewDialogComponent;
    const assetItem = this.setTemporaryAssetItem(mediaItem);

    componentInput.assetItem.set(assetItem);

    if (assetItem.__typename === 'VideoAsset') {
      this.playlistEditorService.previewPlayToggleTriggered.emit(false);
    }
  }

  setTemporaryAssetItem(mediaItem: Media): AssetItem {
    return {
      id: getRandomString(),
      contentId: mediaItem.id,
      name: mediaItem.name,
      type: this.playlistEditorService.setAssetType(mediaItem.type),
      publicId: mediaItem.publicId,
      campaignEnd: null,
      campaignStart: null,
      days: null,
      duration: null,
      uri: mediaItem.secureUrl,
      sequence: null,
      __typename: this.playlistEditorService.setContentTypeName(mediaItem.type),
    };
  }
}

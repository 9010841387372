import {
  Component,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
  OnInit,
  Output,
  EventEmitter,
  signal,
  computed,
  input,
  ChangeDetectionStrategy,
  viewChild,
  HostBinding,
  effect,
} from '@angular/core';
import {
  Maybe,
  Asset,
  LayoutDetailedFragment,
  ChannelPlaylist,
  Channel,
  ChannelsForChannelListFragment,
  Playlist,
} from '@designage/gql';
import {
  PlaylistEditorService,
  AspectResizeCropService,
  LayoutDataService,
} from '@desquare/services';
import { screenUtil } from '@desquare/utils';
import { IZoneResolution } from '@desquare/interfaces';
import moment from 'moment';
import { SubSink } from 'subsink';
import { LocalStorageService } from 'ngx-webstorage';
import { localStorageKeys } from '@desquare/constants';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { PreviewControlsComponent } from '../preview-controls/preview-controls.component';
import { LayoutExplorerComponent } from '@desquare/components/common/src/layout-explorer/layout-explorer.component';
import { NgStyle } from '@angular/common';
import { DatepickerComponent } from '@desquare/components/common/src/datepicker/datepicker.component';
import { TimepickerComponent } from '@desquare/components/common/src/timepicker/timepicker.component';
import { RegionPlayerComponent } from '../region-player/region-player.component';

type ResolutionSource = 'SRC_ASSIGNED' | 'SRC_ALL' | 'SRC_GENERIC';

@Component({
  standalone: true,
  imports: [
    NgStyle,
    TranslateModule,
    NgbTooltip,
    PreviewControlsComponent,
    LayoutExplorerComponent,
    DatepickerComponent,
    TimepickerComponent,
    RegionPlayerComponent,
  ],
  selector: 'app-content-preview',
  templateUrl: './content-preview.component.html',
  styleUrls: ['./content-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentPreviewComponent implements OnInit, OnDestroy {
  regionPlayer = viewChild(RegionPlayerComponent);
  progress = computed(() => this.regionPlayer()?.progressPercentage() || 0);
  @HostBinding('style.--progress') progressValue: number =
    this.regionPlayer()?.progressPercentage() || 0;

  playlist = input.required<Maybe<Playlist>>();

  previewPlaylists = computed(() => {
    const playlist = this.playlist();
    if (!!playlist) {
      return [playlist];
    }
    return [];
  });

  private subs = new SubSink();

  @ViewChild('previewContainer') previewContainer!: ElementRef;

  noScheduledContent!: boolean;

  private _isVisible!: boolean;

  get isVisible() {
    return this._isVisible;
  }
  @Input() set isVisible(value: boolean) {
    this._isVisible = value;
    this.regionPlayer()?.togglePlayback(value);
  }

  _channels: Maybe<ChannelsForChannelListFragment>[] = [];
  @Input() set channels(value: Maybe<ChannelsForChannelListFragment>[]) {
    this._channels = value;
    this.chooseResolutionSource();
  }
  get channels() {
    return this._channels;
  }

  @Input() showButtons = true;
  @Input() showControls = true;
  @Input() previewButtonText = 'PREVIEW';
  @Input() publishedButtonText = 'PUBLISHED';
  @Input() showPublishedButton = true;
  @Input() noContentText = 'NO_CONTENT_FOUND';

  @Input() showSimulateSchedule = false;
  @Input() showAspectRatio = false;

  chooseResolutionSource() {
    if (
      (this.selectedChannelRegions || []).length > 0 &&
      this.channels.length > 0
    ) {
      this.previewResolutionSource = 'SRC_ASSIGNED';
    }
    if (
      this.previewResolutionSource === 'SRC_ASSIGNED' &&
      (this.selectedChannelRegions || []).length === 0
    ) {
      this.previewResolutionSource = 'SRC_ALL';
    }
    if (
      this.previewResolutionSource === 'SRC_ALL' &&
      this.channels.length === 0
    ) {
      this.previewResolutionSource = 'SRC_GENERIC';
    }
    /*
    if (this.channels.length > 0 && this.selectedChannelRegions.length > 0) {
      if (this.previewState.selectedChannel != null) {
        this.selectedChannels.find(
          (x) => x?.id === this.previewState.selectedChannel
        ) || this.selectedChannels[0];
      } else {
        this.setSelectedChannel(
          this._selectedChannel?.id || this.selectedChannelRegions[0].channelId
        );
      }
    }*/
  }
  _selectedChannelRegions: Maybe<ChannelPlaylist[]> = [];
  @Input()
  set selectedChannelRegions(value: Maybe<ChannelPlaylist[]>) {
    this._selectedChannelRegions = value;
    this.chooseResolutionSource();
  }
  get selectedChannelRegions() {
    return this._selectedChannelRegions;
  }

  /** is responsive */
  @Input() isBrowserRes = true;

  get selectedChannels() {
    const selectedChannelIds = (this.selectedChannelRegions || []).map(
      (x) => x.channelId,
    );
    return this.channels.filter(
      (x) => !!x && selectedChannelIds.includes(x.id),
    );
  }

  currentImageUri!: string;
  currentImageDuration!: number;

  currentImageName = '';
  durationTimeoutId!: Maybe<number>;

  /** checkbox simulate date or not */
  enableSimulateDateTime = signal(false);

  /** input element value 'YYYY-MM-DD' or '' */
  simulateDate = signal<string>('');
  /** input element value 'HH:mm' or '' */
  simulateTime = signal<string>('');

  /** if not simulating returns NULL */
  simulateDateTime = computed(() => {
    if (this.enableSimulateDateTime()) {
      if (this.simulateDate() !== '' && this.simulateTime() !== '') {
        return moment(`${this.simulateDate()} ${this.simulateTime()}`);
      } else {
        return moment();
      }
    }
    return null;
  });

  getPlayDateTime() {
    const m = this.simulateDateTime();
    return !!m ? m : moment();
  }
  /** simulated or current date as 'YYYY-MM-DD' */
  getPlayDate() {
    return this.getPlayDateTime().format('YYYY-MM-DD');
  }
  /** simulated or current time as 'HH:mm' */
  getPlayTime() {
    return this.getPlayDateTime().format('HH:mm');
  }

  isMuted = signal(true);
  isPlaying = signal(true);
  previewAssetsToEmulate: Asset[] = [];
  currentWindow!: Maybe<Window>;
  currentPlayingImageElement!: string;
  previousTransitionEffect!: string;
  previousTransitionDuration!: number;
  showImage = true;
  previewResolutionSources: string[] = [];

  _previewResolutionSource: ResolutionSource = 'SRC_GENERIC';
  get previewResolutionSource() {
    return this._previewResolutionSource;
  }
  set previewResolutionSource(value: ResolutionSource) {
    const isChannelRegionInAssignedList = (
      channelId: string,
      region: string,
    ) => {
      return (
        !(this.selectedChannelRegions || [])
          .map((x) => x.channelId)
          .includes(channelId) ||
        !(this.selectedChannelRegions || [])
          .map((x) => x.region)
          .includes(region)
      );
    };

    this._previewResolutionSource = value;
    switch (value) {
      case 'SRC_ASSIGNED':
        if (
          this.selectedChannelRegions &&
          this.selectedChannelRegions.length > 0 &&
          (!this.selectedChannelRegion ||
            !isChannelRegionInAssignedList(
              this.selectedChannelRegion.channelId,
              this.selectedChannelRegion.region || '',
            ))
        ) {
          this.setSelectedChannel(
            this.previewState.selectedChannel ||
              this.selectedChannelRegions[0].channelId,
          );
        }

        // this.setPreviewResolution(e);
        break;

      case 'SRC_ALL':
        this.setSelectedLayout(this.layouts[0].id);
        // this.setPreviewResolution(e);
        break;

      case 'SRC_GENERIC':
        this.setPreviewResolution(
          this._previewResolutionSource,
          this.resolutions[0].resolution,
          this.zoneResolution.orientation,
        );
        break;

      default:
        break;
    }
  }

  zoneResolution!: IZoneResolution;
  resolutions!: IZoneResolution[];
  selectedResolution!: string;
  selectedPreviewChannel!: Channel;
  layoutExplorerVisible = false;
  layoutExplorerSize = 250;
  isFullscreen = false;
  previewState!: {
    previewResolution: IZoneResolution;
    /** channel id */
    selectedChannel?: string;
  };

  layouts: LayoutDetailedFragment[] = [];
  selectedLayout: Maybe<LayoutDetailedFragment>;
  get selectedChannel() {
    return this.selectedChannels.find(
      (x) => !!x && x.id === this.selectedChannelRegion?.channelId,
    );
  }

  selectedChannelRegion: Maybe<ChannelPlaylist>;

  _selectedLayoutRegion = '';
  get selectedLayoutRegion() {
    return this._selectedLayoutRegion;
  }
  set selectedLayoutRegion(value: string) {
    this._selectedLayoutRegion = value;
    this.setPreviewResolution('SRC_ALL', this._selectedLayoutRegion);
  }

  constructor(
    public playlistEditorService: PlaylistEditorService,
    private localStorageService: LocalStorageService,
    public layoutService: LayoutDataService,
    public arcService: AspectResizeCropService,
  ) {
    effect(() => {
      this.progressValue = this.progress();
    });
  }

  ngOnDestroy() {
    this.clearDetachPreviewValue();
    this.exitDetach();
    this.subs.unsubscribe();
  }

  getPreviewState() {
    this.previewState = this.localStorageService.retrieve(
      localStorageKeys.ACTIVE_PREVIEW_RESOLUTION,
    );
    if (this.previewState != null) {
      return;
    }
    this.previewState = {
      previewResolution: { ...this.arcService.getPlaylistAspect() },
    };
  }
  ngOnInit() {
    console.log('ContentPreviewComponent ngOnInit');

    this.getPreviewState();
    this.setResolutions();

    this.zoneResolution = this.arcService.getPlaylistAspect();

    this.initSubscriptions();
  }

  async initSubscriptions() {
    this.layouts = await this.layoutService.getCurrentProfileLayouts();
    this.selectedLayout = this.layouts[0];

    this.subs.sink =
      this.playlistEditorService.playlistScheduleChanges.subscribe(() => {
        this.setSimulatedDate();
      });

    this.subs.sink =
      this.playlistEditorService.previewPlayToggleTriggered.subscribe(
        (value: boolean) => {
          this.regionPlayer()?.togglePlayback(value);
        },
      );
  }

  get previewSources() {
    if (this.selectedChannels.length === 0) {
      return ['SRC_GENERIC', 'SRC_ALL'];
    } else {
      return ['SRC_ASSIGNED', 'SRC_ALL', 'SRC_GENERIC'];
    }
  }

  getPreviewerDrawerSize() {
    if (this.layoutExplorerVisible) return 0;
    return this.previewResolutionSource === 'SRC_GENERIC'
      ? '-127px'
      : `-${this.layoutExplorerSize + 90}px`;
  }

  getSelectedChannel(id: string) {
    const channel = this.channels.find((x) => x?.id === id);
    if (channel) {
      return channel.name;
    }
    return 'not set';
  }

  restartPreview() {
    // TODO
  }

  setSelectedChannel(e: string | Event) {
    const id =
      typeof e === 'string' ? e : (e.target as HTMLSelectElement).value;
    const tmpSelectedChannel = this.channels.find((x) => x?.id === id);
    if (tmpSelectedChannel) {
      this.selectedPreviewChannel = tmpSelectedChannel as Channel;

      this.setSelectedLayout(tmpSelectedChannel.layout?.id || '');
      this.setSelectedLayoutRegion(
        this.selectedChannelRegions?.find((x) => x.channelId === id)?.region ||
          '',
      );

      this.setPreviewResolution(this.previewResolutionSource);
      this.storeActivePreviewResolution();
    }
  }

  setSelectedLayout(e: string | Event) {
    const id =
      typeof e === 'string' ? e : (e.target as HTMLSelectElement).value;

    this.selectedLayout = this.layouts.find((x) => x.id === id);
    this.selectedLayoutRegion = '';
  }

  setSelectedLayoutRegion(e: string | Event) {
    const regionName =
      typeof e === 'string' ? e : (e.target as HTMLSelectElement).value;
    this.selectedLayoutRegion = regionName;
  }

  setResolutions() {
    // Show only one 16:9 aspect ratio
    this.resolutions = [...this.arcService.zoneResolutions];
  }

  togglePlay() {
    if (!this.showControls) {
      return;
    }

    // TODO: decide if we want to handle this here on in nested region player
    this.isPlaying.set(!this.isPlaying());
  }

  toggleFullScreen(isFullScreenTrigger: boolean) {
    if (isFullScreenTrigger && !this.isFullscreen) {
      this.requestFullScreen();
    } else {
      this.exitFullScreen();
    }
  }

  requestFullScreen() {
    const previewComponent = this.previewContainer.nativeElement;
    screenUtil.requestFullScreen(previewComponent);
    this.isFullscreen = true;
  }

  exitFullScreen() {
    screenUtil.exitFullScreen();
    this.isFullscreen = false;
  }

  setDetachPreviewValue() {
    /*
    const assetsToEmulate = this.previewAssets();
    const playlistScheduleStatus =
      this.playlistEditorService.currentPlaylistStatus();

    this.localStorageService.store(
      localStorageKeys.PREVIEW_ASSETS_TO_EMULATE,
      assetsToEmulate,
    );
    this.localStorageService.store(
      localStorageKeys.PLAYLIST_SCHEDULE_STATUS,
      playlistScheduleStatus,
    );*/
  }

  clearDetachPreviewValue() {
    this.localStorageService.clear(localStorageKeys.ASSETS_TO_EMULATE);
    this.localStorageService.clear(localStorageKeys.SIMULATED_DATE);
    this.localStorageService.clear(localStorageKeys.PLAYLIST_SCHEDULE_STATUS);
  }

  setSimulatedDate() {
    if (this.simulateDateTime) {
      this.localStorageService.store(
        localStorageKeys.SIMULATED_DATE,
        this.simulateDateTime,
      );

      this.simulateDate.set(
        this.simulateDateTime()?.format('YYYY-MM-DD') || '',
      );
      this.simulateTime.set(this.simulateDateTime()?.format('HH:mm') || '');
    } else {
      this.localStorageService.clear(localStorageKeys.SIMULATED_DATE);
    }
  }

  exitDetach() {}

  // event can be any
  // es NO lint-disable-next-line @typescript-eslint/no-explicit-any
  toggleSimulatePreview() {
    setTimeout(() => {
      const enabled = !this.enableSimulateDateTime();

      if (enabled) {
        const m = moment();
        this.simulateDate.set(m.format('YYYY-MM-DD'));
        this.simulateTime.set(m.format('HH:mm'));
      } else {
        this.simulateDate.set('');
        this.simulateTime.set('');
      }
      this.enableSimulateDateTime.set(enabled);
    });
  }

  onSimulateDateChange(newDate: string) {
    console.log('onSimulateDateChange', newDate);
    this.simulateDate.set(newDate);
  }
  onSimulateTimeChange(newTime: string) {
    this.simulateTime.set(newTime);
  }

  changePreviewSource(e: Event) {
    const source = (e.target as HTMLSelectElement).value as ResolutionSource;

    this.previewResolutionSource = source;
  }

  setPreviewResolution(
    source: ResolutionSource,
    resolutionEvent?: Event | string,
    orientationEvent?: Event | string,
  ) {
    const orientation = orientationEvent
      ? typeof orientationEvent === 'string'
        ? orientationEvent
        : (orientationEvent.target as HTMLSelectElement).value
      : undefined;

    const resolution = resolutionEvent
      ? typeof resolutionEvent === 'string'
        ? resolutionEvent
        : (resolutionEvent.target as HTMLSelectElement).value
      : undefined;

    if (source === 'SRC_GENERIC') {
      const selectedResolution = this.arcService.zoneResolutions.find(
        (res) => res.resolution === resolution,
      );
      if (selectedResolution) {
        if (
          orientation === 'portrait' &&
          this.zoneResolution.orientation !== 'portrait' &&
          selectedResolution.height < selectedResolution.width
        ) {
          const width = selectedResolution.height;
          const height = selectedResolution.width;
          selectedResolution.width = width;
          selectedResolution.height = height;
          selectedResolution.aspect = (height / width) * 100;
          selectedResolution.orientation = orientation;
        }
        if (
          orientation === 'landscape' &&
          this.zoneResolution.orientation !== 'landscape' &&
          selectedResolution.height > selectedResolution.width
        ) {
          const width = selectedResolution.height;
          const height = selectedResolution.width;
          selectedResolution.width = width;
          selectedResolution.height = height;
          selectedResolution.aspect = (height / width) * 100;
          selectedResolution.orientation = orientation;
        }

        this.zoneResolution = selectedResolution;
      }
    } else if (source === 'SRC_ASSIGNED') {
      const selectedResolution = this.selectedLayout
        ? this.arcService.getRegionResolution(
            this.selectedLayout,
            this.selectedLayoutRegion,
          )
        : null;
      if (selectedResolution) {
        selectedResolution.aspect =
          (selectedResolution.height / selectedResolution.width) * 100;
        this.zoneResolution = selectedResolution;
      }
    } else if (source === 'SRC_ALL') {
      const selectedResolution = this.selectedLayout
        ? this.arcService.getRegionResolution(
            this.selectedLayout,
            this.selectedLayoutRegion,
          )
        : null;

      if (selectedResolution) {
        selectedResolution.aspect =
          (selectedResolution.height / selectedResolution.width) * 100;
        this.zoneResolution = selectedResolution;
      }
    }

    this.setPlaylistAspect();
  }

  storeActivePreviewResolution() {
    this.previewState = {
      previewResolution: { ...this.zoneResolution },
      selectedChannel: this.selectedChannel?.id,
    };
    this.localStorageService.store(
      localStorageKeys.ACTIVE_PREVIEW_RESOLUTION,
      this.previewState,
    );
  }

  setPlaylistAspect() {
    this.arcService.zoneResolutions.map((res) => {
      res.default = false;
    });
    this.zoneResolution.default = true;
    this.arcService.updatePlaylistAspect(this.zoneResolution);
    // this.playlistEditorService.sequenceTouch.emit(true);
    this.regionPlayer()?.clearPreview();
  }
}

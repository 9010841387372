import {
  Component,
  ChangeDetectionStrategy,
  input,
  signal,
  inject,
  model,
} from '@angular/core';
import { CurrentUserService, DeviceDataService } from '@desquare/services';
import { DateTime } from 'luxon';
import { combineLatest, map, tap, switchMap } from 'rxjs';
import { ChartType } from '@desquare/enums';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DeviceData } from '@desquare/types';
import { Maybe } from '@designage/gql';
import { TranslateModule } from '@ngx-translate/core';
import { DesignageEchartComponent } from '../designage-echart/designage-echart.component';
import { AsyncPipe, TitleCasePipe } from '@angular/common';
import { DateProxyPipe } from '../pipe/pipe/date-proxy.pipe';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  imports: [
    AsyncPipe,
    DateProxyPipe,
    TitleCasePipe,
    TranslateModule,
    DesignageEchartComponent,
  ],
  selector: 'device-historical-dashboard',
  templateUrl: './device-historical-dashboard.component.html',
  styleUrls: ['./device-historical-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceHistoricalDashboardComponent {
  private deviceDataService = inject(DeviceDataService);

  modal = model.required<NgbModalRef>();
  deviceId = model.required<string>();

  readonly ChartType = ChartType;
  statusDay = signal(DateTime.now().toFormat('yyyy-MM-dd'));
  dayData = signal<[string, number][]>([]);
  device!: Maybe<DeviceData>;

  deviceMetrics$ = toObservable(this.deviceId).pipe(
    switchMap((deviceId) => {
      const metricsData$ = this.deviceDataService.getDeviceMqttInfo(deviceId);
      const statusData$ = this.deviceDataService.getDeviceMqttStatus$(deviceId);

      return combineLatest({
        metrics: metricsData$,
        status: statusData$,
      }).pipe(
        map(({ metrics, status }) => {
          const filteredData = metrics.filter(
            (item) =>
              (item.temp != null && item.temp != 0) ||
              (item.cpu != null && item.cpu != 0),
          );

          const dataArray = filteredData.map((item) => ({
            timestamp: DateTime.fromISO(item.ts).toISO()!,
            temp: item.temp || 0,
            cpu: item.cpu || 0,
          }));

          // Filter out empty or invalid status entries
          const statusArray = Object.entries(status)
            .filter(([_, item]) => item && item.ts)
            .map(
              ([_, item]) =>
                [DateTime.fromISO(item.ts).toISO()!, item.online ? 1 : 0] as [
                  string,
                  number,
                ],
            );

          // Update dayData signal with filtered status data for selected day
          this.dayData.set(
            statusArray.filter(
              (d) =>
                DateTime.fromISO(d[0]).toFormat('yyyy-MM-dd') ===
                this.statusDay(),
            ),
          );

          return {
            temp: dataArray.map(
              (d) => [d.timestamp, d.temp] as [string, number],
            ),
            cpu: dataArray.map((d) => [d.timestamp, d.cpu] as [string, number]),
            status: statusArray,
          };
        }),
      );
    }),
  );

  onChartClick(event: any) {
    console.log(event);
    this.statusDay.set(DateTime.fromISO(event[0]).toFormat('yyyy-MM-dd'));
  }
}

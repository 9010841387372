<div class="d-flex flex-column h-100">
  <div class="mb-2 d-flex justify-content-between align-items-center">
    <h4 class="flex-grow-1">{{ 'RESOURCE_GROUPS' | translate }}</h4>
  </div>
  <as-split
    [direction]="responsiveUiService.md() ? 'horizontal' : 'vertical'"
    useTransition="true"
  >
    <!-- left area -->
    <as-split-area class="p-2 overflow-auto">
      <div class="d-flex py-2">
        <!-- add resource group button -->
        <button class="btn btn-outline-primary" (click)="onAddResourceGroup()">
          {{ 'ADD_RESOURCE_GROUP' | translate }}
        </button>

        <!-- edit resource group button -->
        <!-- <button
          class="btn mx-2"
          [ngClass]="
            (isEditing$ | async) ? 'btn-primary' : 'btn-outline-primary'
          "
          (click)="onEditResourceGroup(); $event.stopPropagation()"
        >
          {{
            ((isEditing$ | async)
              ? 'RESOURCE_GROUP_EDIT_MODE_TRUE'
              : 'RESOURCE_GROUP_EDIT_MODE_FALSE'
            ) | translate
          }}
        </button> -->
      </div>

      <!-- resource groups -->
      @if (resourceGroups$ | async; as resourceGroups) {
        @if (resourceGroups.length > 0) {
          <div ngbAccordion class="accordion-custom">
            @for (resourceGroup of resourceGroups; track resourceGroup.id) {
              <div
                [ngbAccordionItem]="resourceGroup.id"
                class="mb-3"
                [id]="resourceGroup.id"
              >
                <!-- panel title -->
                <div ngbAccordionHeader>
                  <button
                    ngbAccordionButton
                    (click)="onAccordionPanelChange(resourceGroup.id)"
                  >
                    <app-resource-group-title [resourceGroup]="resourceGroup" />
                  </button>
                </div>

                <!-- panel content -->
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody>
                    <ng-template>
                      <app-resource-group-content
                        [enableEditing]="
                          (resourceGroupBeingEdited$ | async) === null
                        "
                        [resourceGroup]="resourceGroup"
                        (editResourceGroup)="onEditResourceGroup($event)"
                        (deleteResourceGroup)="onDeleteResourceGroup($event)"
                      />
                    </ng-template>
                  </div>
                </div>
              </div>
            }
          </div>
        } @else {
          <ng-container *ngTemplateOutlet="noResourceGroups" />
        }
      } @else {
        <ng-container *ngTemplateOutlet="noResourceGroups" />
      }
      <ng-template #noResourceGroups>
        <h4 class="card p-5">
          {{ 'NO_RESOURCE_GROUP' | translate }}
        </h4>
      </ng-template>
    </as-split-area>

    <!-- right area -->
    @if (
      (resourceGroupBeingEdited$ | async) &&
      ((activeAccordionPanelIds$ | async)?.length ?? 0 > 0)
    ) {
      <as-split-area class="p-2">
        <as-split direction="vertical" unit="percent">
          <!-- user table area -->
          <as-split-area class="d-flex flex-column py-3" size="33" minSize="5">
            <div class="card-body d-flex flex-column overflow-hidden">
              <h5 class="card-title px-3">{{ 'USER_LIST' | translate }}</h5>
              <designage-data-table
                [data]="(users$ | async) ?? []"
                [columns]="userTableColumns"
                [alwaysSort]="true"
                [showFilter]="true"
                [columnSelector]="true"
                [showMultiSelect]="false"
                [showFooter]="false"
                [connectedDropList]="(connectedUserTableList$ | async) ?? []"
                [dragDisabled]="false"
              />
            </div>
          </as-split-area>

          <!-- playlist table area -->
          <as-split-area class="d-flex flex-column py-3" size="33" minSize="5">
            <div class="card-body d-flex flex-column overflow-hidden">
              <h5 class="card-title px-3">{{ 'PLAYLISTS' | translate }}</h5>
              <designage-data-table
                [columns]="playlistTableColumns"
                [data]="(playlists$ | async) ?? []"
                [alwaysSort]="false"
                [showFilter]="true"
                [showFooter]="false"
                [showMultiSelect]="false"
                [connectedDropList]="
                  (connectedPlaylistTableList$ | async) ?? []
                "
                [dragDisabled]="false"
              />
            </div>
          </as-split-area>
        </as-split>
      </as-split-area>
    }
  </as-split>
</div>

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserManageComponent } from '../user-manage/user-manage.component';
import { SlidePanelComponent } from '@designage/app/shared/slide-panel/slide-panel.component';

@Component({
  standalone: true,
  imports: [SlidePanelComponent, UserManageComponent],
  selector: 'app-user-manage-slider',
  template: `<app-slide-panel
    (closePanel)="closeLocationManagePanel()"
    [fullscreenTooltip]="'TOGGLE_USER_TABLE'"
    [closePanelTooltip]="'CLOSE_THIS_CHANNEL'"
    [fullscreen]="true"
    [openWidth]="20"
    [panelContent]="'locationManage'"
  >
    <app-user-manage />
  </app-slide-panel> `,
})
export class UserManageSliderComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  closeLocationManagePanel() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Signal,
  computed,
  effect,
  inject,
  input,
  signal,
} from '@angular/core';
import { DeviceHistoricalDashboardComponent } from '@desquare/components/common/src/device-historical-dashboard/device-historical-dashboard.component';
import { ChartType } from '@desquare/enums';
import { CurrentUserService, DeviceDataService } from '@desquare/services';
import { DeviceStatusData, DeviceMonitorData } from '@desquare/types';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'ts-luxon';
import { Observable, map, tap, combineLatest, switchMap } from 'rxjs';
import { AsyncPipe, CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DesignageEchartComponent } from '@desquare/components/common/src/designage-echart/designage-echart.component';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  imports: [
    AsyncPipe,
    FormsModule,
    TranslateModule,
    NgbTooltip,
    DesignageEchartComponent,
  ],
  selector: 'designage-device-monitor',
  templateUrl: './device-monitor.component.html',
  styleUrls: ['./device-monitor.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceMonitorComponent implements OnInit {
  private deviceDataService = inject(DeviceDataService);
  private modalService = inject(NgbModal);

  deviceId = input.required<string>();

  loading = signal(false);
  timespanMinutes = signal(720);
  readonly ChartType = ChartType;

  deviceMetrics$ = toObservable(this.deviceId).pipe(
    switchMap((deviceId) => {
      // One-time MQTT info fetch
      const metricsData$ = this.deviceDataService.getDeviceMqttInfo(deviceId);
      // Continuous status updates
      const statusData$ = this.deviceDataService.getDeviceMqttStatus$(deviceId);

      return combineLatest({
        metrics: metricsData$,
        status: statusData$,
      }).pipe(
        tap(() => {
          console.log('new device', deviceId), this.loading.set(true);
        }),
        map(({ metrics, status }) => {
          const filteredData = metrics.filter(
            (item) =>
              (item.temp != null && item.temp != 0) ||
              (item.cpu != null && item.cpu != 0),
          );

          const dataArray = filteredData.map((item) => ({
            timestamp: DateTime.fromISO(item.ts).toISO()!,
            temp: item.temp || 0,
            cpu: item.cpu || 0,
          }));

          const cutoffTime = DateTime.now().minus({ hours: 12 });
          const recentData = dataArray.filter(
            (d) => DateTime.fromISO(d.timestamp) > cutoffTime,
          );

          // Filter out empty or invalid status entries
          const statusArray = Object.entries(status)
            .filter(([_, item]) => item && item.ts)
            .map(
              ([_, item]) =>
                [DateTime.fromISO(item.ts).toISO()!, item.online ? 1 : 0] as [
                  string,
                  number,
                ],
            );

          return {
            temp: recentData.map(
              (d) => [d.timestamp, d.temp] as [string, number],
            ),
            cpu: recentData.map(
              (d) => [d.timestamp, d.cpu] as [string, number],
            ),
            status: statusArray,
          };
        }),
        tap(() => this.loading.set(false)),
      );
    }),
  );

  deviceStatusDataForEchart$!: Observable<[string, number][]>; //The observable that holds the monitor data
  modalCloseResult = '';

  ngOnInit(): void {
    // No need for initialization here anymore
  }

  openModal() {
    const modal = this.modalService.open(DeviceHistoricalDashboardComponent, {
      ariaLabelledBy: 'Device-Historical-Dashboard',
      backdrop: 'static',
      size: 'xl',
      windowClass: 'stat-modal',
    });

    const componentInstance =
      modal.componentInstance as DeviceHistoricalDashboardComponent;
    componentInstance.modal.set(modal);
    componentInstance.deviceId.set(this.deviceId());
  }
}

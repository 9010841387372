<div class="h-100 d-flex flex-column">
  <!-- MARK: Buttons -->
  <div class="d-flex justify-content-center py-2">
    <div
      class="mx-2 btn-group btn-group-sm"
      name="uptimeTimespan"
      aria-label="Set the time span for the graph"
      style="height: fit-content"
    >
      <input
        class="btn-check"
        type="radio"
        [checked]="timespanMinutes() === 30"
        [value]="30"
      />
      <label
        for="folderSearch"
        class="btn btn-outline-light"
        ngbTooltip="{{ 'SEARCH_FOLDER_TT' | translate }}"
        (click)="timespanMinutes.set(30)"
      >
        <div>last 30 {{ 'MINUTES' | translate }}</div>
      </label>

      <input
        class="btn-check"
        type="radio"
        [checked]="timespanMinutes() === 120"
        [value]="120"
      />
      <label
        class="btn btn-outline-light"
        ngbTooltip="{{ 'SEARCH_GLOBAL_TT' | translate }}"
        (click)="timespanMinutes.set(120)"
      >
        <div>last 2 {{ 'HOURS' | translate }}</div>
      </label>
      <input
        class="btn-check"
        type="radio"
        [checked]="timespanMinutes() === 720"
        [value]="720"
      />
      <label
        class="btn btn-outline-light"
        ngbTooltip="{{ 'SEARCH_GLOBAL_TT' | translate }}"
        (click)="timespanMinutes.set(720)"
      >
        <div>last 12 {{ 'HOURS' | translate }}</div>
      </label>
    </div>
    <div>
      <button class="btn btn-sm btn-outline-light" (click)="openModal()">
        {{ 'HISTORY_REPORT' | translate }}
      </button>
    </div>
  </div>

  <div class="overflow-y-auto overflow-x-hidden">
    @if (deviceMetrics$ | async; as deviceMetrics) {
      <!-- MARK: UPTIME Graph -->
      @if (deviceMetrics.status.length > 0) {
        <div class="p-2 d-flex justify-content-between">
          <h5>{{ 'UPTIME' | translate }}</h5>
        </div>
        <designage-echart
          [chartType]="ChartType.UPTIME"
          [inputData]="deviceMetrics.status"
          [timespanMinutes]="timespanMinutes()"
        />
      } @else {
        <div class="d-flex justify-content-center align-items-center">
          <h6>
            {{ 'THIS_DEVICE_NOT_REPORT' | translate }}
            {{ 'STATUS' | translate }}
          </h6>
        </div>
      }

      <!-- MARK: CPU Graph -->
      @if (deviceMetrics.cpu.length > 0) {
        <div class="p-2 d-flex justify-content-between">
          <h5>{{ 'CPU_USAGE' | translate }}</h5>
        </div>
        <designage-echart
          [chartType]="ChartType.INFO"
          [inputData]="deviceMetrics.cpu"
          [timespanMinutes]="timespanMinutes()"
          [relativeMaxValue]="true"
          [maxValue]="5"
          [unit]="'%'"
          [name]="'CPU Usage'"
        />
      } @else {
        <div class="d-flex justify-content-center align-items-center">
          <h6>
            {{ 'THIS_DEVICE_NOT_REPORT' | translate }}
            {{ 'CPU_USAGE' | translate }}
          </h6>
        </div>
      }

      <!-- MARK: MEMORY Graph -->
      <!-- @if (deviceMetrics.memory.length > 0) {
            <div class="p-2 d-flex justify-content-between">
              <h5>{{ 'CPU_USAGE' | translate }}</h5>
            </div>
            <designage-echart
              [chartType]="ChartType.MEMORY"
              [data]="deviceMetrics.memory"
              [timespanMinutes]="timespanMinutes"
            />
          } @else {
            <div class="d-flex justify-content-center align-items-center">
              <h6>
                {{ 'THIS_DEVICE_NOT_REPORT' | translate }}
                {{ 'CPU_USAGE' | translate }}
              </h6>
            </div>
          } -->

      <!-- MARK: TEMP Graph -->
      @if (deviceMetrics.temp.length > 0) {
        <div class="p-2 d-flex justify-content-between">
          <h5>{{ 'SYSTEM_TEMP' | translate }}</h5>
        </div>
        <designage-echart
          [chartType]="ChartType.INFO"
          [inputData]="deviceMetrics.temp"
          [timespanMinutes]="timespanMinutes()"
          [relativeMinValue]="true"
          [relativeMaxValue]="true"
          [minValue]="2"
          [maxValue]="2"
          [unit]="'°C'"
          [name]="'System Temp'"
        />
      } @else {
        <div class="d-flex justify-content-center align-items-center">
          <h6>
            {{ 'THIS_DEVICE_NOT_REPORT' | translate }}
            {{ 'SYSTEM_TEMP' | translate }}
          </h6>
        </div>
      }
    } @else {
      <div class="d-flex justify-content-center align-items-center">
        <h6>
          {{ 'LOADING' | translate }}
        </h6>
      </div>
    }
  </div>
</div>

<ng-template #onlineStatusModal />

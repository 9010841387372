import {
  Asset,
  AssetItem,
  Maybe,
  ScheduleDays,
  ScheduleDaysInput,
} from '@designage/gql';
import { getDateTime } from './time.utils';
import { IScheduledDayIndicator, IActivityStatus } from '@desquare/interfaces';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { ActivityStatusColor, ActivityStatus } from '@desquare/enums';
import { PlaylistAssetItem } from '@desquare/types';

/**
 *
 * @param assetItem The current assetItem instance
 * @param days The days isSet status to determine if active
 * @param assetActivityStatus The parent asset activityStatus
 * @param _currentDate The date to which the status will be checked
 */
export function getAssetItemStatus(
  assetItem: AssetItem,
  days: Partial<IScheduledDayIndicator>[],
  assetActivityStatus: Partial<IActivityStatus>,
  _currentDate = new Date(),
) {
  const { campaignEnd: _campaignEnd, campaignStart: _campaignStart } =
    assetItem;
  const currentDate = moment(
    new Date(_currentDate.toISOString().split('.')[0]),
  );
  const campaignStart = _campaignStart ? moment(_campaignStart) : null;
  const campaignEnd = _campaignEnd ? moment(_campaignEnd) : null;

  // days in getDay starts from sunday while our gql type structure starts from monday
  const indexOfDayToday =
    _currentDate.getDay() === 0 ? 6 : _currentDate.getDay() - 1;

  const isWithinDateRange =
    (!campaignStart && !campaignEnd) ||
    (!campaignStart &&
      campaignEnd &&
      currentDate.isSameOrBefore(campaignEnd)) ||
    (campaignStart &&
      !campaignEnd &&
      currentDate.isSameOrAfter(campaignStart)) ||
    (campaignStart &&
      campaignEnd &&
      currentDate.isSameOrAfter(campaignStart) &&
      currentDate.isSameOrBefore(campaignEnd)) ||
    (campaignStart &&
      campaignEnd &&
      campaignStart.isSame(campaignEnd) &&
      currentDate.isSame(campaignStart));

  const isWithinDayList = days ? days[indexOfDayToday].isSet : false;
  const isWithinWaitingDateRange =
    campaignStart && currentDate.isBefore(campaignStart);

  switch (assetActivityStatus.status) {
    case ActivityStatus.WAITING:
      if (!assetItem.disabled) {
        return isWithinDateRange && isWithinDayList
          ? ActivityStatus.WAITING
          : ActivityStatus.INACTIVE;
      } else {
        return ActivityStatus.INACTIVE;
      }
    case ActivityStatus.ACTIVE:
      if (!assetItem.disabled) {
        if (isWithinDateRange && isWithinDayList) {
          return ActivityStatus.ACTIVE;
        } else if (
          isWithinDateRange &&
          !isWithinDayList &&
          days.find((day) => day.isSet)
        ) {
          return ActivityStatus.WAITING;
        } else if (isWithinWaitingDateRange) {
          return ActivityStatus.WAITING;
        } else {
          return ActivityStatus.INACTIVE;
        }
      } else {
        return ActivityStatus.INACTIVE;
      }
    case ActivityStatus.ACTIVE:
    default:
      return ActivityStatus.INACTIVE;
  }
}

const defaultAssetTime = '00:00:00';

/**
 *
 * @param asset The current asset instance
 * @param date The day to which the status will be checked
 */
export function getAssetStatus(
  asset: Asset,
  assetsCount: number,
  date = new Date(),
) {
  const actualStartTime =
    asset.actualStartTime && assetsCount > 1
      ? asset.actualStartTime
      : defaultAssetTime;
  const actualEndTime =
    asset.actualEndTime && assetsCount > 1
      ? asset.actualEndTime
      : defaultAssetTime;
  return getAssetStatusByParams(
    actualStartTime,
    actualEndTime,
    assetsCount,
    date,
  );
}
function getAssetStatusByParams(
  actualStartTime: string,
  actualEndTime: string,
  assetsCount: number,
  date = new Date(),
) {
  if (assetsCount === 0) return ActivityStatus.ACTIVE;

  const time = date.toTimeString().substring(0, 8);
  let status = ActivityStatus.INACTIVE;
  if (actualStartTime < actualEndTime) {
    if (time > actualStartTime && time < actualEndTime) {
      status = ActivityStatus.ACTIVE;
    } else {
      status = ActivityStatus.WAITING;
    }
  } else {
    if (time > actualStartTime || time < actualEndTime) {
      status = ActivityStatus.ACTIVE;
    } else {
      status = ActivityStatus.WAITING;
    }
  }
  return status;
}

/**
 *
 * @param startDate The start date of the playlist
 * @param endDate The endDate of the playlist
 * @param date The day to which the status will be checked
 */
export function getPlaylistStatus(
  startDate?: Maybe<moment.Moment>,
  endDate?: Maybe<moment.Moment>,
  _date?: Maybe<moment.Moment>,
) {
  const date = cloneDeep(_date ? _date : moment());
  // exclude time in evaluation
  // date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  if (startDate && date.isBefore(startDate)) {
    return ActivityStatus.WAITING;
  } else if (
    startDate &&
    endDate &&
    date.isSameOrAfter(startDate) &&
    date.isSameOrBefore(endDate)
  ) {
    return ActivityStatus.ACTIVE;
  } else if (startDate && !endDate && date.isSameOrAfter(startDate)) {
    return ActivityStatus.ACTIVE;
  } else if (!startDate && endDate && date.isSameOrBefore(endDate)) {
    return ActivityStatus.ACTIVE;
  } else if (!startDate && !endDate) {
    return ActivityStatus.ACTIVE;
  } else {
    return ActivityStatus.INACTIVE;
  }
}

export function isResourcePlayable(
  item: PlaylistAssetItem,
  simulatedDate = new Date(),
) {
  const simulatedMoment = moment(simulatedDate);
  const {
    playlistStart,
    playlistEnd,
    actualStartTime,
    actualEndTime,
    playlistAssetsCount,
  } = item;

  const momentOrNull = (s: Maybe<string>) => {
    return !!s ? moment(s) : undefined;
  };

  const playlistPlaying =
    getPlaylistStatus(
      momentOrNull(playlistStart),
      momentOrNull(playlistEnd),
      simulatedMoment,
    ) === ActivityStatus.ACTIVE;
  if (playlistPlaying) {
    const assetPlaying =
      getAssetStatusByParams(
        actualStartTime || defaultAssetTime,
        actualEndTime || defaultAssetTime,
        playlistAssetsCount,
        simulatedDate,
      ) === ActivityStatus.ACTIVE;
    if (assetPlaying) {
      const itemPlaying =
        getAssetItemStatus(
          item,
          scheduleDaysFromGql(item.days),
          { status: ActivityStatus.ACTIVE },
          simulatedDate,
        ) === ActivityStatus.ACTIVE;
      return itemPlaying;
    }
  }
  return false;
}

/**
 *
 * @param activityStatus The status of a given playlist/asset/content
 */
export function getActivityStatusColor(activityStatus: ActivityStatus) {
  switch (activityStatus) {
    case ActivityStatus.ACTIVE:
      return ActivityStatusColor.ACTIVE;
    case ActivityStatus.WAITING:
      return ActivityStatusColor.WAITING;
    case ActivityStatus.INACTIVE:
      return ActivityStatusColor.INACTIVE;
    case ActivityStatus.DISABLED:
      return ActivityStatusColor.DISABLED;
  }
}

const defaultDays: ScheduleDays = {
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
  sunday: true,
};
const getDayIndicatorStyleDefault = (
  isSet: boolean,
): Record<string, string> => {
  return {
    'background-color': isSet ? '#178863' : '',
    border: isSet ? '1px solid #178863' : '1px solid #f1556c',
  };
};

export function scheduleDaysFromGql(
  days: Maybe<ScheduleDays>,
  getDayIndicatorStyle: (
    set: boolean,
  ) => Record<string, string> = getDayIndicatorStyleDefault,
) {
  const dayObjects = Object.keys(days || defaultDays).filter(
    (key) => key[0] !== '_',
  );

  return dayObjects.reduce<IScheduledDayIndicator[]>(
    (accumulator, currentValue) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isSet = (days as any)[currentValue];

      accumulator.push({
        code: currentValue.substring(0, 1).toUpperCase(),
        key: currentValue as keyof Omit<ScheduleDays, '__typename'>,
        style: getDayIndicatorStyle(isSet),
        isSet,
      });

      return accumulator;
    },
    [],
  );
}
export function scheduleDaysToGql(
  week: IScheduledDayIndicator[],
): ScheduleDaysInput {
  const gqlWeek: ScheduleDaysInput = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };

  for (const day of week) {
    gqlWeek[day.key] = day.isSet;
  }
  return gqlWeek;
}

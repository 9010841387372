import { Component, OnDestroy, OnInit, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SlidePanelComponent } from '@designage/app/shared/slide-panel/slide-panel.component';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import {
  CurrentUserService,
  EncryptionService,
  PlaylistViewService,
  PlaylistEditorService,
  SlidePanelService,
} from '@desquare/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubSink } from 'subsink';
import { PlaylistManageComponent } from '../playlist-manage/playlist-manage.component';
import { TranslateService } from '@ngx-translate/core';
import { PlaylistManageNewComponent } from '../../playlist-editor/playlist-manage-new/playlist-manage-new.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  imports: [
    SlidePanelComponent,
    PlaylistManageComponent,
    PlaylistManageNewComponent,
  ],
  selector: 'app-playlist-manage-panel',
  template: `
    <app-slide-panel
      (closePanel)="closePlaylistManagePanel()"
      [fullscreenTooltip]="'TOGGLE_PLAYLIST_TABLE'"
      [closePanelTooltip]="'CLOSE_THIS_PLAYLIST'"
      [openWidth]="20"
      [fullscreen]="true"
      [panelContent]="'playlistManage'"
    >
      @if (useEditor === 'old') {
        <app-playlist-manage></app-playlist-manage>
      }
      @if (useEditor === 'new') {
        <designage-playlist-manage></designage-playlist-manage>
      }
    </app-slide-panel>
  `,
})
export class PlaylistManagePanelComponent implements OnInit, OnDestroy {
  route = inject(ActivatedRoute);
  router = inject(Router);
  encryptionService = inject(EncryptionService);
  private subs: SubSink = new SubSink();

  showTableBelowPlaylist: boolean = false;
  isPlaylistManagePanelCollapsed: boolean = true;
  isOkToRemovePendingPlaylist: boolean = false;
  playlistId = signal<string>('');
  params$ = this.route.params.pipe(takeUntilDestroyed());

  constructor(
    private playlistViewService: PlaylistViewService,
    private modalService: NgbModal,
    private playlistEditorService: PlaylistEditorService,
    private translateService: TranslateService,
    private currentUserService: CurrentUserService,
  ) {
    this.params$.subscribe((params) => {
      const { id } = params;
      if (id) this.playlistId.set(this.encryptionService.decrypt(id));
    });
    inject(SlidePanelService).setPanelComponentId(this.playlistId());
  }

  async canDeactivate(): Promise<boolean> {
    return this.playlistViewService.hasUnsavedPlaylistChanges
      ? this.openUnsavedConfirmationDialog()
      : true;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.initVariables();
  }
  useEditor: 'old' | 'new' | undefined;
  initVariables() {
    console.log('route data:', this.route.snapshot.data.breadcrumb.alias);
    this.useEditor =
      this.route.snapshot.data.breadcrumb.alias === 'playlistManageNew'
        ? 'new'
        : 'old';
    // this.subs.sink = this.route.params.subscribe((params) => {
    // const encryptedPlaylistId = params.playlistId;
    // this.playlistId = this.encryptionService.decrypt(encryptedPlaylistId);
    // });
  }

  async openUnsavedConfirmationDialog() {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {
      size: 'sm',
    });

    modalRef.componentInstance.headerText = 'Leave the page?';
    modalRef.componentInstance.bodyText = this.translateService.instant(
      'CLOSE_WITHOUT_SAVING_PROMPT',
    );
    modalRef.componentInstance.dismissButtonText = 'CANCEL';
    modalRef.componentInstance.closeButtonText = 'CLOSE_WITHOUT_SAVING';
    modalRef.componentInstance.useDangerButton = true;

    return await modalRef.result.then(
      (value) => {
        this.playlistViewService.hasUnsavedPlaylistChanges = false;

        // this prevents the unsaved changes info banner to show (backup system of playlist)
        // it is alright to remove the backup since the user at this point on purposely left
        // the playlist manage page
        this.removePendingPlaylist();

        return value;
      },
      () => {
        return false;
      },
    );
  }

  closePlaylistManagePanel() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  removePendingPlaylist() {
    /*this.playlistEditorService.deletePendingPlaylist({
      profileId: this.currentUserService.currentProfile?.id,
      playlistId: this.playlistId,
    });*/
  }
}

import { Component, Input } from '@angular/core';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import {
  CurrentUserService,
  PlaylistViewService,
  PlaylistEditorService,
} from '@desquare/services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { PlaylistManageComponent } from 'apps/designage/src/app/playlist/playlist-manage/playlist-manage.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [BaseDialogComponent, PlaylistManageComponent],
  selector: 'designage-playlist-manage-dialog',
  template: `<app-base-dialog
    headerText="Edit Playlist"
    [dismissOverride]="dismissFunction"
  >
    @if (playlistId) {
      <div class="modal-body">
        <app-playlist-manage
          [invokedFrom]="'channelPage'"
          [inlinePlaylistId]="playlistId"
          (playlistPublished)="onPlaylistPublished()"
        ></app-playlist-manage>
      </div>
    }
  </app-base-dialog> `,
})
export class PlaylistManageDialogComponent {
  @Input() playlistId!: string;

  dismissFunction = () =>
    this.playlistViewService.hasUnsavedPlaylistChanges
      ? this.openUnsavedConfirmationDialog()
      : this.modal.close();

  constructor(
    private modalService: NgbModal,
    private playlistViewService: PlaylistViewService,
    private playlistEditorService: PlaylistEditorService,
    private currentUserService: CurrentUserService,
    private translateService: TranslateService,
    private modal: NgbActiveModal,
  ) {}

  async openUnsavedConfirmationDialog() {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {
      size: 'sm',
    });

    modalRef.componentInstance.headerText = 'Leave the page? dialog';
    modalRef.componentInstance.bodyText = this.translateService.instant(
      'CLOSE_WITHOUT_SAVING_PROMPT',
    );
    modalRef.componentInstance.dismissButtonText = 'CANCEL';
    modalRef.componentInstance.closeButtonText = 'CLOSE_WITHOUT_SAVING';
    modalRef.componentInstance.useDangerButton = true;

    return await modalRef.result.then(
      (value) => {
        this.playlistViewService.hasUnsavedPlaylistChanges = false;

        // this prevents the unsaved changes info banner to show (backup system of playlist)
        // it is alright to remove the backup since the user at this point on purposely left
        // the playlist manage page
        this.removePendingPlaylist();

        this.modal.dismiss();
      },
      () => {
        // nothing happens
      },
    );
  }

  onPlaylistPublished() {
    setTimeout(() => {
      this.modal.close();
    }, 1000);
  }

  removePendingPlaylist() {
    this.playlistEditorService.deletePendingPlaylist({
      profileId: this.currentUserService.currentProfile?.id,
      playlistId: this.playlistId,
    });
  }
}

import { getTimeZones } from '@vvo/tzdb';
import { Maybe } from 'graphql/jsutils/Maybe';
const timeZones = getTimeZones();

export const getTimezoneOffsetByName = (tzName: Maybe<string>) => {
  if (!tzName) return '';
  try {
    return timeZones
      .find(
        (x) =>
          x.name === tzName ||
          x.alternativeName === tzName ||
          x.group.includes(tzName),
      )
      ?.currentTimeFormat.split(' ')[0];
  } catch (e) {
    console.error('ERROR: getTimezoneOffsetByName', e);
    return '';
  }
};

import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'des-button',
  standalone: true,
  imports: [TranslateModule],
  template: `
    <button
      class="btn"
      [type]="type()"
      [class.btn-sm]="smallButton()"
      [class]="computedClass()"
      [class.loading]="loading()"
      [disabled]="disabled() || loading()"
    >
      <div
        class="des-spinner mx-auto spinner-border spinner-border-sm text-light"
        role="status"
        [attr.aria-label]="loaderText() | translate"
      ></div>
      <div class="text">
        <ng-content></ng-content>
      </div>
    </button>
  `,
  styles: [
    `
      button {
        display: grid;
        grid-template-areas: 'des-button-stack';
        .des-spinner {
          visibility: hidden;
          grid-area: des-button-stack;
        }
        .text {
          grid-area: des-button-stack;
        }
        &.loading {
          .des-spinner {
            visibility: visible;
          }
          .text {
            visibility: hidden;
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesButtonComponent {
  type = input<'submit' | 'reset' | 'button'>('button');
  disabled = input(false);
  loading = input(false);
  loaderText = input('SAVING');
  text = input('SAVE');
  smallButton = input(false);
  outlineButton = input(true);
  color = input<
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | 'link'
  >('primary');

  computedClass = computed(() => {
    return this.outlineButton()
      ? `btn-outline-${this.color()}`
      : `btn-${this.color()}`;
  });
}

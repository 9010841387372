import {
  Component,
  OnInit,
  Input,
  inject,
  computed,
  input,
} from '@angular/core';
import { Maybe, PlaylistType } from '@designage/gql';
import { SessionService } from '@desquare/services';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AssetComponent } from '../asset/asset.component';
import { PlaylistStore } from '@desquare/stores';
import { getPlaylistStatus } from '@desquare/utils';
import moment from 'moment';

@Component({
  standalone: true,
  imports: [TranslateModule, NgbTooltip, AssetComponent],
  selector: 'playlist-assets-list',
  template: ` <div class="d-flex justify-content-between">
      @if (showAddSequence()) {
        <button
          type="button"
          (click)="addAsset()"
          class="btn btn-outline-primary btn-block ms-2 mb-2"
        >
          {{
            (playlistType() === 'INTERACTIVE'
              ? 'ADD_SEQUENCE_INTERACTIVE'
              : 'ADD_SEQUENCE'
            ) | translate
          }}
        </button>
      }
      <div class="d-flex justify-content-center align-items-center me-3">
        <div
          class="pins"
          [hidden]="!isAnythingPinned()"
          (click)="unpinAll()"
          ngbTooltip="{{ 'UNPIN_ALL' | translate }}"
        >
          <i class="ti ti-pin2 pin-no-1"></i>
          <i class="ti ti-pin2 pin-no-2"></i>
        </div>
      </div>
    </div>

    @for (asset of assets(); track asset.id) {
      <app-asset
        class="m-1 mb-2"
        [editingAssetIdx]="$index"
        [asset]="asset"
        [assetsCount]="assets().length"
        [playlistType]="playlistType()"
        [simulateDateTime]="simulateDateTime()"
        [currentPlaylistStatus]="playlistStatus()"
        [simpleUiActive]="simpleUiActive"
      />
    }`,
  styleUrls: ['./asset-list.component.scss'],
})
export class AssetListComponent implements OnInit {
  sessionService = inject(SessionService);
  playlistStore = inject(PlaylistStore);

  simulateDateTime = input.required<Maybe<moment.Moment>>();

  @Input() simpleUiActive!: boolean;

  assets = this.playlistStore.playlist.assets;

  profileId!: Maybe<string>;

  playlistType = computed(() => {
    return this.playlistStore.playlist.type();
  });
  playlistStatus = computed(() => {
    const startDate = this.playlistStore.playlist.startDate
      ? moment(this.playlistStore.playlist.startDate())
      : undefined;
    const endDate = this.playlistStore.playlist.endDate
      ? moment(this.playlistStore.playlist.endDate())
      : undefined;
    const now = this.simulateDateTime() || this.sessionService.currentMoment();

    return getPlaylistStatus(startDate, endDate, now);
  });

  /** show add sequence in standard playlist, interactive playlist for now only have 1 sequence */
  showAddSequence = computed(() => {
    if (this.playlistStore.playlist.type() === PlaylistType.Interactive) {
      return this.playlistStore.playlist.assets().length === 0;
    } else {
      return true;
    }
  });

  constructor() {}

  ngOnInit() {}

  isAnythingPinned() {
    return this.playlistStore.isAnythingPinned();
  }
  unpinAll() {
    this.playlistStore.unpinAll();
  }
  addAsset() {
    this.playlistStore.addSequence();
  }
}

export enum ChartType {
  UPTIME = 'uptime',
  UPTIMEFULL = 'uptimeFullReport',
  INFO = 'info',
  INFOFULL = 'infoFullReport',
  TEMP = 'temp',
  TEMPFULL = 'tempFullReport',
  CPU = 'cpu',
  CPUFULL = 'cpuFullReport',
  MEMORY = 'memory',
  MEMORYFULL = 'memoryFullReport',
  STORAGE = 'disk',
  STORAGEFULL = 'diskFullReport',
}

import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
  ElementRef,
  ViewChild,
  inject,
  computed,
  effect,
  ChangeDetectionStrategy,
  viewChild,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  Maybe,
  Playlist,
  Channel,
  Asset,
  PlaylistStatus,
  Location,
  ChannelGroup,
  GetPlaylistDetailedGQL,
  DuplicatePlaylistsGQL,
  ChannelPlaylist,
  ChannelsForChannelListFragment,
  PlaylistType,
} from '@designage/gql';
import {
  EncryptionService,
  PlaylistAsset,
  PlaylistAssetContent,
  PlaylistViewService,
  PlaylistViewSetting,
  PlaylistEditorService,
  ToasterService,
  ChannelService,
  UiMode,
  CurrentUserService,
  ResponsiveUiService,
  PlaylistTabs,
  SessionService,
  PlaylistService,
} from '@desquare/services';
import { AngularSplitModule } from 'angular-split';
import { cloneDeep } from 'lodash';
import { DateValidator } from '@desquare/validators';
import moment from 'moment';
import { CommonModule, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgbModal, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { PlaylistPublishingDialogComponent } from '@designage/app/playlist/playlist-publishing-dialog/playlist-publishing-dialog.component';
import { PlaylistRevertVersionDialogComponent } from '@designage/app/playlist/playlist-revert-version-dialog/playlist-revert-version-dialog.component';
import { PlaylistCreateCopyDialogComponent } from '@designage/app/playlist/playlist-create-copy-dialog/playlist-create-copy-dialog.component';
import { ContentPreviewComponent } from '@designage/app/shared/content-preview/content-preview.component';
import { Observable, lastValueFrom } from 'rxjs';
import _ from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { ChannelRegionSelectionComponent } from '@designage/app/shared/channel-region-selection/channel-region-selection.component';
import { PlaylistVersionComponent } from '@designage/app/playlist/playlist-version/playlist-version.component';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { DatepickerComponent } from '@desquare/components/common/src/datepicker/datepicker.component';
import { PlaylistStore } from '@desquare/stores';
import { AssetListComponent } from '../asset-list/asset-list.component';
import { MediaListComponent } from '../../media/media-list/media-list.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbNavModule,
    AngularSplitModule,
    AssetListComponent,
    ContentPreviewComponent,
    ChannelRegionSelectionComponent,
    PlaylistVersionComponent,
    DatepickerComponent,
    DateProxyPipe,
    MediaListComponent,
  ],
  selector: 'designage-playlist-form-new',
  templateUrl: './playlist-form-new.component.html',
  styleUrls: ['./playlist-form-new.component.scss'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaylistFormNewComponent implements OnInit {
  playlistStore = inject(PlaylistStore);
  playlistService = inject(PlaylistService);

  playlist = this.playlistStore.playlist;

  assetIds = computed(() => {
    const assets = this.playlist.assets() || [];
    return assets.map((x) => x.id);
  });

  loading = computed(() => this.playlistStore.loading());
  loaderMessage = computed(() => this.playlistStore.loaderMessage());

  playlistType = computed(() => this.playlistStore.playlist.type());
  isInteractive = computed(
    () => this.playlistType() === PlaylistType.Interactive,
  );

  changeDetected = this.playlistStore.isDirty;

  selectedChannels = computed(() =>
    this.playlist?.channelRegions ? this.playlist.channelRegions() : [],
  );
  publishedAssets = computed(
    () => this.playlist().publishedPlaylist?.assets || [],
  );

  nameRequired = computed(() => (this.playlist().name || '').length === 0);

  @ViewChild('playlistNameInput') playlistNameInput!: ElementRef;
  preview = viewChild<ContentPreviewComponent>('preview');

  @Input() invokedFrom?: string;

  isEditingName = false;
  updatedAt!: Maybe<string>;
  resourceCount = computed(() =>
    this.playlist
      .assets()
      .map((a) => (a.content ? a.content.length : 0))
      .reduce((sum, current) => sum + current, 0),
  );
  /** enum of statuses */
  playlistStatus = PlaylistStatus;

  isStartDateChecked = computed(() => {
    return this.playlist.startDate ? !!this.playlist.startDate() : false;
  });
  isEndDateChecked = computed(() => {
    return this.playlist.endDate ? !!this.playlist.endDate() : false;
  });

  simulateDateTime = computed(() => {
    return this.preview()?.simulateDateTime() || null;
  });

  initialized = false;
  profileId!: Maybe<string>;
  comment!: Maybe<string>;

  currentTab!: PlaylistTabs;

  channelFilter!: string;

  invalidDateRange!: boolean;
  viewSettings!: PlaylistViewSetting;
  activeChannels: string[] = [];
  simpleUiActive = false;
  uiMode$!: Observable<UiMode>;
  playlistStartDate!: string;
  playlistEndDate!: string;
  activeIdSettingsPanel!: number;

  channels: Maybe<ChannelsForChannelListFragment>[] = [];

  // TODO: enable this with a custom feature connected to profile
  advancedScheduleEnabled = false;

  constructor(
    private formBuilder: FormBuilder,
    private playlistEditorService: PlaylistEditorService,
    private datePipe: DatePipe,
    private session: SessionService,
    private encryptionService: EncryptionService,
    private modalService: NgbModal,
    private duplicatePlaylistsGQL: DuplicatePlaylistsGQL,
    private router: Router,
    private toasterService: ToasterService,
    private playlistViewService: PlaylistViewService,
    private channelService: ChannelService,
    public currentUserService: CurrentUserService,
    public responsiveUiService: ResponsiveUiService,
  ) {
    effect(() => {
      // setPendingPlaylist

      if (
        this.session.profileId() &&
        this.playlistStore.isDirty() &&
        !this.playlistStore.loading()
      ) {
        const playlistValue = {
          ...cloneDeep(this.playlist()),
        };
        this.playlistService.savePendingPlaylist(playlistValue);
      }
    });
  }

  ngOnInit() {
    this.initVariables();
    this.initSubscriptions();

    this.editPlaylistNameInput();

    // TODO: tab should be probably changed when changing playlist loaded (via slide panel)
    // -----
    this.initViewSettings();
    let defaultTab = PlaylistTabs.PREVIEW;
    if (this.invokedFrom === 'channelPage' || this.isInteractive()) {
      defaultTab = PlaylistTabs.CONTENT_GALLERY;
    }

    this.currentTab =
      this.invokedFrom != 'channelPage'
        ? this.viewSettings.currentTab || defaultTab
        : defaultTab;

    this.responsiveUiService.isMobileDevice()
      ? (this.activeIdSettingsPanel = 1)
      : (this.activeIdSettingsPanel = 2);
    this.isInteractive()
      ? (this.activeIdSettingsPanel = 3)
      : (this.activeIdSettingsPanel = 2);
  }

  // TODO: this should be done via store when loading the playlist
  // promptUnsavedChanges() {
  //   if (
  //     this.changeDetected &&
  //     !this.isCreateNewAsset &&
  //     confirm('There are unsaved changes. Do you want to save it?')
  //   ) {
  //     this.addPendingPlaylist();
  //     this.save();
  //   } else {
  //     this.removePendingPlaylist();
  //   }
  // }

  initVariables() {
    this.profileId = this.session.profileId() || '';

    this.currentTab = PlaylistTabs.PREVIEW;

    this.invalidDateRange = false;
  }

  initViewSettings() {
    if (this.playlist) {
      const setting = this.playlistViewService.getViewSettings(
        this.playlist.id(),
      );
      if (setting) {
        this.viewSettings = setting;
      } else {
        // TODO: this should be moved inside PlaylistViewService
        const playlistAssets: PlaylistAsset[] = [];
        for (const asset of this.playlist.assets()) {
          const playlistAssetContents: PlaylistAssetContent[] = [];
          for (const content of asset.content) {
            if (content.id) {
              playlistAssetContents.push({
                contentId: content.id,
                isOpen: false,
                isPinned: false,
              });
            }
          }
          // Default first content to be selected
          playlistAssetContents[0].isOpen = true;
          playlistAssets.push({
            assetId: asset.id,
            isCollapsed: false,
            contents: playlistAssetContents,
          });
        }
        this.viewSettings = {
          playlistId: this.playlist().id,
          currentTab: this.currentTab,
          assets: playlistAssets,
        };
        this.playlistViewService.saveViewSettings(this.viewSettings);
      }
    }
  }

  initSubscriptions() {
    this.uiMode$ = this.currentUserService.getUiMode();

    this.initChannels();
  }

  async initChannels() {
    this.channels = [];
    this.channels = await this.channelService.getCurrentProfileChannels();
  }

  setSelectedChannels(channelRegions: ChannelPlaylist[]) {
    this.playlistStore.setChannelRegions(channelRegions);
  }

  getNewIsoDate() {
    const d = new Date().toISOString();
    return d.substring(0, d.indexOf('T'));
  }

  // event can be any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCheckBoxStartDate(event: any) {
    if (event.target.checked === true) {
      this.playlistStore.setPlaylistStartEndDate(
        'startDate',
        this.getNewIsoDate(),
      );
    } else {
      this.playlistStore.setPlaylistStartEndDate('startDate', null);
    }
  }

  // event can be any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCheckBoxEndDate(event: any) {
    if (event.target.checked === true) {
      this.playlistStore.setPlaylistStartEndDate(
        'endDate',
        this.getNewIsoDate(),
      );
    } else {
      this.playlistStore.setPlaylistStartEndDate('endDate', null);
    }
  }

  setStartDate(value: string) {
    this.playlistStore.setPlaylistStartEndDate('startDate', value);
  }
  setEndDate(value: string) {
    this.playlistStore.setPlaylistStartEndDate('endDate', value);
  }

  updatePlaylistName() {
    this.playlistStore.setPlaylistName(
      this.playlistNameInput.nativeElement.value,
    );
    this.isEditingName = false;
  }

  transformDate(dateString: string) {
    return this.datePipe.transform(dateString, 'short');
  }

  async publish() {
    let validForm = this.playlistStore.isValid();

    if (!validForm) {
      return;
    }

    const channels = await this.channelService.getCurrentProfileChannels();
    const selectedChannelIds = (this.playlist().channelRegions || []).map(
      (x) => x.channelId,
    );

    const modal = this.modalService.open(PlaylistPublishingDialogComponent, {
      backdrop: 'static',
    });
    modal.componentInstance.playlistName = this.playlist.name();
    modal.componentInstance.channels = channels
      .filter((x) => !!x && selectedChannelIds?.includes(x.id))
      .map((x) => x?.name)
      .filter((x) => !!x);
    modal.componentInstance.activeChannels = this.activeChannels;
    modal.result
      .then(async (value) => {
        if (value && 'comment' in value) {
          const { comment } = value;

          await this.playlistStore.publish(comment);
        }
      })
      .catch(() => {});
  }

  async save() {
    const saved = await this.playlistStore.save();
    if (saved) {
      // no reason to keep
      this.playlistService.deletePendingPlaylist(this.playlist.id());
    }
  }

  delete() {
    // TODO
    // use playlistService to delete
    // use router to go to list
  }

  editPlaylistNameInput() {
    this.isEditingName = true;
    setTimeout(() => {
      this.playlistNameInput.nativeElement.value = this.playlist().name;
      this.playlistNameInput.nativeElement.select();
    });
  }

  revertToVersion(id: string) {
    const modal = this.modalService.open(PlaylistRevertVersionDialogComponent, {
      backdrop: 'static',
    });
    modal.result
      .then(() => {
        this.revertPlaylist(id);
      })
      .catch(() => {});
  }

  async revertPlaylist(id: string) {
    this.playlistStore.revertTo(id);
  }

  createCopy(id: string) {
    const modal = this.modalService.open(PlaylistCreateCopyDialogComponent, {
      backdrop: 'static',
    });
    modal.result
      .then(() => {
        this.duplicatePlaylist(id);
      })
      .catch(() => {});
  }

  duplicatePlaylist(id: string) {
    this.duplicatePlaylistsGQL.mutate({ ids: [id] }).subscribe(({ data }) => {
      if (
        data?.duplicatePlaylists.isSuccessful &&
        data.duplicatePlaylists.playlistIds?.length
      ) {
        const playlistId = this.encryptionService.encrypt(
          data.duplicatePlaylists.playlistIds[0] || '',
        );
        if (playlistId) {
          this.toasterService.success(
            'Successfully created a copy of the version.',
          );
          this.router.navigate(['/playlist/manage', playlistId]);
        }
      } else {
        this.toasterService.error('Failed to create a copy of the version.');
      }
    });
  }

  setCurrentTab(tab: PlaylistTabs) {
    this.currentTab = tab;
    this.playlistViewService.setPlaylistCurrentTab(this.currentTab);
  }
}

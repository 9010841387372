import { camelCase, startCase } from 'lodash';
import { v4 as uuidV4 } from 'uuid';

export function getCopyName(name: string, others: string[] = []) {
  let find = name;
  let count = 1;

  while (others.findIndex((x) => x === find) >= 0) {
    find = `${name}_${count}`;
    count++;
  }
  return find;
}

export function getUidString() {
  return uuidV4();
}

// based on https://stackoverflow.com/a/12502559
// output can be predictable but will do just fine for simple operations
export function getRandomString() {
  return Math.random().toString(36).slice(2);
}

export function pascalCase(str: string) {
  return startCase(camelCase(str)).replace(/ /g, '');
}

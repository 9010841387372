import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  effect,
  input,
  output,
} from '@angular/core';
import type { ECharts, EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { ChartType } from '@desquare/enums';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { CommonModule } from '@angular/common';

export type graphData = {
  name: string;
  value: string;
};

@Component({
  standalone: true,
  imports: [NgxEchartsDirective, CommonModule],
  selector: 'designage-echart',
  providers: [provideEcharts()],
  template: `<div
    echarts
    [options]="chartOption"
    (chartInit)="onChartInit($event)"
    [style.height.px]="height()"
    class="echart"
  ></div> `,
  styles: [
    `
      .echart {
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignageEchartComponent implements OnInit {
  chartType = input.required<ChartType>();
  chartFull = input<boolean>(false);
  inputData = input.required<[string, number][]>();
  timespanMinutes = input<number>(1440);
  dayZoom = input<string>();
  height = input<number>(128);
  unit = input<string>('');
  minValue = input<number>(0);
  maxValue = input<number>(100);
  relativeMinValue = input<boolean>(false);
  relativeMaxValue = input<boolean>(false);
  name = input<string>('');
  onChartClick = output<any>();

  // chartOption!: EChartsOption;
  echartsInstance?: ECharts;

  chartOption!: EChartsOption;

  chartOptionTemplate: EChartsOption = {
    textStyle: {
      color: '#fff',
      fontFamily: 'Nunito',
      fontSize: 16,
    },
    grid: {
      top: 10,
      left: '12%',
    },
    tooltip: {
      trigger: 'axis',
    },
    dataZoom: [
      {
        type: 'slider',
        realtime: true,
        rangeMode: ['value', 'value'],
        startValue: 0,
        endValue: DateTime.now().toISO(),
        brushSelect: false,
        moveHandleSize: 15,
        backgroundColor: '#323b44',
        borderColor: '#323b44',
        labelFormatter: (value) => {
          return DateTime.fromMillis(value).toFormat('HH:mm');
        },
        showDetail: true,
        showDataShadow: false,
        height: 15,
        textStyle: {
          color: '#fff',
          fontFamily: 'Nunito',
          fontSize: 12,
        },
      },
      {
        type: 'inside',
        startValue: DateTime.now()
          .minus({ minutes: this.timespanMinutes() })
          .toISO(),
        endValue: DateTime.now().toISO(),
      },
    ],
    xAxis: {
      type: 'time',
      position: 'bottom',
      splitLine: {
        show: true,
        lineStyle: { opacity: 0.2, color: '#82909E' },
      },
      max: () => {
        return DateTime.now().toISO();
      },
      min: () => {
        return DateTime.now()
          .minus({ minutes: this.timespanMinutes() })
          .toISO();
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        color: '#fff',
        formatter: (value) => {
          return DateTime.fromMillis(value).toFormat('HH:mm');
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#fff',
      },
      alignTicks: true,
      splitLine: {
        show: true,
        lineStyle: { opacity: 0.2, color: '#82909E' },
      },
    },
    series: {
      type: 'line',
      lineStyle: {
        color: '#323b44',
        width: 1,
        opacity: 1,
      },
      showSymbol: false,
      itemStyle: {
        opacity: 0.5,
        borderWidth: 0,
        borderColor: '#fff',
      },
    },
  };

  inputDataUpdates = effect(() => {
    const currentInputData = this.inputData();
    if (!currentInputData || !this.echartsInstance) return;
    if (this.chartFull()) {
      this.echartsInstance.setOption({
        series: {
          data: this.dayStatChecker(currentInputData),
        },
      });
    } else {
      this.echartsInstance.setOption({
        series: {
          data: currentInputData,
        },
      });
    }
  });

  timespanUpdates = effect(() => {
    const currentTimespanMinutes = this.timespanMinutes();
    if (!currentTimespanMinutes || !this.echartsInstance) return;
    this.echartsInstance.setOption({
      dataZoom: {
        startValue: () =>
          DateTime.now().minus({ minutes: currentTimespanMinutes }).toISO(),
        endValue: () => DateTime.now().toISO(),
      },
      xAxis: {
        min: () =>
          DateTime.now().minus({ minutes: currentTimespanMinutes }).toISO(),
      },
    });
  });

  dayZoomUpdates = effect(() => {
    const currentDayZoom = this.dayZoom();
    if (!currentDayZoom || !this.echartsInstance) return;
    this.echartsInstance.setOption({
      dataZoom: {
        startValue: DateTime.fromISO(currentDayZoom).startOf('day').toISO(),
        endValue: DateTime.fromISO(currentDayZoom).endOf('day').toISO(),
      },
      xAxis: {
        min: DateTime.fromISO(currentDayZoom).startOf('day').toISO(),
        max: DateTime.fromISO(currentDayZoom).endOf('day').toISO(),
      },
    });
  });

  ngOnInit(): void {
    switch (this.chartType()) {
      case ChartType.UPTIME:
        this.chartOption = this.chartFull()
          ? this.upTimeChartFull()
          : _.merge(this.chartOptionTemplate, this.upTimeChart());
        break;

      case ChartType.INFO:
        this.chartOption = _.merge(this.chartOptionTemplate, this.infoChart());
        break;

      default:
        break;
    }
  }

  onChartInit(ec: ECharts) {
    this.echartsInstance = ec;

    // Initial dayZoom setup if available
    if (this.dayZoom()) {
      this.echartsInstance.setOption({
        dataZoom: {
          startValue: DateTime.fromISO(
            this.dayZoom() || DateTime.now().toISODate(),
          )
            .startOf('day')
            .toISO(),
          endValue: DateTime.fromISO(
            this.dayZoom() || DateTime.now().toISODate(),
          )
            .endOf('day')
            .toISO(),
        },
        xAxis: {
          min: DateTime.fromISO(this.dayZoom() || DateTime.now().toISODate())
            .startOf('day')
            .toISO(),
          max: DateTime.fromISO(this.dayZoom() || DateTime.now().toISODate())
            .endOf('day')
            .toISO(),
        },
      });
    }

    this.echartsInstance.on('click', (params) => {
      this.onChartClick.emit(params.value);
    });
  }

  upTimeChart() {
    const uptimeConfig: EChartsOption = {
      grid: {
        show: true,
        // backgroundColor: '#f1556c',
      },
      tooltip: {
        show: false,
        trigger: 'item',
        valueFormatter: (value) => (value === 1 ? 'Online' : 'Offline'),
      },
      dataZoom: [
        {
          filterMode: 'none',
        },
        {
          filterMode: 'none',
        },
      ],
      xAxis: {
        axisPointer: {
          show: true,
          snap: true,
          label: {
            formatter: (params) => {
              // console.log('params', params);
              const status = params.seriesData[0].value as [string, number];
              return `${status[1] === 1 ? 'online' : 'offline'}: ${DateTime.fromJSDate(
                new Date(params.value),
              ).toFormat('HH:mm:ss')}`;
            },
            backgroundColor: '#323b44',
            shadowBlur: 10,
            shadowColor: '#323b44',
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          },
        },
      },
      yAxis: {
        axisLine: {
          show: false,
        },
        splitNumber: 1,
        axisLabel: {
          formatter: (axisValue: number) => {
            return axisValue === 1 ? 'Online' : 'Offline';
          },
        },
      },
      series: {
        data: this.inputData(),
        type: 'line',
        step: 'end',
        areaStyle: { color: '#178863', opacity: 1 },
        tooltip: {},
        lineStyle: {
          color: '#fff',
          width: 1,
          opacity: 0.7,
        },
        itemStyle: {
          color: (param) => {
            const value = param.data as [string, number];
            return value[1] === 1 ? '#178863' : '#f1556c';
          },
          opacity: 1,
          borderWidth: 0,
          borderColor: '#fff',
        },
      },
    };
    return uptimeConfig;
  }

  upTimeChartFull() {
    return {
      grid: { height: this.height() - 100 },
      tooltip: {
        show: true,
      },
      calendar: {
        itemStyle: {
          opacity: 1,
          color: '#323b44',
          borderColor: '#404D59',
          borderWidth: 0.5,
          borderType: 'dotted',
        },
        top: 'middle',
        // left: 'center',
        orient: 'horizontal',
        cellSize: ['auto', 25],
        splitLine: {
          show: true,
          lineStyle: { opacity: 1, color: '#404D59', borderWidth: 2 },
        },
        yearLabel: {
          show: false,
          fontSize: 30,
        },
        dayLabel: {
          color: '#fff',
          margin: 20,
          firstDay: 1,
          nameMap: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        monthLabel: {
          color: '#fff',
          margin: 20,
          show: true,
        },
        range: [
          DateTime.now().minus({ months: 2 }).toFormat('yyyy-MM'),
          DateTime.now().endOf('month').toFormat('yyyy-MM-dd'),
        ],
      },
      visualMap: {
        show: false,
        min: 1,
        max: 3,
        inRange: {
          color: ['#f1556c', '#c38822', '#407241'],
        },
      },
      series: [
        {
          type: 'heatmap',
          coordinateSystem: 'calendar',
          data: this.dayStatChecker(this.inputData()),
          itemStyle: {
            borderWidth: 0,
            borderColor: '#fff',
          },
          selectedMode: 'single',
          select: {},
          tooltip: {
            formatter: (params: any) => {
              return `select ${params.value[0]}`;
            },
          },
        },
      ],
    } as EChartsOption;
  }

  infoChart() {
    return {
      tooltip: {
        valueFormatter: (value: number) => `${value}${this.unit()}`,
      },
      yAxis: [
        {
          type: 'value',
          scale: true,
          boundaryGap: [0.2, 0.2],
          min: this.relativeMinValue()
            ? (value: { min: number }) => value.min - this.minValue()
            : undefined,
          max: this.relativeMaxValue()
            ? (value: { max: number }) => value.max + this.maxValue()
            : undefined,
          splitNumber: 4,
          axisLabel: {
            color: '#fff',
          },
          axisLine: {
            show: true,
            lineStyle: { color: '#82909E' },
          },
          axisTick: {
            show: true,
            lineStyle: { color: '#82909E' },
          },
          splitLine: {
            show: true,
            lineStyle: { opacity: 0.2, color: '#82909E' },
          },
        },
      ],
      series: {
        name: this.name(),
        data: this.inputData(),
        type: 'line',
        smooth: true,
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 1,
              color: '#3bafda',
            },
            {
              offset: 0,
              color: '#f1556c',
            },
          ]),
        },
      },
    } as EChartsOption;
  }

  dayStatChecker(inputData: [string, number][]) {
    const entriesByDay = _.groupBy(inputData, (d) =>
      DateTime.fromISO(d[0]).toFormat('yyyy-MM-dd'),
    );
    const groupedByDay: [string, number][] = [];
    Object.keys(entriesByDay).forEach((key) => {
      const onlines: number = entriesByDay[key].filter(
        (d) => d[1] === 1,
      ).length;
      const offlines: number = entriesByDay[key].filter(
        (d) => d[1] === 0,
      ).length;

      if (onlines > 0 && offlines === 0) groupedByDay.push([key, 3]);
      if (onlines > 0 && offlines > 0) groupedByDay.push([key, 2]);
      if (onlines === 0 && offlines > 0) groupedByDay.push([key, 1]);
    });
    return groupedByDay;
  }
}

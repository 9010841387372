import { Signal } from '@angular/core';
import { ILocationForm } from '@desquare/interfaces';
import { create, enforce, only, test } from 'vest';

export const locationFormValidation = (existingNames: Signal<string[]>) => {
  return create((model: ILocationForm, field: string) => {
    only(field);
    test('name', 'LOCATION_NAME_REQUIRED', () => {
      enforce(model.name).isNotBlank();
    });
    test('name', 'DUPLICATE_LOCATION_NAME', () => {
      enforce(model.name).notInside(existingNames() || []);
    });
    test('streetAddress1', 'REQUIRED', () => {
      enforce(model.streetAddress1).isNotBlank();
    });
    test('zip', 'REQUIRED', () => {
      enforce(model.zip).isNotBlank();
    });
    test('city', 'REQUIRED', () => {
      enforce(model.city).isNotBlank();
    });
    test('country', 'REQUIRED', () => {
      enforce(model.country).isNotBlank();
    });
  });
};

@if (loading()) {
  <app-loader [message]="loaderMessage"></app-loader>
} @else {
  <div class="container-fluid content-container-card d-flex flex-column p-2">
    <div class="h-100 overflow-auto">
      <!-- @if(loading()){
    <app-loader [message]="loaderMessage"></app-loader>} @else { -->
      <div class="d-flex ms-1 me-4">
        <h5 class="px-2 col-4 text-truncate">
          {{ 'PLAYLIST_NAME' | translate }}
        </h5>
        <!-- <h5 class="col-1">
          {{ 'STATUS' | translate }}
        </h5> -->
        <h5 class="px-2 col-3 col-xl-2 text-truncate">
          {{ 'PLAYLIST_TIMESTAMP' | translate }}
        </h5>
        @if (showLayoutRegions) {
          <h5 class="px-2 col-3 text-truncate">
            {{ 'REGION' | translate }}
          </h5>
        }

        <h5 class="px-4 col-2 col-xl-3 text-truncate text-end">
          <!-- {{ 'ACTIONS' | translate }} -->
        </h5>
      </div>
      @for (playlist of playlists(); track playlist.id) {
        @if (selectedPlaylistRegion() != null) {
          @if (playlist.region == selectedPlaylistRegion()) {
            <app-playlist-row
              (loaderMessage)="loaderMessage = $event"
              (removePlaylistId)="removePlaylistFromChannel($event)"
              (updatePlaylistRegion)="updateRegion($event)"
              [playlistRegions]="playlistRegions()"
              [layout]="layout() ?? undefined"
              [showLayoutRegions]="showLayoutRegions"
              [deviceId]="deviceId ?? ''"
              [playlist]="playlist"
              (updatePlaylist)="getPlaylists()"
            />
          }
        } @else {
          <app-playlist-row
            (loaderMessage)="loaderMessage = $event"
            (removePlaylistId)="removePlaylistFromChannel($event)"
            (updatePlaylistRegion)="updateRegion($event)"
            [playlistRegions]="playlistRegions()"
            [layout]="layout() ?? undefined"
            [showLayoutRegions]="showLayoutRegions"
            [deviceId]="deviceId ?? ''"
            [playlist]="playlist"
            (updatePlaylist)="getPlaylists()"
          />
        }
      }
      <!-- } -->
    </div>
    <div class="d-flex justify-content-between px-2 pt-3">
      <button
        (click)="addPlaylist(); $event.stopPropagation()"
        class="btn btn-outline-primary"
      >
        {{ 'ADD_PLAYLIST_TO_CHANNEL' | translate }}
      </button>
      @if (playlists().length) {
        <button
          (click)="rePublish(); $event.stopPropagation()"
          class="btn btn-outline-success"
          ngbTooltip="{{ 'REPUBLISH_PLAYLISTS_TOOLTIP' | translate }}"
        >
          @if (rePublishLoader()) {
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          } @else {
            {{ 'REPUBLISH_PLAYLISTS' | translate }}
          }
        </button>
      }
    </div>
  </div>
}
<ng-template #empty>
  <h5>{{ 'NO_PLAYLISTS_FOUND' | translate }}</h5>
</ng-template>

import {
  signalStore,
  withState,
  withComputed,
  withMethods,
  patchState,
  withHooks,
} from '@ngrx/signals';
import {
  MediaService,
  PlaylistService,
  ToasterService,
} from '@desquare/services';
import { Signal, computed, inject, signal } from '@angular/core';
import { lastValueFrom, pipe, switchMap } from 'rxjs';
import { GraphQLFormattedError } from 'graphql';
import {
  AssetItem,
  Channel,
  ChannelPlaylist,
  ChannelRegionInput,
  Maybe,
  MediaDetailedFragment,
  Playlist,
  PlaylistType,
  SaveOption,
  ScheduleDays,
  SmilRegion,
} from '@designage/gql';
import { IPlaylistRegion } from '@desquare/interfaces';
import { ApolloError } from '@apollo/client';
import { LocalStorageService } from 'ngx-webstorage';
import { localStorageKeys } from '@desquare/constants';
import { cloneDeep } from 'lodash';
import {
  arrayItemMove,
  ngbDateToIsoString,
  playlistEngine,
} from '@desquare/utils';

export interface IPlaylistSignal {
  /** initial status of playlist from db, WITHOUT open/pinned statuses */
  originalPlaylist?: Playlist;
  playlist: Playlist;
  loading: boolean;
  loaderMessage: string;
  errors: GraphQLFormattedError<Record<string, any>>[] | null;
}

const initialState: IPlaylistSignal = {
  originalPlaylist: undefined,
  playlist: playlistEngine.playlist.createNew(),
  loading: false,
  loaderMessage: '',
  errors: null,
};

/** isOpen and isPinned should not matter in dirty status recognition */
function cleanRuntimeFlags(p: Maybe<Playlist>) {
  if (p) {
    p.assets.forEach((a) => {
      a.content.forEach((item) => {
        delete item.isOpen;
        delete item.isPinned;
        // item.isOpen = null;
        // item.isPinned = null;
      });
    });
  }
  return p;
}

export const PlaylistStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),

  withMethods(
    (
      store,
      playlistService = inject(PlaylistService),
      mediaService = inject(MediaService),
      localStorageService = inject(LocalStorageService),
    ) => ({
      createNew: (name = 'New playlist', type = PlaylistType.Scheduled) => {
        localStorageService.store(localStorageKeys.ACTIVE_PLAYLIST_ID, '');
        const playlist = playlistEngine.playlist.createNew(name, type);
        patchState(store, {
          originalPlaylist: undefined,
          playlist,
          loading: false,
          errors: [],
        });
      },
      getPlaylistFromApi: async (id: string) => {
        return lastValueFrom(playlistService.getDetailedPlaylist$(id)).then(
          ({ playlist, loading, errors }) => {
            localStorageService.store(localStorageKeys.ACTIVE_PLAYLIST_ID, id);

            const p = cloneDeep(playlist);
            p.assets = playlistEngine.assets.sortDayParts(p.assets);

            patchState(store, {
              originalPlaylist: cloneDeep(p),
              playlist: p,
              loading,
              loaderMessage: 'FETCHING_PLAYLIST',
              errors: errors ? [...errors] : [],
            });
          },
        );
      },
      revertTo: async (id: string) => {
        return lastValueFrom(playlistService.getDetailedPlaylist$(id)).then(
          ({ playlist, loading, errors }) => {
            const reverted = cloneDeep(playlist);
            reverted.id = store.playlist().id;
            reverted.channelRegions = store.playlist().channelRegions;

            patchState(store, {
              playlist: reverted,
              loading,
              // loaderMessage: 'FETCHING_PLAYLIST',
              errors: errors ? [...errors] : [],
            });
          },
        );
      },
      save: async () => {
        const playlist = store.playlist();
        const { id, name, assets, channelRegions, type, startDate, endDate } =
          playlist;
        const channelIds: Maybe<ChannelRegionInput[]> = channelRegions?.map(
          (x) => {
            return { id: x.channelId, region: x.region };
          },
        );

        let savedPlaylist: Maybe<Playlist>;
        if (playlist.id && playlist.id !== '') {
          const ret = await playlistService.updatePlaylist({
            id,
            name,
            assets,
            channelIds,
            startDate,
            endDate,
            saveOption: SaveOption.Update,
          });
          if (ret && ret.isSuccessful && ret.playlist) {
            savedPlaylist = ret.playlist as Playlist;
          }
        } else {
          const ret = await playlistService.createPlaylist({
            name,
            assets,
            channelIds,
            type,
            startDate,
            endDate,
            profileId: '', // api will pick current profile
            saveOption: SaveOption.Draft,
          });
          if (ret && ret.isSuccessful) {
            savedPlaylist = ret.playlist as Playlist;
          }
        }
        if (savedPlaylist) {
          patchState(store, {
            playlist: savedPlaylist,
          });
          return true;
        }
        return false;
      },
      publish: async (comment: string) => {
        const playlist = store.playlist();
        const { id, name } = playlist;

        if (playlist.id && playlist.id !== '') {
          const ret = await playlistService.updatePlaylist({
            id,
            name,
            comment,
            assets: [],
            saveOption: SaveOption.Publish,
          });
          if (ret && ret.isSuccessful && ret.playlist) {
            const p = ret.playlist as Playlist;
            patchState(store, { playlist: p });
            playlistService.refreshPlaylistsFromApi();
          }
        }
      },
      setPlaylistName: (name: string) => {
        patchState(store, {
          playlist: { ...store.playlist(), name },
        });
      },
      setPlaylistStartEndDate: (
        field: 'startDate' | 'endDate',
        isoDate: string | null,
      ) => {
        const playlist = store.playlist();
        playlistEngine.playlist.setValue(playlist, field, isoDate);
        patchState(store, {
          playlist,
        });
      },
      setChannelRegions: (channelRegions: ChannelPlaylist[]) => {
        const playlist = store.playlist();
        // TODO: use the engine
        playlist.channelRegions = channelRegions;
        patchState(store, {
          playlist,
        });
      },

      /** Asset, DayPart, Sequence */
      setSequenceName: (assetIdx: number, name: string) => {
        const playlist = store.playlist();
        // doable but needed?
        playlistEngine.assets.setValue(playlist.assets[assetIdx], 'name', name);
        patchState(store, { playlist });
      },
      /** add new day part */
      addSequence: () => {
        const playlist = store.playlist();
        playlistEngine.assets.add(playlist);
        patchState(store, { playlist });
      },
      deleteSequence: (idx: number) => {
        const playlist = store.playlist();
        playlistEngine.assets.delete(playlist, idx);
        patchState(store, { playlist });
      },
      setDayPartStartTime: (assetIdx: number, isoTime = '00:00:00') => {
        const playlist = cloneDeep(store.playlist());

        playlistEngine.assets.setStartTime(playlist, assetIdx, isoTime);
        patchState(store, { playlist });
      },
      /** MEDIA ITEMS */
      /** add item to a sequence or day part */
      addAssetItem: (
        assetIdx: number,
        itemIdx: number = -1,
        item: AssetItem,
      ) => {
        const playlist = store.playlist();
        playlistEngine.items.addAssetItem(playlist, assetIdx, itemIdx, item);
        patchState(store, { playlist });
      },
      addMedia: (
        assetIdx: number,
        itemIdx: number = -1,
        mediaWithMetadata: MediaDetailedFragment,
      ) => {
        const playlist = store.playlist();
        playlistEngine.items.addMediaItem(
          playlist,
          assetIdx,
          itemIdx,
          mediaWithMetadata,
        );
        patchState(store, { playlist });
      },
      duplicateAssetItem: (assetIdx: number, itemIdx: number) => {
        const playlist = store.playlist();
        playlistEngine.items.duplicateItem(playlist, assetIdx, itemIdx);
        patchState(store, { playlist });
      },
      setAssetItemOpen: (assetIdx: number, itemIdx: number, open?: boolean) => {
        const playlist = store.playlist();
        const newOpen =
          open !== undefined
            ? open
            : !(playlist.assets[assetIdx].content[itemIdx].isOpen || false);
        if (newOpen) {
          // close all others if not pinned
          for (const item of playlist.assets[assetIdx].content) {
            item.isOpen = item.isPinned || false;
          }
        }
        playlist.assets[assetIdx].content[itemIdx].isOpen = newOpen;
        patchState(store, { playlist });
      },
      setAssetItemPin: (assetIdx: number, itemIdx: number, pin?: boolean) => {
        const playlist = store.playlist();
        const newPin = !!pin
          ? pin
          : !(playlist.assets[assetIdx].content[itemIdx].isPinned || false);
        playlist.assets[assetIdx].content[itemIdx].isPinned = newPin;
        patchState(store, { playlist });
      },
      deleteAssetItem: (assetIdx: number, itemIdx: number) => {
        const playlist = store.playlist();
        playlistEngine.items.deleteAssetItem(playlist, assetIdx, itemIdx);
        patchState(store, { playlist });
      },
      setAssetItemContent: (
        assetIdx: number,
        itemIdx: number,
        newAssetItem: AssetItem,
      ) => {
        const playlist = store.playlist();
        playlistEngine.items.replaceAssetItem(
          playlist,
          assetIdx,
          itemIdx,
          newAssetItem,
        );
        patchState(store, { playlist });
        // NOTE: this should be connected to setAssetItemMedia somehow
      },
      setAssetItemMedia: (
        assetIdx: number,
        itemIdx: number,
        mediaWithMetadata: MediaDetailedFragment,
      ) => {
        const playlist = store.playlist();
        // playlist.assets[assetIdx].content[itemIdx].days = days;
        playlistEngine.items.replaceMedia(
          playlist,
          assetIdx,
          itemIdx,
          mediaWithMetadata,
        );
        patchState(store, { playlist });
      },
      setAssetItemName: (assetIdx: number, itemIdx: number, name: string) => {
        const playlist = store.playlist();
        playlistEngine.items.setValue(
          playlist.assets[assetIdx].content[itemIdx],
          'name',
          name,
        );
        patchState(store, { playlist });
      },
      setAssetItemDays: (
        assetIdx: number,
        itemIdx: number,
        days: ScheduleDays,
      ) => {
        const playlist = store.playlist();
        playlistEngine.items.setItemDays(playlist, assetIdx, itemIdx, days);
        patchState(store, { playlist });
      },
      setAssetItemDuration: (
        assetIdx: number,
        itemIdx: number,
        durationMs: number,
      ) => {
        const playlist = store.playlist();
        // playlist.assets[assetIdx].content[itemIdx].days = days;
        playlistEngine.items.setValue(
          playlist.assets[assetIdx].content[itemIdx],
          'duration',
          durationMs,
        );
        patchState(store, { playlist });
      },
      setAssetItemCampaignDate: (
        assetIdx: number,
        itemIdx: number,
        field: 'campaignStart' | 'campaignEnd',
        isoDate: string | null,
      ) => {
        const playlist = store.playlist();

        playlistEngine.items.setValue(
          playlist.assets[assetIdx].content[itemIdx],
          field,
          isoDate,
        );
        patchState(store, {
          playlist,
        });
      },
      setAssetItemDisabled: (
        assetIdx: number,
        itemIdx: number,
        disabled: boolean,
      ) => {
        const playlist = store.playlist();

        playlistEngine.items.setValue(
          playlist.assets[assetIdx].content[itemIdx],
          'disabled',
          disabled,
        );
        patchState(store, {
          playlist,
        });
      },
      setAssetItemDisabledOptimization: (
        assetIdx: number,
        itemIdx: number,
        disabled: boolean,
      ) => {
        const playlist = store.playlist();

        playlistEngine.items.setItemDisabledOptimization(
          playlist,
          assetIdx,
          itemIdx,
          disabled,
        );

        patchState(store, {
          playlist,
        });
      },
      setItemPinned: (assetIdx: number, itemIdx: number, isPinned: boolean) => {
        const playlist = store.playlist();

        playlistEngine.items.setValue(
          playlist.assets[assetIdx].content[itemIdx],
          'isPinned',
          isPinned,
        );
        patchState(store, {
          playlist,
        });
      },
      moveAssetItem: (
        oldAssetIdx: number,
        oldItemIdx: number,
        newAssetIdx: number,
        newItemIdx: number,
      ) => {
        const playlist = store.playlist();
        arrayItemMove(
          oldItemIdx,
          newItemIdx,
          playlist.assets[oldAssetIdx].content,
          newAssetIdx !== oldAssetIdx
            ? playlist.assets[newAssetIdx].content
            : undefined,
        );
        console.log(
          'playlist.assets[oldAssetIdx]',
          playlist.assets[oldAssetIdx].content.length,
        );
        patchState(store, {
          playlist,
        });
      },
      unpinAll: () => {
        const playlist = store.playlist();
        playlist.assets.forEach((a) => {
          a.content.forEach((i) => {
            i.isPinned = false;
          });
        });
        patchState(store, {
          playlist,
        });
      },
      /** parse interactive regions, add target items to this sequence, remove target items not mapped in regions */
      setInteractiveItem: async (
        assetIdx: number,
        itemIdx: number,
        interactiveRegions: Maybe<SmilRegion>[],
        interactiveSource: string,
        width: number,
        height: number,
      ) => {
        const targetMediaIds = interactiveRegions
          .map((x) => x?.action?.target || '')
          .filter((x) => x !== '');
        const targetMedia = await mediaService.getMediaByIds(targetMediaIds);
        const playlist = store.playlist();
        playlistEngine.items.setInteractiveItem(
          playlist,
          assetIdx,
          itemIdx,
          interactiveSource,
          interactiveRegions,
          width,
          height,
          targetMedia || [],
        );
        patchState(store, {
          playlist,
        });
      },
    }),
  ),
  withHooks((store) => ({
    onInit() {
      // do nothing for now
    },
  })),
  withComputed((state) => ({
    isAnythingPinned: computed(() => {
      const p = state.playlist();

      for (const a of p.assets) {
        for (const i of a.content) {
          if (i.isPinned) return true;
        }
      }

      return false;
    }),
    /** evaluate changes except open/pinned statuses */
    isDirty: computed(() => {
      const oldState = state.originalPlaylist
        ? JSON.stringify(cleanRuntimeFlags(state.originalPlaylist()))
        : '';
      const newState = state.playlist
        ? JSON.stringify(cleanRuntimeFlags(state.playlist()))
        : '';
      return oldState !== newState;
    }),
    isValid: computed(() => {
      return playlistEngine.isValid(state.playlist());
    }),
  })),
);

@if (device(); as device) {
  @if (device.deviceInfo !== null) {
    <!-- Device Information -->
    <table class="table table-sm">
      <thead>
        <tr>
          <th scope="col" colspan="2">
            {{ 'DEVICE_INFORMATION' | translate }}
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ 'DEVICE_NAME' | translate }}</td>
          <td>
            <div class="d-flex justify-content-between">
              <span class="text-truncate">
                {{ device.name }}
              </span>
              <copy-to-clipboard [cbcontent]="device.name" />
            </div>
          </td>
        </tr>
        <tr>
          <td>{{ 'LOCATION' | translate }}</td>
          <td>{{ device.location?.name }}</td>
        </tr>
        <br />
        <tr>
          <td>
            {{ 'DEVICE_TYPE' | translate }}
          </td>
          <td>
            {{ device.deviceInfo?.hardwareBrand ?? '' | titlecase }}
            {{ device.deviceInfo?.applicationType | titlecase }}
            {{ device.deviceInfo?.osVersion?.version }}
          </td>
        </tr>
        <tr>
          <td>{{ 'DEVICE_MODEL' | translate }}</td>
          <td>{{ device.deviceInfo?.model | titlecase }}</td>
        </tr>

        <tr>
          <td>{{ 'SERIAL' | translate }}</td>
          <td>
            <div class="d-flex justify-content-between">
              <span class="text-truncate">
                {{ device.serialNumber }}
              </span>
              @if (device.serialNumber) {
                <copy-to-clipboard [cbcontent]="device.serialNumber" />
              }
            </div>
          </td>
        </tr>
        <tr>
          <td>{{ 'FIRMWARE' | translate }}</td>
          <td>
            <div class="d-flex justify-content-between">
              <span class="text-truncate">
                {{ device.deviceInfo?.firmware }}
              </span>
              @if (device.deviceInfo?.firmware) {
                <copy-to-clipboard
                  [cbcontent]="device.deviceInfo?.firmware ?? ''"
                />
              }
            </div>
          </td>
        </tr>
        @if (device.deviceInfo?.memory) {
          <tr>
            <td>{{ 'DEVICE_MEMORY' | translate }}</td>
            <td>
              @if (device.deviceInfo) {
                <app-device-memory
                  class="justify-content-start"
                  [deviceInfo]="device.deviceInfo"
                  [compressed]="false"
                />
              }
            </td>
          </tr>
        }
        @if (device.deviceInfo?.cpu) {
          <tr>
            <td>{{ 'DEVICE_CPU' | translate }}</td>
            <td>
              @if (device.deviceInfo) {
                <app-device-cpu
                  class="justify-content-start"
                  [deviceInfo]="device.deviceInfo"
                />
              }
            </td>
          </tr>
        }
        @if (
          device.deviceInfo?.monitors &&
          device.deviceInfo?.monitors?.length! > 0
        ) {
          <tr>
            <td>{{ 'CONNECTED_MONITORS' | translate }} (EDID)</td>
            <td>
              @for (monitor of device.deviceInfo?.monitors; track monitor) {
                <div class="text-truncate">
                  <span class="me-1">
                    Manufacturer: {{ monitor?.manufacturer }} -
                    {{ monitor?.model }}</span
                  >
                  <span> serial: {{ monitor?.serial }} </span>
                </div>
              }
            </td>
          </tr>
        }
        @if (device.deviceInfo?.support4k) {
          <tr>
            <td [colSpan]="2">
              <span class="mx-2 fs-3"> <i class="ri-4k-fill"></i></span>
              {{ 'DEVICE_SUPPORTS_UHD' | translate }}
            </td>
          </tr>
        }
      </tbody>
    </table>

    <!-- Device Software -->
    <table class="table table-sm">
      <thead>
        <tr>
          <th scope="col" colspan="2">
            {{ 'DEVICE_SOFTWARE' | translate }}
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ 'APPLET_VERSION' | translate }}</td>
          <td>
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex w-100">
                @if (
                  currentUserService.isSuperAdmin ||
                  currentUserService.isProfileManager
                ) {
                  <span
                    ngbTooltip="{{
                      device.signageOSDeviceTiming?.activeAppletVersion ===
                      device.deviceInfo?.appletVersion
                        ? ('UP_TO_DATE' | translate)
                        : ('UPDATE_AVAILABLE' | translate)
                    }}"
                    class="badge"
                    [ngClass]="
                      device.signageOSDeviceTiming?.activeAppletVersion ===
                      device.deviceInfo?.appletVersion
                        ? 'bg-success'
                        : 'bg-warning'
                    "
                  >
                    {{ device.deviceInfo?.appletVersion }}
                  </span>
                  @if (
                    device.signageOSDeviceTiming?.activeAppletVersion !==
                    device.deviceInfo?.appletVersion
                  ) {
                    <span
                      class="badge rounded-pill bg-primary cursor-pointer mx-2 text-truncate"
                      [ngbPopover]="popoverUpgradeApplet"
                      [popoverTitle]="'APPLET_UPDATE_VERSION' | translate"
                      ><i class="ri-arrow-up-fill me-1"></i
                      >{{ 'UPDATE_AVAILABLE' | translate }}</span
                    >
                  }
                } @else {
                  <span class="text-truncate">{{
                    device.deviceInfo?.appletVersion
                  }}</span>
                }
              </div>
              @if (device.deviceInfo?.appletVersion) {
                <copy-to-clipboard
                  [cbcontent]="device.deviceInfo?.appletVersion ?? ''"
                />
              }
              <ng-template #popoverUpgradeApplet>
                {{ 'BULK_ACTION_TT_APPLET_UPDATE_VERSION' | translate }}:
                {{ device.signageOSDeviceTiming?.activeAppletVersion }}
                <br />
                <button
                  (click)="updateAppletVersion(device)"
                  class="my-2 btn btn-sm btn-outline-warning"
                >
                  {{ 'CLICK_TO_UPDATE' | translate }}
                </button>
                <br />
                <div class="badge text-warning">
                  Warning: this operation will restart playback
                </div>
              </ng-template>
            </div>
          </td>
        </tr>
        <tr>
          <td>{{ 'CORE_APP_VERSION' | translate }}</td>
          <td>
            <div class="d-flex justify-content-between">
              <span class="text-truncate">
                {{ device.deviceInfo?.applicationVersion }}
              </span>
              @if (device.deviceInfo?.applicationVersion) {
                <copy-to-clipboard
                  [cbcontent]="device.deviceInfo?.applicationVersion ?? ''"
                />
              }
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Network -->
    <table class="table table-sm">
      <thead>
        <tr>
          <th scope="col" colspan="2">
            <div class="d-flex justify-content-between">
              <div>
                {{ 'NETWORK_INFORMATION' | translate }}
              </div>
              @if (
                device.deviceInfo?.networkAdapters &&
                device.deviceInfo?.networkAdapters?.length
              ) {
                <div
                  class="px-1 cursor-pointer bg-secondary rounded-circle"
                  (click)="miniMenuOpen = !miniMenuOpen"
                  ngbTooltip="{{ 'SHOW_ALL_NETWORK' | translate }}"
                >
                  {{ miniMenuOpen ? '-' : '+' }}
                </div>
              }
            </div>
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      @if (device.deviceInfo && device.deviceInfo.networkAdapters) {
        <tbody>
          @if (!miniMenuOpen) {
            @if (
              device.deviceInfo
                ? getNetworkAdaptersUtil(device.deviceInfo).activeAdapters[0]
                : null;
              as adapter
            ) {
              <tr>
                <td>{{ 'NETWORK_ACTIVE_INTERFACE' | translate }}</td>
                <td>
                  <div class="d-flex">
                    {{ adapter.type | titlecase }}
                    @if (adapter.type === 'wifi' && adapter.wifiStrength) {
                      <wifi-strength-indicator
                        class="ps-2"
                        [signal]="adapter.wifiStrength"
                      />
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  {{ 'IP' | translate }}
                </td>
                <td>
                  <div class="d-flex justify-content-between">
                    <span class="text-truncate">
                      {{ adapter.localAddress }}
                    </span>
                    @if (adapter.localAddress) {
                      <copy-to-clipboard [cbcontent]="adapter.localAddress" />
                    }
                  </div>
                </td>
              </tr>
            }
          } @else {
            @for (
              adapter of getNetworkAdaptersUtil(device.deviceInfo)
                .activeAdapters;
              track adapter.macAddress;
              let i = $index;
              let isFirst = $first;
              let isLast = $last;
              let c = $count
            ) {
              <tr class="text-decoration-underline">
                <td colspan="2">
                  {{ 'NETWORK_ADAPTER' | translate }} {{ i + 1 }}
                  @if (isFirst) {
                    <span class="fw-bolder"> {{ 'ACTIVE' | translate }} </span>
                  }
                </td>
              </tr>
              <tr>
                <td>{{ 'NAME' | translate }}</td>
                <td>
                  <div class="d-flex justify-content-between">
                    <span class="text-truncate">
                      {{ adapter.name }}
                    </span>
                    <copy-to-clipboard
                      [cbcontent]="adapter.name"
                    ></copy-to-clipboard>
                  </div>
                </td>
              </tr>

              <tr>
                <td>{{ 'NETWORK_ACTIVE_INTERFACE' | translate }}</td>
                <td>{{ adapter.type }}</td>
              </tr>
              @if (adapter.type === 'wifi') {
                <tr>
                  <td>{{ 'IP_WIFI_STRENGTH' | translate }}</td>
                  <td>{{ adapter.wifiStrength }}%</td>
                </tr>
                <tr>
                  <td>{{ 'IP_WIFI_SSID' | translate }}</td>
                  <td>{{ adapter.wifiSsid }}</td>
                </tr>
              }
              <tr>
                <td>{{ 'IP' | translate }}</td>
                <td>
                  <div class="d-flex justify-content-between">
                    <span class="text-truncate">
                      {{ adapter.localAddress }}
                    </span>
                    @if (adapter.localAddress) {
                      <copy-to-clipboard [cbcontent]="adapter.localAddress" />
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ 'IP_NETMASK' | translate }}</td>
                <td>
                  <div class="d-flex justify-content-between">
                    <span class="text-truncate">
                      {{ adapter.netmask }}
                    </span>
                    @if (adapter.netmask) {
                      <copy-to-clipboard [cbcontent]="adapter.netmask" />
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ 'IP_GATEWAY' | translate }}</td>
                <td>
                  <div class="d-flex justify-content-between">
                    <span class="text-truncate">
                      {{ adapter.gateway }}
                    </span>
                    @if (adapter.gateway) {
                      <copy-to-clipboard [cbcontent]="adapter.gateway" />
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ 'IP_DNS' | translate }}</td>
                <td>
                  @for (dns of adapter.dns; track dns) {
                    <div class="mt-1 d-flex justify-content-between">
                      <span class="text-truncate">
                        {{ dns }}
                      </span>
                      @if (dns) {
                        <copy-to-clipboard [cbcontent]="dns" />
                      }
                    </div>
                  }
                </td>
              </tr>
              <tr>
                <td>{{ 'NETWORK_MAC' | translate }}</td>
                <td>
                  <div class="d-flex justify-content-between">
                    <span class="text-truncate">
                      {{ adapter.macAddress | uppercase }}
                    </span>
                    <copy-to-clipboard [cbcontent]="adapter.macAddress" />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  {{ adapter.disabled ? 'DISABLED' : ('ENABLED' | translate) }}
                </td>
                <td></td>
              </tr>
              @if (c > 1 && !isLast) {
                <br />
              }
            }
          }
        </tbody>
      }
    </table>

    <!-- Date Time -->
    <table class="table table-sm">
      <thead>
        <tr>
          <th scope="col" colspan="2">
            {{ 'TIME_INFORMATION' | translate }}
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ 'LOCAL_DEVICE_TIME' | translate }}</td>
          @if (count$ | async; as count) {
            <td>
              {{
                (device.status?.Status === 1
                  ? count
                  : device.deviceInfo?.currentTime?.currentDate
                ) | date: 'mediumTime' : undefined : device.timezoneOffset
              }}
            </td>
          }
        </tr>
        <tr>
          <td>{{ 'DATE' | translate }}</td>
          <td>
            {{
              device.deviceInfo?.currentTime?.currentDate | date: 'mediumDate'
            }}
          </td>
        </tr>
        <tr>
          <td>{{ 'TIME_ZONE' | translate }}</td>
          <td>
            {{ device.deviceInfo?.currentTime?.timezone }} (
            {{ device.timezoneOffset }} )
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Status -->
    <table class="table table-sm">
      <thead>
        <tr>
          <th scope="col" colspan="2">
            {{ 'DEVICE_STATUS' | translate }}
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ 'STATUS' | translate }}</td>
          <td>
            {{ getStatusEnum(device.status?.Status) | titlecase }}
          </td>
        </tr>
        <tr>
          <td>
            {{
              getStatusEnum(device.status?.Status) !== 'online'
                ? ('OFFLINE_FOR' | translate)
                : ('PING' | translate)
            }}
          </td>
          <td>
            <span
              ngbTooltip="{{
                (getStatusEnum(device.status?.Status) !== 'online'
                  ? device.deviceInfo?.currentTime?.currentDate
                  : device.lastPing
                ) | date: 'long'
              }}"
            >
              {{
                (getStatusEnum(device.status?.Status) !== 'online'
                  ? device.deviceInfo?.currentTime?.currentDate
                  : device.lastPing
                ) | amTimeAgo
              }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    @if (isUserSuperAdmin) {
      <!-- Debug -->
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col" colspan="2">
              <div class="d-flex justify-content-between">
                <div>Admin device debug</div>
                <div
                  class="px-1 cursor-pointer bg-secondary rounded-circle"
                  (click)="debugMiniMenuOpen = !debugMiniMenuOpen"
                >
                  {{ debugMiniMenuOpen ? '-' : '+' }}
                </div>
              </div>
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          @if (debugMiniMenuOpen) {
            <tr>
              <td>deviceId:</td>
              <td>
                {{ device.id }}
                <copy-to-clipboard [cbcontent]="device.id"></copy-to-clipboard>
              </td>
            </tr>
            <tr>
              <td>data url:</td>
              <td>
                {{ s3playlistsUrl() }}
                <copy-to-clipboard [cbcontent]="s3playlistsUrl()" />
              </td>
            </tr>
          }
        </tbody>
      </table>
    }
  } @else {
    <div class="d-flex flex-column justify-content-between">
      <hr />
      <ngx-skeleton-loader
        count="5"
        animation="progress-dark"
        [theme]="{
          height: '0.5rem',
        }"
      />
      <hr />
      <ngx-skeleton-loader
        count="5"
        animation="progress-dark"
        [theme]="{
          height: '0.5rem',
        }"
      />
      <hr />
      <ngx-skeleton-loader
        count="5"
        animation="progress-dark"
        [theme]="{
          height: '0.5rem',
        }"
      />
    </div>
  }
}

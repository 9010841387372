import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import {
  AssetItem,
  AssetType,
  Maybe,
  Media,
  ResourceType,
} from '@designage/gql';
import { SubSink } from 'subsink';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ToasterService } from '@desquare/services';
import { environment } from '@desquare/environments';
import moment from 'moment';
import {
  trapLetters as _trapLetters,
  getRandomString,
  AssetItemIsImage,
  AssetItemIsVideo,
  AssetItemIsHtml,
  AssetItemIsIFrame,
  mediaToAssetItem,
  resourceTypeToAssetItemTypeName,
} from '@desquare/utils';
import { TranslateModule } from '@ngx-translate/core';
import { MediaListComponent } from '@designage/app/media/media-list/media-list.component';
import { AngularSplitModule } from 'angular-split';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    AngularSplitModule,
    TranslateModule,
    MediaListComponent,
    NgbTooltip,
  ],
  selector: 'app-asset-content-form',
  template: `
    <form
      class="h-100"
      [id]="formId"
      [formGroup]="contentForm"
      (submit)="submitForm($event, contentForm.value)"
      class="h-100"
    >
      <as-split unit="percent" direction="horizontal" useTransition="true">
        <as-split-area size="65" class="scrollable-list">
          <app-media-list
            [isAssetSelection]="true"
            [multiSelect]="multiSelect"
            [filterMediaTypes]="filterMediaTypes"
            [showBulkAction]="false"
            [showCreativeEditor]="false"
            [enableSlidePanel]="false"
            (selectedMediaList)="onAssetsSelect($event)"
            (selectedMedia)="onAssetSelect($event)"
          ></app-media-list>
        </as-split-area>
        @if (isMetadataAvailable) {
          <as-split-area size="35" minSize="35">
            <div
              class="h-100 w-100 d-flex justify-content-center align-items-center"
            >
              @if (isVideo && isMetadataAvailable) {
                <div class="h-100">
                  <video
                    [src]="videoAddress"
                    class="mw-100 h-100"
                    controls="true"
                  ></video>
                </div>
              }
              @if (isImage || isHtml || isRaw || !isMetadataAvailable) {
                <img
                  [src]="mediaUrl"
                  alt="{{ 'IMAGE' | translate }}"
                  class="img-fluid landscape"
                  ngbTooltip="{{ title | translate }}"
                />
              }
            </div>
          </as-split-area>
        }
      </as-split>
    </form>
  `,
  styles: [
    `
      :host {
        display: block;
        height: 100%;
        overflow: hidden;
      }
      .landscape {
        max-height: 650px;
      }

      .time-picker-box {
        width: 213px;
      }

      .scrollable-list {
        overflow-y: auto;
      }
      /* 
      as-split {
         height: 70vh;
      }*/
    `,
  ],
})
export class AssetContentFormComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  @Output() submitted = new EventEmitter<AssetItem>();
  @Output() value = new EventEmitter<AssetItem>();
  @Output() valid = new EventEmitter<boolean>();
  @Output() pristine = new EventEmitter<boolean>();
  @Input() resource!: Maybe<AssetItem>;
  @Input() locations: Location[] = [];
  @Input() formId = 'contentForm';
  @Input() profileId!: string;
  @Input() multiSelect = true;
  @Input() filterMediaTypes = '';

  media!: Media;
  selectedMediaList: Media[] = [];
  contentForm!: FormGroup;
  minimumTime = moment.unix(1).utc();
  defaultTime = 10000; // 10000ms or 10sec.
  durationInput!: Maybe<string>;

  validDurationFormat = true;
  validDurationValue = true;
  trapLetters = _trapLetters;

  constructor(
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
  ) {}

  get title() {
    return this.media && this.media.metadata
      ? this.media.name
      : 'METADATA_MISSING_ERROR';
  }

  get mediaUrl() {
    if (this.media && this.media.metadata) {
      return this.media.secureUrl;
    } else if (this.resource && 'uri' in this.resource) {
      return this.resource.uri;
    } else if (this.media && !this.media.metadata) {
      return environment.assets.missingImagePlaceholder;
    }

    return environment.assets.placeholderImage;
  }

  get isImage() {
    return (
      AssetItemIsImage(this.resource) || this.media?.type === ResourceType.Image
    );
  }

  get isVideo() {
    return (
      AssetItemIsVideo(this.resource) || this.media?.type === ResourceType.Video
    );
  }

  get isHtml() {
    return AssetItemIsHtml(this.resource);
  }

  get isIFrame() {
    return AssetItemIsIFrame(this.resource);
  }

  get isRaw() {
    return this.media?.type === ResourceType.Raw;
  }

  get showDuration() {
    return this.isImage || this.isHtml || this.isIFrame;
  }

  get isMetadataAvailable() {
    return this.resource || this.media?.metadata;
  }

  get videoAddress() {
    if (this.resource && 'uri' in this.resource) {
      return this.resource.uri;
    }

    return this.media.secureUrl;
  }

  ngOnInit() {
    this.initVariables();
    this.initForm();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initVariables() {
    this.valid.emit(false);
    this.pristine.emit(true);
  }

  initForm() {
    // controls config needs to be any so we can extend extra fields
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let controlsConfig: any = {
      id: [null, Validators.required],
      contentId: [
        this.resource ? this.resource.contentId : Validators.required,
      ],
      name: [this.resource ? this.resource.name : null, Validators.required],
      type: [this.resource ? this.resource.__typename : null],
      campaignStart: [this.resource ? this.resource.campaignStart : null],
      campaignEnd: [this.resource ? this.resource.campaignEnd : null],
    };

    if (this.resource) {
      if ('publicId' in this.resource) {
        controlsConfig.publicId = [this.resource.publicId, Validators.required];
      }

      if ('uri' in this.resource) {
        controlsConfig.uri = [this.resource.uri, Validators.required];
      }

      if (
        this.resource?.duration !== null &&
        this.resource?.duration !== undefined
      ) {
        const duration = this.resource.duration;
        controlsConfig.duration = [duration, Validators.required];
        this.durationInput = this.millisecondsToHMSFormat(duration);
      }

      if (this.resource?.id) {
        controlsConfig.id = [this.resource.id, Validators.required];
      }
    }

    if (this.media) {
      // Controls config needs to be any so it can be extended
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const mediaControlsConfig: any = {
        id: [getRandomString(), Validators.required],
        contentId: [this.media.id, Validators.required],
        publicId: [this.media.publicId, Validators.required],
        name: [this.media.name, Validators.required],
        type: [this.media.type, Validators.required],
        uri: [this.media.secureUrl, Validators.required],
        media: [this.media, Validators.required],
        __typename: [
          this.media ? resourceTypeToAssetItemTypeName(this.media.type) : null,
          Validators.required,
        ],
        mediaList: [],
      };

      if (this.media.type !== ResourceType.Video) {
        mediaControlsConfig.duration = [this.defaultTime, Validators.required];
        this.durationInput = this.millisecondsToHMSFormat(this.defaultTime);
      }

      controlsConfig = { ...controlsConfig, ...mediaControlsConfig };
    }

    this.contentForm = this.formBuilder.group(controlsConfig);

    if (
      this.contentForm.controls.mediaList &&
      this.selectedMediaList?.length > 0
    ) {
      const mediaList: AssetItem[] = [];
      for (const selectedMedia of this.selectedMediaList) {
        const assetItem = mediaToAssetItem(selectedMedia, this.defaultTime);
        if (!!assetItem) {
          mediaList.push(assetItem);
        }
      }
      this.contentForm.controls.mediaList.patchValue(mediaList);
    }

    this.subs.sink = this.contentForm.valueChanges.subscribe(() => {
      if (this.contentForm) {
        this.valid.emit(this.contentForm.valid);
        this.pristine.emit(this.contentForm.pristine);
        this.value.emit(this.contentForm.value);
      }
    });
  }

  // submit form event can be any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitForm(event: any, values: AssetItem) {
    if (this.resource) {
      values.contentId = this.resource.contentId;
    }

    this.submitted.emit(values);
  }

  onAssetSelect(media: Media) {
    this.media = media;
    // adds FormControl to contentForm depending on the media.type and patches value from the selected asset
    // if (this.media && this.media.metadata) {
    //   this.contentForm.reset();
    //   this.initForm();
    //   // needed to update form pristine state
    //   this.contentForm.updateValueAndValidity();
    // } else {
    //   this.toasterService.error('MEDIA_UNAVAILABLE');
    // }
  }

  onAssetsSelect(mediaList: Media[]) {
    this.selectedMediaList = mediaList;
    if (this.selectedMediaList.length > 0) {
      this.media = this.media ? this.media : this.selectedMediaList[0];
      this.contentForm.reset();
      this.initForm();
      // needed to update form pristine state
      this.contentForm.updateValueAndValidity();
      return;
    }
    return this.contentForm.reset();
  }

  onDurationInputChange(value: string) {
    this.contentForm.controls.duration.markAsDirty();
    this.validDurationFormat = moment(value, 'HH:mm:ss', true).isValid();
    this.validDurationValue = value !== '00:00:00';
    if (this.validDurationFormat && this.validDurationValue) {
      this.contentForm.patchValue({
        duration: value,
      });
      this.durationInput = value;
    } else {
      this.durationInput = null;
      this.contentForm.patchValue({
        duration: null,
      });
    }
  }

  millisecondsToHMSFormat(ms: number) {
    return moment(moment.unix(ms / 1000).utc()).format('HH:mm:ss');
  }
}

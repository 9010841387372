import {
  DestroyRef,
  EventEmitter,
  Injectable,
  OnDestroy,
  Output,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  BulkActionDevicesGQL,
  ChannelBulkAction,
  ChannelLocationForMapFragment,
  DeviceInfo,
  GetProfileDevicesForMapGQL,
  GetSingleDeviceForMapGQL,
  DevicePowerAction,
  SetDevicePowerActionGQL,
  Maybe,
  GetDeviceMonitorHistoryGQL,
  DeleteDeviceGQL,
  DeleteDeprovisionedDeviceGQL,
  ProvisionDeviceGQL,
  ProvisionDeviceInput,
} from '@designage/gql';
import {
  IChannelFilter,
  ISortOrderDirection,
  IScreenshotUrlArray,
  IDeviceLog,
  IDeviceInfo,
  IVerifyDeviceForm,
} from '@desquare/interfaces';
import { DeviceStatusCode, DeviceStatusInfo } from '@desquare/models';
import {
  DeviceData,
  DeviceMonitorData,
  DeviceStatusChangeEventArg,
  DeviceStatusData,
} from '@desquare/types';
import {
  debounce,
  getDeviceTime,
  getScreenshotUrl,
  getTimezoneOffsetByName,
} from '@desquare/utils';
import { ApolloError } from '@apollo/client/errors';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { SubSink } from 'subsink';
import { ToasterService } from '../toaster/toaster.service';
import { UiDataService } from '../ui-data/ui-data.service';
import { TopicInfo, TopicLogic } from './mqtt.topic.logic';
import { QoS } from 'mqtt-packet/types';
import { orderBy } from 'lodash';
import {
  Observable,
  filter,
  lastValueFrom,
  map,
  merge,
  scan,
  switchMap,
  tap,
} from 'rxjs';
// import * as momentTz from 'moment-timezone';
import { getTimeZones } from '@vvo/tzdb';
import moment from 'moment';
import { SessionService } from '../session/session.service';
import { deviceInfoState, deviceStatusState } from '../session/session';
import { signal } from '@angular/core';
import { CurrentUserService } from '../current-user/current-user.service';
import { ChannelService } from '../channel/channel.service';
import { GraphQLFormattedError } from 'graphql';
import { LocationService } from '../location/location.service';

const timeZones = getTimeZones();

@Injectable({
  providedIn: 'root',
})
export class DeviceDataService implements OnDestroy {
  provisionDeviceGql = inject(ProvisionDeviceGQL);
  profileId = inject(CurrentUserService).getCurrentProfileId();
  private subs = new SubSink();
  private mqttSubs = new SubSink();
  private SubShot = new SubSink();

  devices: DeviceData[] = [];

  get notInstalledChannelCount() {
    return 0;
  }

  // MARK: Sources
  devicesFromGql$ = inject(GetProfileDevicesForMapGQL).watch(
    { id: inject(CurrentUserService).getCurrentProfileId() },
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    },
  );

  location: ChannelLocationForMapFragment | null = null;
  screenshotArray!: IScreenshotUrlArray;

  /** to be emitted when visibilityFilter changes */
  @Output() deviceDataChanged = new EventEmitter();
  /** possibly fired very often, use only in monitor components on single device */
  @Output() deviceInfoChanged = new EventEmitter<DeviceInfo>();
  /** possibly fired very often, use only in monitor components on single device */
  @Output() deviceStatusChanged =
    new EventEmitter<DeviceStatusChangeEventArg>();

  loading = signal<boolean>(true);
  private _mqttService = inject(MqttService);
  private destroyRef = inject(DestroyRef);
  public currentUserService = inject(CurrentUserService);

  constructor(
    private sessionService: SessionService,
    public channelService: ChannelService,
    private toasterService: ToasterService,
    private bulkActionDevicesGQL: BulkActionDevicesGQL,
    private getProfileDevicesGQL: GetProfileDevicesForMapGQL,
    private getDeviceMonitorHistoryGQL: GetDeviceMonitorHistoryGQL,
    private getSingleDeviceGQL: GetSingleDeviceForMapGQL,
    private setDevicePowerActionGQL: SetDevicePowerActionGQL,
    private uiDataService: UiDataService,
    private deleteDevice: DeleteDeviceGQL,
    private forceDeleteDevice: DeleteDeprovisionedDeviceGQL,
  ) {
    this.uiDataService.currentStatusFilter$.subscribe(() => {
      this.filterVisibleDevices();
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.mqttSubs.unsubscribe();
    this.SubShot.unsubscribe();
  }

  /**
   * too many status change events could occur in a short time (many autonomous devices)
   * use this function to group in a single event
   */
  private fireDebouncedChangeEvent = debounce(this.doFireChangeEvent, 250, {
    isImmediate: false,
  });

  getDevicesFromApi$(): Observable<{
    devices: DeviceData[];
    loading: boolean;
    errors: readonly GraphQLFormattedError<Record<string, any>>[] | undefined;
  }> {
    return this.devicesFromGql$.valueChanges.pipe(
      map(({ data, errors, loading }) => {
        const devices: DeviceData[] = (data.profile?.devices || []).map(
          (d: DeviceData) => ({
            ...d,
            lastPing: d.deviceInfo?.currentTime?.currentDate,
          }),
        );
        return { devices, loading, errors };
      }),
    );
  }

  refreshDevicesFromApi() {
    this.devicesFromGql$.refetch();
  }

  getDeviceDataFromMqtt$() {
    const topic = TopicLogic.allProfileDevicesTopic(this.profileId);
    return this._mqttService.observe(topic);
  }

  // MARK: device parse mqtt data
  parseMqttDeviceData(
    message: IMqttMessage,
    ti: TopicInfo,
    deviceData: DeviceData,
  ): DeviceData {
    switch (ti.MessageType) {
      case TopicLogic.MSG_TYPES.TOPIC_MSGTYPE_STATUS:
        const status = this.mqttMessageToDeviceStatusInfo(
          message.payload.toString(),
        );
        deviceData = {
          ...deviceData,
          id: ti.DeviceId,
          status,
          lastPing: new Date(),
        };
        break;
      case TopicLogic.MSG_TYPES.TOPIC_MSGTYPE_DEVINFO:
        const deviceInfo = this.parseMqttDeviceInfo(message);
        if (deviceInfo) {
          deviceData = {
            ...deviceData,
            id: ti.DeviceId,
            deviceInfo,
            lastPing: new Date(),
            timezoneOffset: getTimezoneOffsetByName(
              deviceInfo.currentTime?.timezone,
            ),
          };
        }
        break;
      default:
        deviceData = { ...deviceData };
    }

    return deviceData;
  }

  /**
   * subscribe to screenshot updates
   *
   */
  subscribeToMqttScreenshots$() {
    const topic = TopicLogic.allProfileScreenshotsTopic(this.profileId);
    return this._mqttService.observe(topic);
    // .subscribe((message: IMqttMessage) => {
    //   const ti = TopicLogic.getTopicInfo(message.topic);
    //   this.setDeviceScreenshot(ti.DeviceId);
    // this.fireDebouncedChangeEvent();
    // });
  }

  parseMqttDeviceInfo(message: IMqttMessage) {
    try {
      const info: IDeviceInfo = JSON.parse(message.payload.toString());
      return info;
    } catch (e) {
      console.error('ERROR parsing device info', e);
      console.error('original message payload:', message.payload);
    }
  }

  doFireChangeEvent() {
    this.filterVisibleDevices();
    this.deviceDataChanged.emit();
  }

  /**
   * reset coordinates of a single channel to its location's
   *
   * @param deviceId
   * @returns
   */
  async resetDevicePosition(deviceId: string) {
    return await this.resetDevicesPosition([deviceId]);
  }
  /**
   * reset coordinates of a channels to relative locations'
   *
   * @param channelIds
   */
  async resetDevicesPosition(channelIds: string[]) {
    this.subs.sink = this.bulkActionDevicesGQL
      .mutate({
        ids: channelIds,
        action: ChannelBulkAction.ResetPosition,
      })
      .subscribe({
        next: ({ data }) => {
          if (!(data && data.bulkActionDevices.isSuccessful)) {
            this.toasterService.error('UNKNOWN_ERROR');
          }

          const ret = data?.bulkActionDevices.isSuccessful;

          this.refreshDevices();
          return ret;
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('resetDevicePosition', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });
          return false;
        },
      });
  }
  /** DANGEROUS this deletes a device from db only, make sure the device is not connected to SOS
   * after deletion devices list is refreshed
   */
  async forceDeprovisionDevice(id: string) {
    const { data } = await lastValueFrom(this.forceDeleteDevice.mutate({ id }));

    if (data?.deleteDeprovisionedDevice.isSuccessful) {
      this.refreshDevices();
      this.toasterService.success('DEVICE_DEACTIVATED');
    } else {
      this.toasterService.error('DEACTIVATE_DEVICE_ERROR');
    }
    return data?.deleteDeprovisionedDevice.isSuccessful || false;
  }

  // MARK: deletes a device from SOS and DB, returns success, fails if device is not present in SOS
  async deprovisionDevice(id: string) {
    try {
      const { data } = await lastValueFrom(this.deleteDevice.mutate({ id }));
      if (data?.deleteDevice.isSuccessful) {
        this.refreshDevices();
        this.channelService.refreshChannels();
        this.toasterService.success('DEVICE_DEACTIVATED');
        return true;
      }
    } catch {
      // nothing
    }
    // something happened
    this.toasterService.error('DEACTIVATE_DEVICE_ERROR');
    return false;
  }

  async bulkUpdateDeviceApplet(
    deviceIds: string[],
    application: 'SMIL' | 'CLASSIC' | 'LATEST',
  ) {
    const { data } = await lastValueFrom(
      this.bulkActionDevicesGQL.mutate({
        ids: deviceIds,
        action: ChannelBulkAction.AppletUpdateVersion,
        extra: application,
      }),
    );

    if (data?.bulkActionDevices.isSuccessful) {
      this.refreshDevices();
    }
  }

  async bulkClearDeviceCache(deviceIds: string[]) {
    const { data } = await lastValueFrom(
      this.bulkActionDevicesGQL.mutate({
        ids: deviceIds,
        action: ChannelBulkAction.ClearDeviceCache,
      }),
    );

    if (data?.bulkActionDevices.isSuccessful) {
      this.refreshDevices();
    }
  }

  lastFilter!: IChannelFilter;

  // MARK: initialize data connection and saves current filter
  initDataLinks(filter: IChannelFilter) {
    // TODO: in this if we should probably consider filter.mqttOnly /Marco
    if (
      this.lastFilter &&
      this.lastFilter.profileId === filter.profileId &&
      this.lastFilter.locationId === filter.locationId &&
      this.lastFilter.deviceId === filter.deviceId
    ) {
      return;
    }

    this.lastFilter = filter;
    if (!filter.mqttOnly) {
      this.getDevices(filter);
    }
    if (filter.profileId) {
      this.subscribeToMqttProfileMessages(filter.profileId);
      this.subscribeToMqttScreenshots(filter.profileId);
    }
  }

  defaultNtpServer = 'pool.ntp.org';
  provisionDevice(input: ProvisionDeviceInput) {
    // console.log('provisionDevice', input); // DEBUG
    return this.provisionDeviceGql.mutate({ input });
  }

  toProvisionDeviceInput(form: IVerifyDeviceForm): ProvisionDeviceInput {
    console.log('toProvisionDeviceInput', form); // DEBUG
    const provisionDeviceInput: ProvisionDeviceInput = {
      name: form.name,
      profileId: this.profileId,
      channelId: form.channelId,
      verificationHash: form.verificationHash,
      orientation: form.orientation,
      volume: form.volume,
      timeZone: form.timeZone,
      ntpServer: form.ntpServer,
      application: form.application,
    };

    if (form.locationTab) {
      // TODO: Revisit after refactoring forms to reactive forms
      if (
        form.locationTab === 'tabExisting' &&
        form.locationSelection?.locationId
      ) {
        provisionDeviceInput.locationId = form.locationSelection.locationId;
      } else {
        if (form.location) {
          provisionDeviceInput.locationInput = inject(
            LocationService,
          ).toCreateLocationInput({
            ...form.location,
            profileId: form.location.profileId ?? form.profileId,
          });
        }
      }
    }

    // console.log('form: ', form); // DEBUG
    // console.log('provisionDeviceInput: ', provisionDeviceInput); // DEBUG
    return provisionDeviceInput;
  }

  // MARK: get device data from graphql
  private getDevices(filter: IChannelFilter) {
    if (filter.profileId && filter.deviceId) {
      // MARK: get single device from graphql
      this.subs.sink = this.getSingleDeviceGQL
        .watch({ id: filter.deviceId }, { fetchPolicy: 'cache-first' })
        .valueChanges.subscribe({
          next: ({ data, loading }) => {
            this.loading.set(loading);

            this.devices = [];
            if (data?.device) {
              const d: DeviceData = data?.device;
              d.status = DeviceStatusInfo.CtrlOffline;
              d.screenshotUrl = getScreenshotUrl(d.id) || '';
              this.devices.push(d);
              this.fireDebouncedChangeEvent();
            }
          },
          error: (error: ApolloError) => {
            error.graphQLErrors.forEach((gqlError) => {
              console.error('getDevices, get Single Device', gqlError);
              this.toasterService.error('DEVICE_UNAVAILABLE');
            });
          },
        });
    } else if (filter.profileId) {
      // MARK: get profile devices from graphql
      this.subs.sink = this.getProfileDevicesGQL
        .watch({ id: filter.profileId }, { fetchPolicy: 'cache-first' })
        .valueChanges.subscribe({
          next: ({ data, loading }) => {
            this.loading.set(loading);

            if (data?.profile) {
              this.devices = JSON.parse(JSON.stringify(data?.profile?.devices));
              this.devices.forEach((d) => {
                d.status = this.mqttMessageToDeviceStatusInfo(
                  d.lastStatus || TopicLogic.MSG_PAYLOADS.STATUS_OFFLINE,
                ); // this.getDeviceStatus(d.id);
                d.lastPing = d.deviceInfo?.currentTime?.currentDate;
                d.timezoneOffset = getTimezoneOffsetByName(
                  d.deviceInfo?.currentTime?.timezone,
                );
                d.screenshotUrl = getScreenshotUrl(d.id) || '';
                if (d.deviceInfo) {
                  this.setComputedValuesInfo(d.deviceInfo);
                }
              });
              this.fireDebouncedChangeEvent();
            }
          },
          error: (error: ApolloError) => {
            console.error('getDevices, get All profile Devices', error);
            this.toasterService.error('DEVICE_UNAVAILABLE');
          },
        });
    }
  }

  async getDeviceMonitorData(deviceId: string) {
    const { data } = await lastValueFrom(
      this.getDeviceMonitorHistoryGQL.fetch(
        { id: deviceId },
        { fetchPolicy: 'network-only' },
      ),
    );

    return {
      monitorHistory: data?.device?.monitorHistory as DeviceMonitorData[],
      statusHistory: data?.device?.statusHistory as DeviceMonitorData[],
    };
  }

  refreshDevices() {
    this.refreshDevicesFromApi();
    // this.getDevices(this.lastFilter);
  }

  /**
   * status filter management
   */
  private get visibilityFilter() {
    return this.uiDataService.deviceFilter;
  }

  private get visibleStatuses() {
    const statuses: DeviceStatusCode[] = [];
    if (this.visibilityFilter.onlineChannels) {
      statuses.push(DeviceStatusCode.online);
    }
    if (this.visibilityFilter.offlineChannels) {
      statuses.push(DeviceStatusCode.offline);
    }
    if (this.visibilityFilter.noDeviceChannels) {
      statuses.push(DeviceStatusCode.ctrloffline);
    }
    return statuses;
  }
  /**
   * 2nd level (status) filtering
   * original channel list is filtered depending on user preferences
   * AFTER all channels for 1st level filter are collected
   */
  visibleDevices: DeviceData[] = [];
  visibleDevicesSignal = signal<DeviceData[]>([]);

  filterVisibleDevices() {
    const search = this.visibilityFilter.filterText?.toLocaleLowerCase() || '';

    let devices = this.devices.filter((x) => this.isDeviceVisible(x, search));

    // sort device, order by name
    if (this.visibilityFilter.sortOrderConfigMap) {
      const orderByFields: string[] = [];
      const orderByDirections: ISortOrderDirection[] = [];

      try {
        const sortEntries = this.visibilityFilter.sortOrderConfigMap.entries();

        for (let [fieldName, direction] of sortEntries) {
          orderByFields.push(fieldName);
          orderByDirections.push(direction);
        }

        devices = orderBy(devices, orderByFields, orderByDirections);
      } catch {}
    }

    this.visibleDevices = devices;
    this.visibleDevicesSignal.set(devices);
    this.fireDebouncedChangeEvent();
  }

  isDeviceVisible(d: DeviceData, searchText: string) {
    const searchMatch = d.name.toLocaleLowerCase().includes(searchText);

    const ret =
      this.visibleStatuses.includes(
        d.status?.Status || DeviceStatusCode.offline,
      ) && searchMatch;
    const desc = `vis: ${ret}, match: ${searchMatch},  name: ${d.name}`;
    return ret;
  }

  /** map of screenshots timestamps */
  deviceScreenshotsTimestamps: Map<string, number> = new Map();

  /**
   * ATTENTION! Call this if the status value changes (from MQTT)
   * expensive and blind function, optimize outside
   *
   * @param deviceId ATTENTION
   * @param status
   */
  setDeviceStatus(deviceId: string, status: DeviceStatusInfo) {
    // let's keep a fast cache of the status easily accessible
    // this.deviceStatuses.set(deviceId, status);

    const s = deviceStatusState.get(deviceId);
    if (s) {
      s.set(status);
    } else {
      deviceStatusState.set(deviceId, signal(status));
    }

    const d = this.devices.find((x) => x.id === deviceId) as DeviceData;
    if (d) {
      d.status = status;
      if (status.Status !== DeviceStatusInfo.Offline.Status) {
        d.lastPing = new Date();
      }
    }
  }

  /**
   * DEPRECATED use getDeviceStatusSignal instead
   * get device status from MQTT signal based cache
   *
   * @param deviceId
   * @returns
   */
  getDeviceStatus(deviceId: string | undefined): DeviceStatusInfo {
    if (deviceId) {
      const sgn = this.getDeviceStatusSignal(deviceId);
      return sgn();
    }
    // default
    return DeviceStatusInfo.Offline;
  }
  getDeviceStatusSignal(deviceId: string) {
    let sgn = deviceStatusState.get(deviceId);
    if (!sgn) {
      sgn = signal(DeviceStatusInfo.Offline);
      deviceStatusState.set(deviceId, sgn);
    }
    return sgn;
  }

  getLastPing(deviceId: string) {
    const device = this.devices.find((d) => d.id === deviceId);
    return device?.lastPing;
  }

  getDeviceScreenshot(deviceId: string) {
    if (!this.deviceScreenshotsTimestamps.get(deviceId)) {
      this.setDeviceScreenshot(deviceId);
    }
    let timestamp = this.deviceScreenshotsTimestamps.get(deviceId);

    if (!timestamp) {
      // initialize the timestamp to avoid creating always new timestamps for the same device on consequent calls
      this.setDeviceScreenshot(deviceId);
      timestamp = this.deviceScreenshotsTimestamps.get(deviceId);
    }

    return getScreenshotUrl(deviceId, timestamp);
  }

  setDeviceScreenshot(deviceId: string) {
    const timestamp = Math.floor(Date.now());
    this.deviceScreenshotsTimestamps.set(deviceId, timestamp);

    const idx = this.devices.findIndex((x) => x.id === deviceId);
    if (idx >= 0) {
      const url = getScreenshotUrl(deviceId, timestamp);
      this.devices[idx].screenshotUrl = url;
      this.devices[idx].lastPing = new Date();
    }
  }

  isAndroid(deviceId: string | undefined) {
    if (deviceId) {
      const deviceInfo = this.getDeviceInfo(deviceId);
      return deviceInfo?.applicationType === 'android';
    }
  }

  /**
   * checks if device screen is powered on from MQTT cache
   *
   * @param deviceId
   * @returns
   */
  isDeviceScreenPoweredOn(deviceId: string | undefined) {
    if (deviceId) {
      const deviceInfo = this.getDeviceInfo(deviceId);
      return deviceInfo?.screen?.isPoweredOn || false;
    }
    return false;
  }

  /**
   * Send MQTT message/command to device
   *
   * @param msg
   * @returns
   */
  mqttMessageToDeviceStatusInfo(msg: string) {
    switch (msg) {
      case TopicLogic.MSG_PAYLOADS.STATUS_ONLINE:
        return DeviceStatusInfo.Online;
      default:
        return DeviceStatusInfo.Offline;
    }
  }

  turnScreenOn(deviceId: string) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    this.sendMsg(topic, TopicLogic.MSG_PAYLOADS.CMD_TOGGLE_SCREEN_POWER, 0);
    if (this.isAndroid(deviceId)) {
      const device = this.getDevice(deviceId);
      if (device?.uid) {
        return this.setDevicePowerAction(
          device?.uid,
          DevicePowerAction.DisplayPowerOn,
        );
      }
    }
  }
  turnScreenOff(deviceId: string) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    this.sendMsg(topic, TopicLogic.MSG_PAYLOADS.CMD_TOGGLE_SCREEN_POWER, 0);
  }

  /**
   * reboot device
   *
   * @param deviceId
   */
  rebootDevice(deviceId: string) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    this.sendMsg(topic, TopicLogic.MSG_PAYLOADS.CMD_REBOOT, 0);
    if (this.isAndroid(deviceId)) {
      const device = this.getDevice(deviceId);
      if (device?.uid) {
        return this.setDevicePowerAction(
          device.uid,
          DevicePowerAction.SystemReboot,
        );
      }
    }
  }

  /** DEPRECATED */
  changeTimeZone(deviceId: string, newTimeZone: string, newNtpServer: string) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    const cmdParam = {
      command: TopicLogic.MSG_PAYLOADS.CMD_SET_TIMEZONE,
      timeZone: newTimeZone,
      ntpServer: newNtpServer,
    };
    const msg = JSON.stringify(cmdParam);
    this.sendMsg(topic, msg, 2);
  }
  /** DEPRECATED set brightness over mqtt */
  setBrightness(
    deviceId: string,
    brightness1: number,
    brightness2: number,
    time1: string,
    time2: string,
  ) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    const cmdParam = {
      command: TopicLogic.MSG_PAYLOADS.CMD_SET_BRIGHTNESS,
      brightness1,
      brightness2,
      time1,
      time2,
    };
    const msg = JSON.stringify(cmdParam);
    this.sendMsg(topic, msg, 2);
  }
  /** DEPRECATED setVolume over MQTT */
  setVolume(deviceId: string, volume: number) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    const cmdParam = {
      command: TopicLogic.MSG_PAYLOADS.CMD_SET_VOLUME,
      volume,
    };
    const msg = JSON.stringify(cmdParam);
    this.sendMsg(topic, msg, 2);
  }

  /**
   * reload device applet
   *
   * @param deviceId
   */
  reloadDeviceApp(deviceId: string) {
    console.log('reloadDeviceApp', deviceId);

    const topic = TopicLogic.deviceCommandTopic(deviceId);
    this.sendMsg(topic, TopicLogic.MSG_PAYLOADS.CMD_APP_RELOAD, 2);
  }

  refreshDeviceInfo(deviceId: string) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    this.sendMsg(topic, TopicLogic.MSG_PAYLOADS.CMD_REFRESH_INFO, 2);
  }

  /** activate mqtt live log for a minute */
  activateLiveLog(deviceId: string) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    this.sendMsg(topic, TopicLogic.MSG_PAYLOADS.CMD_GET_LIVE_LOG, 2);
  }

  /**
   * request a device to get a screenshot
   */
  askForScreenshot(deviceId: string) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    this.sendMsg(topic, TopicLogic.MSG_PAYLOADS.CMD_SAVE_SCREENSHOT);
  }

  getDevice(deviceId: string) {
    const index = this.devices.findIndex((x) => x.id === deviceId);
    return index >= 0 ? this.devices[index] : undefined;
  }

  async getDeviceNoCache(id: string) {
    const { data } = await lastValueFrom(
      this.getSingleDeviceGQL.fetch({ id }, { fetchPolicy: 'no-cache' }),
    );
    const { device } = data;
    if (device) {
      const deviceData: DeviceData = {
        ...device,
        status: this.getDeviceStatus(id),
        screenshotUrl: this.getDeviceScreenshot(id),
      };
      return deviceData;
    } else {
      return undefined;
    }
  }

  // MARK: MQTT Communications

  /**
   * low level MQTT send
   *
   * @param topic
   * @param msg
   */
  sendMqttMessage(topic: string, msg: string) {
    this.sendMsg(topic, msg);
  }

  /**
   * subscribe to all device MQTT messages, belonging to selected profile id
   *
   * @param profileId
   */
  private subscribeToMqttProfileMessages(profileId: string): void {
    const topic = TopicLogic.allProfileDevicesTopic(profileId);
    this._mqttService
      .observe(topic)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((message: IMqttMessage) => {
        // console.log('message from: ', message.topic);
        // console.log('content: ', message.payload.toString());

        const ti = TopicLogic.getTopicInfo(message.topic);
        switch (ti.MessageType) {
          case TopicLogic.MSG_TYPES.TOPIC_MSGTYPE_STATUS:
            const status = this.mqttMessageToDeviceStatusInfo(
              message.payload.toString(),
            );
            const online = status === DeviceStatusInfo.Online;
            // console.log(
            //   `MQTT status ${ti.DeviceId} is now ${status.StatusLabel}`,
            //   status
            // );
            if (this.getDeviceStatus(ti.DeviceId) !== status) {
              this.setDeviceStatus(ti.DeviceId, status);
              this.deviceStatusChanged.emit({
                deviceId: ti.DeviceId,
                ts: Date.now(),
                online,
              });
              this.fireDebouncedChangeEvent();
            }
            break;
          case TopicLogic.MSG_TYPES.TOPIC_MSGTYPE_DEVINFO:
            // TODO update data!
            const deviceInfo = this.parseDeviceInfo(message);
            if (deviceInfo) {
              // console.log(`MQTT deviceInfo ${ti.DeviceId}:`, deviceInfo);
              this.deviceInfoChanged.emit(deviceInfo);
              this.updateDeviceInfo(ti.DeviceId, deviceInfo);
              this.fireDebouncedChangeEvent();
            }
            break;
          default:
            break;
        }
      });
    // console.log('subscribed to topic: ' + topic);
  }

  getMqttProfileMessages$(profileId: string) {
    const topic = TopicLogic.allProfileDevicesTopic(profileId);
    return this._mqttService
      .observe(topic)
      .pipe(takeUntilDestroyed(this.destroyRef));
  }
  getMqttDeviceMessages$(
    deviceId: string,
    profileId: string,
  ): Observable<DeviceInfo> {
    const topic = TopicLogic.deviceInfoTopic(deviceId, profileId);
    return this._mqttService.observe(topic).pipe(
      map((message) => JSON.parse(message.payload.toString()) as DeviceInfo),
      takeUntilDestroyed(this.destroyRef),
      tap((data) => console.log('mqtt deviceInfo', data)),
    );
  }
  getMqttDeviceLog$(
    deviceId: string,
    profileId?: string,
  ): Observable<IDeviceLog> | undefined {
    const useProfileId = profileId || this.sessionService.profileId();
    if (useProfileId) {
      const topic = TopicLogic.deviceLogTopic(useProfileId, deviceId);
      return this._mqttService.observe(topic).pipe(
        map((message) => JSON.parse(message.payload.toString()) as IDeviceLog),
        takeUntilDestroyed(this.destroyRef),
      );
    }
  }

  getDeviceInfoHistory$(deviceId: string): Observable<DeviceMonitorData[]> {
    const infoHistory = this.getDeviceMonitorHistoryGQL
      .fetch({ id: deviceId }, { fetchPolicy: 'network-only' })
      .pipe(
        // tap((data) => console.log('info data', data)),
        map(({ data }) => data.device?.monitorHistory as DeviceMonitorData[]),
      );
    return infoHistory;
  }

  getDeviceStatusHistory$(deviceId: string): Observable<DeviceStatusData[]> {
    const infoHistory = this.getDeviceMonitorHistoryGQL
      .fetch({ id: deviceId }, { fetchPolicy: 'network-only' })
      .pipe(
        map(({ data }) => data.device?.statusHistory as DeviceStatusData[]),
        // tap((data) => console.log('status data', data))
      )
      .pipe(
        map((data) => {
          const latestData: DeviceStatusData[] = [...data];
          const currentStatus = this.getDeviceStatus(deviceId);
          if (currentStatus) {
            latestData.push({
              ts: moment().toISOString(),
              online: currentStatus === DeviceStatusInfo.Online ? true : false,
              __typename: 'DeviceStatusRecord',
            });
          }
          return latestData;
        }),
        // ,tap((data) => console.log('status data', data))
      );
    return infoHistory;
  }

  // Returns the status for a device, including Historical along with MQTT pushed messages
  getDeviceMqttStatus$(deviceId: string): Observable<DeviceStatusData[]> {
    // Get historical data once
    const history$ = this.getDeviceStatusHistory$(deviceId);

    // Stream of new MQTT messages for this device
    const mqtt$ = this.getMqttProfileMessages$(this.profileId).pipe(
      filter(
        (message: IMqttMessage) =>
          message.topic ===
          `designage/appletSnd/${this.profileId}/${deviceId}/status`,
      ),
      map((message) => ({
        ts: moment().toISOString(),
        online: message.payload.toString() === 'ON',
        __typename: 'DeviceStatusRecord' as const,
      })),
    );

    // Merge historical data with real-time updates
    return merge(
      // Emit historical data immediately
      history$,
      // Then merge in any new MQTT messages
      history$.pipe(
        switchMap((history) =>
          mqtt$.pipe(
            // Accumulate new messages with historical data
            scan((acc, curr) => [...acc, curr], history),
          ),
        ),
      ),
    );
  }

  getDeviceMqttInfo(deviceId: string) {
    // Get historical data once
    const history$ = this.getDeviceInfoHistory$(deviceId);

    // Stream of new MQTT messages for this device
    const mqtt$ = this.getMqttProfileMessages$(this.profileId).pipe(
      filter(
        (message: IMqttMessage) =>
          message.topic ===
          `designage/appletSnd/${this.profileId}/${deviceId}/info`,
      ),
      map((message) => {
        const info = JSON.parse(message.payload.toString());
        const monitorData: DeviceMonitorData = {
          ts: moment().toISOString(),
          cpu: info.cpu > 0 ? info.cpu : null,
          temp: info.temperature > 0 ? info.temperature : null,
          __typename: 'DeviceStatusRecord',
        };
        return monitorData;
      }),
    );

    return merge(
      // Emit historical data immediately
      history$,
      // Then merge in any new MQTT messages
      history$.pipe(
        switchMap((history) =>
          mqtt$.pipe(
            // Accumulate new messages with historical data
            scan((acc, curr) => [...acc, curr], history),
          ),
        ),
      ),
    );
  }

  getCurrentBrightness(info: DeviceInfo): {
    brightness: Maybe<number>;
    schedule: Maybe<
      'NO_SCHEDULE' | 'DEVICE_BRIGHTNESS_DAY' | 'DEVICE_BRIGHTNESS_NIGHT'
    >;
  } {
    const timezoneOffset = getTimezoneOffsetByName(info.currentTime?.timezone);
    if (
      info.screen?.brightness &&
      info.currentTime?.currentDate &&
      timezoneOffset
    ) {
      const currentDeviceTimeString = getDeviceTime(
        info.currentTime?.currentDate,
        timezoneOffset,
      );
      if (info.screen.brightness.timeFrom1 === info.screen.brightness.timeFrom2)
        return {
          brightness: info.screen.brightness.brightness1,
          schedule: 'NO_SCHEDULE',
        };
      if (
        currentDeviceTimeString >= info.screen.brightness.timeFrom1! &&
        currentDeviceTimeString <= info.screen.brightness.timeFrom2!
      )
        return {
          brightness: info.screen.brightness.brightness1,
          schedule: 'DEVICE_BRIGHTNESS_DAY',
        };
      if (
        currentDeviceTimeString < info.screen.brightness.timeFrom1! ||
        currentDeviceTimeString > info.screen.brightness.timeFrom2!
      )
        return {
          brightness: info.screen.brightness.brightness2,
          schedule: 'DEVICE_BRIGHTNESS_NIGHT',
        };
    }
    return { brightness: null, schedule: null };
  }

  setComputedValuesInfo(info: IDeviceInfo) {
    const computed = this.getCurrentBrightness(info);
    if (!!info.screen) {
      info.screen.currentBrightness = computed.brightness;
      info.screen.scheduleInUse = computed.schedule;
    }
  }
  parseDeviceInfo(message: IMqttMessage) {
    try {
      const info: IDeviceInfo = JSON.parse(message.payload.toString());

      if (!info.screen?.currentBrightness) {
        this.setComputedValuesInfo(info);
      }

      return info;
    } catch (e) {
      console.error('ERROR parsing device info', e);
      console.error('original message payload:', message.payload);
    }
  }
  /**
   * updates data locally as they arrive, without reloading
   * same update is done on API and saved to db
   *
   * @param deviceId
   * @param deviceInfo
   */
  private updateDeviceInfo(deviceId: string, deviceInfo: IDeviceInfo) {
    const sgn = this.getDeviceInfoSignal(deviceId);
    sgn?.set(deviceInfo);

    const d = this.devices.find((x) => x.id === deviceId);
    if (d) {
      d.deviceInfo = deviceInfo;
      d.timezoneOffset = getTimezoneOffsetByName(
        deviceInfo.currentTime?.timezone,
      );
      d.lastPing = new Date();
    }
  }
  getDeviceInfo(deviceId: string) {
    let sgn = this.getDeviceInfoSignal(deviceId);

    return sgn ? sgn() : undefined;
  }
  /** get last value from mqtt or from previously fetched data */
  getDeviceInfoSignal(deviceId: string) {
    let sgn = deviceInfoState.get(deviceId);
    if (!sgn) {
      sgn = signal(this.devices.find((x) => x.id === deviceId)?.deviceInfo);
      deviceInfoState.set(deviceId, sgn);
    }
    return sgn;
  }

  /**
   * MARK: subscribe to screenshot updates
   *
   * @param profileId
   */
  private subscribeToMqttScreenshots(profileId: string): void {
    const topic = TopicLogic.allProfileScreenshotsTopic(profileId);
    this._mqttService
      .observe(topic)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((message: IMqttMessage) => {
        const ti = TopicLogic.getTopicInfo(message.topic);
        this.setDeviceScreenshot(ti.DeviceId);
        // this.fireDebouncedChangeEvent();
      });
  }

  private sendMsg(
    topicName: string,
    msg: string,
    qos: QoS = 1,
    retain: boolean = false,
  ): void {
    // use unsafe publish for non-ssl websockets
    this._mqttService.unsafePublish(topicName, msg, { qos, retain });
  }

  // #endregion

  /**
   * Calls API to perform signageos power action
   *
   * @param deviceUid
   * @param devicePowerAction
   */
  setDevicePowerAction(
    deviceUid: string,
    devicePowerAction: DevicePowerAction,
  ) {
    this.subs.sink = this.setDevicePowerActionGQL
      .mutate(
        { uid: deviceUid, devicePowerAction },
        { fetchPolicy: 'no-cache' },
      )
      .subscribe({
        next: ({ data }) => {
          if (data?.powerAction?.isSuccessful) {
            return true;
            // const successMessage = this.getPowerActionMessage(devicePowerAction);
            // this.toasterService.success(successMessage);
          } else {
            this.toasterService.error('ALERT');
            return false;
          }
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('setDevicePowerAction', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });
          return false;
        },
      });
  }

  /**
   * gets the success message of the action
   */
  getPowerActionMessage(devicePowerAction: DevicePowerAction) {
    switch (devicePowerAction) {
      case DevicePowerAction.AppletReload:
        return 'DEVICE_APP_RELOAD_SUCCESS';
      case DevicePowerAction.SystemReboot:
        return 'DEVICE_REBOOT_SUCCESS';
      case DevicePowerAction.DisplayPowerOn:
        return 'DEVICE_DISPLAY_ON_SUCCESS';
      case DevicePowerAction.DisplayPowerOff:
        return 'DEVICE_DISPLAY_OFF_SUCCESS';
      default:
        return '';
    }
  }

  getConnectedChannelPlaylists(deviceId: string) {
    return this.channelService.getChannelPlaylists(deviceId);
  }

  getConnectedChannel(channelId: string) {
    return this.channelService.getChannel(channelId);
  }
}

@if (userForm) {
  <form
    [formGroup]="userForm"
    (submit)="onSubmit(userForm.value)"
    class="row px-3"
  >
    <div class="col-8 d-flex flex-column">
      @if (!isEditingName) {
        <h1 class="editable-field mt-0">
          {{ userForm.value.name || ('NO_DATA' | translate)
          }}<i
            (click)="editNameInput()"
            class="px-2 edit-icon ri-pencil-fill text-primary"
          ></i>
        </h1>
      } @else {
        <div class="input-group mb-2 mt-1 ps-1">
          <input
            #userNameInput
            type="text"
            name="name"
            class="form-control form-control-dark"
            [placeholder]="'NAME' | translate"
            [attr.aria-label]="'NAME' | translate"
            aria-describedby="basic-addon2"
            formControlName="name"
            (blur)="isEditingName = false"
          />
        </div>
      }
      @if (
        userForm.controls['name'].pristine &&
        userForm.controls['name'].hasError('required')
      ) {
        <div class="error">
          {{ 'NAME_IS_REQUIRED' | translate }}
        </div>
      }
      <!-- MARK: Date Created and Date Updated -->
      <div class="py-2 row mb-4">
        <div class="col-6">
          <label class="form-label" for="dateCreated"
            >{{ 'DATE_CREATED' | translate }}:</label
          >
          <span class="px-1">{{
            user?.auth0Profile?.created_at | date: 'short'
          }}</span>
        </div>
        <div class="col-6">
          <label class="form-label" for="dateUpdated"
            >{{ 'DATE_UPDATED' | translate }}:</label
          >
          <span class="px-1">{{ user?.updatedAt | date: 'short' }}</span>
        </div>
      </div>

      <!-- MARK: EMail and Role -->
      <div class="py-2 row" style="height: 5rem">
        <div class="col-6">
          <label class="form-label" for="email"
            >{{ 'EMAIL_ADDRESS' | translate }}:
          </label>
          <span class="">
            {{ user?.auth0Profile?.email || 'NO_DATA' | translate }}
          </span>
        </div>
        <div class="col-6">
          <!-- Role switcher -->
          <label class="form-label me-1" for="roles"
            >{{ 'ROLE' | translate }}:
          </label>
          @if (isEditingRole) {
            <div
              class="d-flex"
              style="margin-left: -0.9rem; margin-top: -0.6rem"
            >
              <select
                style="max-width: 10rem"
                formControlName="roleId"
                class="form-select form-select-sm form-select-dark role-select"
                (change)="isEditingRole = false"
              >
                <option [ngValue]="null" selected disabled>
                  {{ 'SELECT_A_ROLE' | translate }}
                </option>
                @for (role of roles; track role.id) {
                  <option [value]="role.id">
                    {{ role.name ?? '-' | translate }}
                  </option>
                }
              </select>
              <div
                class="my-auto ms-2 btn-close btn-close-white cursor-pointer"
                (click)="isEditingRole = false"
              ></div>
            </div>
          } @else {
            <div style="margin-top: -0.35rem" class="editable-field">
              {{ userForm.value.roleId }}
              @if (canManageUsers) {
                <button
                  (click)="isEditingRole = true"
                  class="btn btn-sm btn-outline-primary"
                >
                  {{ 'EDIT' | translate }}
                </button>
              }
            </div>
          }
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mt-3 d-flex justify-content-end">
        <button
          [disabled]="!userForm.dirty"
          type="revert"
          class="btn btn-outline-warning"
          (click)="revertChanges()"
        >
          {{ 'CANCEL' | translate }}
        </button>
        <button
          [disabled]="userForm.invalid || userForm.pristine"
          type="submit"
          class="ms-3 btn btn-outline-success"
        >
          {{ 'SAVE' | translate }}
        </button>
      </div>
    </div>
  </form>
}

import { CommonModule } from '@angular/common';
import {
  Component,
  Output,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { environment } from '@desquare/environments';
import { IFullScreen } from '@desquare/interfaces';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, NgbTooltip],
  selector: 'app-preview-controls',
  templateUrl: './preview-controls.component.html',
  styleUrls: ['./preview-controls.component.scss'],
})
export class PreviewControlsComponent {
  @Output() mute = new EventEmitter<boolean>();
  @Output() playback = new EventEmitter<boolean>();
  @Output() fullScreen = new EventEmitter<boolean>();

  @Input() isPlaying!: boolean;

  isMuted!: boolean;
  isFullscreen!: boolean;
  detachIcon!: string;
  detachedWindowIcon!: string;
  muteIcon!: string;
  pauseIcon!: string;
  playIcon!: string;
  unmuteIcon!: string;
  fullScreenIcon!: string;
  exitScreenIcon!: string;

  @HostListener('document:fullscreenchange', [])
  @HostListener('document:mozfullscreenchange', [])
  @HostListener('document:webkitfullscreenchange', [])
  fullScreenChange() {
    const fullScreenDocument: IFullScreen = document;
    if (
      !fullScreenDocument.webkitIsFullScreen &&
      !fullScreenDocument.mozFullScreen &&
      !fullScreenDocument.msFullscreenElement
    ) {
      this.isFullscreen = false;
      this.fullScreen.emit(this.isFullscreen);
    }
  }

  constructor() {
    this.initVariables();
  }

  initVariables() {
    this.isPlaying = true;

    this.isMuted = false;
    this.isFullscreen = false;
    this.detachIcon = environment.assets.detachIcon;
    this.detachedWindowIcon = environment.assets.detachedWindowIcon;
    this.muteIcon = environment.assets.muteIcon;
    this.pauseIcon = environment.assets.pauseIcon;
    this.playIcon = environment.assets.playIcon;
    this.unmuteIcon = environment.assets.unmuteIcon;
    this.fullScreenIcon = environment.assets.fullScreenIcon;
    this.exitScreenIcon = environment.assets.exitScreenIcon;
  }

  onMute() {
    this.isMuted = !this.isMuted;
    this.mute.emit(this.isMuted);
  }

  onFullScreen() {
    this.isFullscreen = !this.isFullscreen;
    this.fullScreen.emit(this.isFullscreen);
  }

  onPlay() {
    this.isPlaying = !this.isPlaying;
    this.playback.emit(this.isPlaying);
  }
}

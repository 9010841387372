import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
  inject,
  computed,
  effect,
  signal,
} from '@angular/core';
import {
  AspectResizeCropService,
  CurrentUserService,
  EncryptionService,
  SlidePanelService,
} from '@desquare/services';
import { Maybe, PlaylistStatus } from '@designage/gql';
import { SubSink } from 'subsink';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { localStorageKeys } from '@desquare/constants';
import { cloneDeep } from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbService } from 'xng-breadcrumb';
import { PlaylistFormNewComponent } from '@designage/app/playlist-editor/playlist-form-new/playlist-form-new.component';
import { PlaylistStore } from '@desquare/stores';

enum Tabs {
  SETTINGS,
  DETAILS,
}

@Component({
  standalone: true,
  imports: [TranslateModule, PlaylistFormNewComponent],
  selector: 'designage-playlist-manage',
  template: `
    @if (loading()) {
      <div class="progress progress-bar-custom">
        <div
          class="progress-bar progress-bar-striped progress-bar-animated progress-bar-custom"
          role="progressbar"
          aria-valuenow="100"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ loaderMessage() | translate }}
        </div>
      </div>
    }
    <div
      [class.disabled]="loading()"
      class="pt-0 overflow-auto container-fluid content-container-card manage-channel-container"
    >
      @if (editingReady()) {
        <designage-playlist-form-new
          [invokedFrom]="invokedFrom"
        ></designage-playlist-form-new>
      }
    </div>
  `,
  styles: [
    `
      .disabled {
        pointer-events: none;
      }

      .progress-bar-custom {
        width: 100%;
        height: 1.5rem;
      }
    `,
  ],
})
export class PlaylistManageNewComponent implements OnInit, OnDestroy {
  playlistStore = inject(PlaylistStore);

  private subs = new SubSink();
  playlist = computed(() => this.playlistStore.playlist());
  loading = this.playlistStore.loading;
  loaderMessage = this.playlistStore.loaderMessage;

  currentTab!: Tabs;
  tabs = Tabs;
  playlistStatus = PlaylistStatus;

  _inlinePlaylistId: string = '';
  @Input() set inlinePlaylistId(value: string) {
    // this._inlinePlaylistId = value;
    if (value) {
      this.initPlaylistDecrypted(value);
    }
  }

  @Input() invokedFrom?: string;
  @Output() playlistPublished = new EventEmitter<void>();

  constructor(
    private route: ActivatedRoute,
    private encryptionService: EncryptionService,
    private currentUserService: CurrentUserService,

    private arcService: AspectResizeCropService,
    private breadcrumbService: BreadcrumbService,
    private slidePanelService: SlidePanelService,
  ) {
    effect(() => {
      console.log('playlist changed', this.playlist());
    });
  }

  get showSettingsNav() {
    return this.currentUserService.canManagePlaylist;
  }

  ngOnInit() {
    this.initVariables();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initVariables() {
    this.currentTab = this.currentUserService.canManagePlaylist
      ? Tabs.SETTINGS
      : Tabs.DETAILS;

    if (!this.inlinePlaylistId) {
      this.subs.sink = this.route.params.subscribe((params) => {
        const encryptedPlaylistId = params.playlistId;
        if (encryptedPlaylistId) {
          this.initPlaylist(encryptedPlaylistId);
        }
      });
    }
  }

  async initPlaylist(encryptedId: string) {
    const decryptedId = this.encryptionService.decrypt(encryptedId);
    await this.initPlaylistDecrypted(decryptedId);
    this.slidePanelService.setPanelComponentId(decryptedId);
  }

  editingReady = computed(
    () => this.editingPlaylistId() === this.playlistStore.playlist.id(),
  );

  editingPlaylistId = signal<string>('');
  async initPlaylistDecrypted(decryptedId: string) {
    this.editingPlaylistId.set(decryptedId);
    this.playlistStore.getPlaylistFromApi(decryptedId);
  }

  canExit() {
    return true;
  }
}

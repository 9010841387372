import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  inject,
  HostListener,
  signal,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import {
  Channel,
  ChannelsForChannelListFragment,
  ChannelBulkAction,
  DeleteChannelGQL,
  PlaylistStatus,
  GetEventsListGQL,
  EventForListFragment,
  CreateChannelInput,
  ChannelType,
} from '@designage/gql';

import { SubSink } from 'subsink';
import {
  IDatatableRowActivateArgs,
  ICreateChannelForm,
  ChannelRow,
  IDesignageDataTableColumns,
} from '@desquare/interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ToasterService,
  EncryptionService,
  CurrentUserService,
  DeviceDataService,
  FilterService,
  ChannelService,
  SlidePanelService,
} from '@desquare/services';
import { ApolloError } from '@apollo/client/errors';
import { FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DatatableRowActivationType } from '@desquare/enums';
import { styleUtil } from '@desquare/utils';
import { ScreenAppStatus, ScreenStatus } from '@desquare/enums';
import { DeleteChannelDialogComponent } from '@desquare/components/common/src/modals/delete-channel-dialog.component';
import { DeviceStatusInfo } from '@desquare/models';
import { lastValueFrom } from 'rxjs';
import { cloneDeep } from 'lodash';
import { DeviceStatusSummary } from '@desquare/components/common/src/DeviceStatusSummary';
import { CreateEventDialogComponent } from '../create-event-dialog/create-event-dialog.component';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';
import { TranslateModule } from '@ngx-translate/core';
import { DesignageDataTableComponent } from '@desquare/components/common/src/designage-data-table/designage-data-table.component';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    LoaderComponent,
    DesignageDataTableComponent,
    RouterOutlet,
  ],
  selector: 'app-event-list',
  template: ` <div class="d-flex flex-column h-100">
      <div class="d-flex justify-content-between">
        <div class="flex-grow-1">
          @if (!hideHeader) {
            <h4>
              {{ 'EVENTS' | translate }}
            </h4>
          }
        </div>

        <div class="text-end">
          @if (showAddEventButton) {
            <button
              class="btn btn-md btn-outline-primary"
              (click)="openCreateEventDialog()"
            >
              {{ 'EVENT_ADD_EVENT' | translate }}
            </button>
          }
        </div>
      </div>

      <designage-data-table
        [configId]="tableConfigId"
        [data]="events()"
        [columns]="desColumns"
        [loading]="datatableLoading()"
        [(selectedRows)]="selectedChannels"
        [alwaysSort]="true"
        (rowClick)="onRowClick($event)"
        [showMultiSelect]="false"
        [rowActiveInSlidingPanel]="selectedEventId()"
      />
      <!-- <div class="d-flex flex-column h-100">
    </div> -->
      <div [hidden]="datatableLoading() || !hasNoData" class="col-12">
        <hr />
        @if (hasNoData) {
          <h4 class="unavailable-message">
            {{ 'NO_CHANNELS_FOUND' | translate }}
          </h4>
        }
        @if (datatableLoading()) {
          <h4 class="unavailable-message">
            {{ 'LOADING' | translate }}
          </h4>
        }
      </div>
    </div>
    <app-loader [message]="loaderMessage" />

    <!-- Channel Manage Slide Panel -->
    @if (enableSlidePanel) {
      <router-outlet />
    }`,
  styleUrls: ['./event-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventListComponent implements OnInit, OnDestroy {
  @HostListener('window:focus', ['$event'])
  onFocus() {
    this.channelService.refreshChannels();
    console.log('events focus');
  }
  private subs = new SubSink();

  @Input() deviceId?: string;

  @Input() hiddenButtons = false;
  @Input() redirectOnSelect = true;

  @Input() hideHeader = false;
  @Input() showBulkAction = false;
  @Input() enableSlidePanel = true;
  @Input() enableCheckbox = true;

  @Output() channelSelect = new EventEmitter<ChannelsForChannelListFragment>();
  @Output() channelOldSelect = new EventEmitter<IDatatableRowActivateArgs>();
  @Output() channelDataTableRowClicked =
    new EventEmitter<ChannelsForChannelListFragment>();

  slidePanelService = inject(SlidePanelService);

  loaderMessage!: string;
  events = signal<EventForListFragment[]>([]);
  selectedChannels: ChannelRow[] = [];
  desColumns: IDesignageDataTableColumns[] = [];
  selectionSettings!: Object;
  datatableLoading = signal(false);

  total = 0;

  channelActionsForm!: FormGroup;
  profileId!: string;
  bulkActions = Object.values(ChannelBulkAction);
  channelActionInProgress = false;

  // slide panel variables

  tableConfigId = 'event-list';

  constructor(
    private modalService: NgbModal,
    private toasterService: ToasterService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private currentUserService: CurrentUserService,
    private encryptionService: EncryptionService,
    private deleteChannelGQL: DeleteChannelGQL,
    private deviceDataService: DeviceDataService,
    private filterService: FilterService,
    private getEventsList: GetEventsListGQL,
    private channelService: ChannelService,
  ) {}

  isSlidePanelOpen = this.slidePanelService.getIsPanelOpen();
  selectedEventId = this.slidePanelService.getPanelComponentId();

  get showAddEventButton() {
    // return true;
    return this.currentUserService.canManageEvents && !this.hiddenButtons;
  }

  get hasNoData(): boolean {
    return !this.datatableLoading() && this.total === 0;
  }

  get bulkActionButtonStyle() {
    return styleUtil.getBulkActionButtonStyle(
      this.channelActionsForm.controls.action.value,
    );
  }

  get bulkActionButtonIsEnabled() {
    return (
      (this.selectedChannels.length &&
        this.channelActionsForm.controls.action.value) ||
      false
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.initVariables();
    this.getProfileEvents();
    this.initForm();
  }

  initVariables() {
    this.profileId = this.currentUserService.getCurrentProfileId();

    if (this.deviceId) {
      this.tableConfigId = 'device-events-list';
    }
    this.desColumns = [
      {
        fieldName: 'name',
        name: 'NAME',
        type: 'string',
        visible: 'mandatory',
      },
      {
        fieldName: 'eventStart',
        name: 'EVENT_START',
        type: 'date',
        visible: true,
      },
      {
        fieldName: 'eventEnd',
        name: 'EVENT_END',
        type: 'date',
        visible: true,
      },
      {
        fieldName: 'layout.name',
        name: 'LAYOUT',
        type: 'string',
        visible: true,
      },
      {
        fieldName: 'devices',
        name: 'DEVICES',
        type: 'generic-array',
        visible: this.deviceId ? false : 'mandatory',
        disableSorting: true,
        arrayHeader: 'DEVICES_CONNECTED_TO_CHANNEL',
        columns: [
          {
            fieldName: 'id',
            name: '',
            type: 'device-status-indicator',
            visible: 'mandatory',
          },
          {
            fieldName: 'name',
            name: 'DEVICE_NAME',
            type: 'string',
            visible: 'mandatory',
          },
          {
            fieldName: 'location.name',
            name: 'LOCATION',
            type: 'object',
            visible: 'mandatory',
          },
          {
            fieldName: 'appVersionStatus',
            name: 'Screen App',
            type: 'string',
            visible: 'mandatory',
          },
        ],
      },
      {
        fieldName: 'playlists',
        name: 'PLAYLISTS',
        type: 'generic-array',
        visible: true,
        disableSorting: true,
        arrayHeader: 'PLAYLISTS_CONNECTED_TO_THIS_CHANNEL',
        columns: [
          {
            fieldName: 'name',
            name: 'PLAYLIST_NAME',
            type: 'string',
            visible: 'mandatory',
          },
        ],
      },
    ];

    // Exclude ResetPosition and AddScreen from the list
    this.bulkActions = this.bulkActions.filter(
      (action) =>
        action !== ChannelBulkAction.ResetPosition &&
        action !== ChannelBulkAction.AddScreen,
    );
  }

  initForm() {
    this.channelActionsForm = this.formBuilder.group({
      action: [null],
    });
  }

  getEvents() {
    this.events.set([]);
    this.getProfileEvents();
  }

  updateChannels(deviceId?: string) {
    let updated = false;
    for (const channel of this.events()) {
      if (
        !deviceId ||
        (deviceId && channel.devices.map((x) => x.id).includes(deviceId))
      ) {
        // TODO: calculate event status based on dates and rules
      }
    }
  }

  getChannelStatus(channel: EventForListFragment) {
    if (!channel.devices || channel.devices.length === 0) {
      return undefined;
    }
    const devIds = channel.devices.map((x) => x.id);
    const devStatuses = devIds.map((x) =>
      this.deviceDataService.getDeviceStatus(x),
    );
    const statusSummary = new DeviceStatusSummary(devStatuses);
    return statusSummary.getMixedStatusValue();
  }

  /** standard events list */
  getProfileEvents() {
    this.datatableLoading.set(true);
    this.subs.sink = this.getEventsList
      .watch(
        {
          profileId: this.profileId!,
          deviceId: this.deviceId,
        },
        { fetchPolicy: 'cache-and-network' },
      )
      .valueChanges.subscribe({
        next: ({ data, loading }) => {
          const { profile } = data;

          if (profile && profile.events) {
            this.events.set(cloneDeep(profile.events));
            const statuses = [
              PlaylistStatus.Published,
              PlaylistStatus.ReadyToPublish,
            ];
            this.events().forEach((c) => {
              c.playlists = c.playlists.filter((x) =>
                statuses.includes(x.status),
              );
            });
            console.log('events', this.events);
            this.total = this.events.length;
          } else {
            this.total = 0;
            this.events.set([]);
          }
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('getProfileEvents error', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });
        },
      });
    this.datatableLoading.set(false);
  }

  /**
   * Add event dialog
   * @param form null on first call, valued when user goes back from STEP 2
   */
  openCreateEventDialog(form?: ICreateChannelForm) {
    const modalRef = this.modalService.open(CreateEventDialogComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    if (this.profileId) {
      modalRef.componentInstance.profileId = this.profileId;
      modalRef.componentInstance.deviceId = this.deviceId;
    }

    modalRef.result
      .then(async (values: ICreateChannelForm) => {
        console.log('values', values);

        values.userId = this.currentUserService.currentUser!.id;
        values.eventDeviceId = this.deviceId;

        const { name, description, layoutId, type, eventStart, eventEnd } =
          values;
        const input: CreateChannelInput = {
          name,
          description,
          layoutId,
          profileId: this.currentUserService.getCurrentProfileId(),
          type: ChannelType.Event,
          eventStart,
          eventEnd,
          eventDeviceId: this.deviceId,
        };
        console.log('create event input:', input);

        const { data } = await lastValueFrom(
          this.channelService.createChannel(input),
        );
        const event = data?.createChannel.channel;

        if (event) {
          console.log('saved event');
          // this.modal.close(event);
        }
        // added, refresh UI
        this.getEvents();
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  navigateToChannel(channel: Channel) {
    if (channel?.id && this.profileId) {
      const id = this.encryptionService.encrypt(channel.id);
      const profileId = this.encryptionService.encrypt(this.profileId);

      if (this.currentUserService.canManageChannels && profileId) {
        this.router.navigate(['manage', id, profileId], {
          relativeTo: this.route,
        });
        // this.router.navigate(['/channel/manage', id, profileId]);
      } else if (this.currentUserService.canViewChannels) {
        // TODO: test this use case (not yet tested)
        this.router.navigate(['detail', id], {
          relativeTo: this.route,
        });
        // this.router.navigate(['/channel/detail', id]);
      } else {
        this.toasterService.error('ERROR_USER_NO_PERMISSION');
      }
    } else {
      this.toasterService.error('UNKNOWN_ERROR');
    }
  }
  navigateOldToChannel({ type, row }: IDatatableRowActivateArgs) {
    if (type === DatatableRowActivationType.CLICK) {
      const channel: Channel = row;
      if (channel?.id && this.profileId) {
        const id = this.encryptionService.encrypt(channel.id);
        const profileId = this.encryptionService.encrypt(this.profileId);

        if (this.currentUserService.canManageChannels && profileId) {
          this.router.navigate(['manage', id, profileId], {
            relativeTo: this.route,
          });
          // this.router.navigate(['/channel/manage', id, profileId]);
        } else if (this.currentUserService.canViewChannels) {
          // TODO: test this use case (not yet tested)
          this.router.navigate(['detail', id], {
            relativeTo: this.route,
          });
          // this.router.navigate(['/channel/detail', id]);
        } else {
          this.toasterService.error('ERROR_USER_NO_PERMISSION');
        }
      } else {
        this.toasterService.error('UNKNOWN_ERROR');
      }
    }
  }

  onRowClick(e: unknown) {
    const channel = e as Channel;
    console.log('onRowClick', channel);
    if (this.redirectOnSelect) {
      this.slidePanelService.setPanelComponentId(channel.id);
      this.navigateToChannel(channel);
    } else {
      if (channel?.id) {
        this.slidePanelService.setPanelComponentId(channel.id);
        this.channelSelect.emit(channel);
      }
    }
  }

  completeChannelTableData(rawChannels: ChannelsForChannelListFragment[]) {
    const channelRows: ChannelRow[] = [];
    for (const channel of rawChannels) {
      const device = channel.devices.length ? channel.devices[0] : null;
      const deviceStatus = device
        ? this.deviceDataService.getDeviceStatus(device.id)
        : DeviceStatusInfo.CtrlOffline;
      const screenStatus = !device
        ? ScreenStatus.NOT_AVAILABLE
        : deviceStatus === DeviceStatusInfo.Offline
          ? ScreenStatus.OFFLINE
          : this.deviceDataService.isDeviceScreenPoweredOn(device.id)
            ? ScreenStatus.DISPLAY_ON
            : ScreenStatus.DISPLAY_OFF;
      const appVersion = device?.signageOSDeviceTiming?.appletVersion || '';
      const activeAppVersion =
        device?.signageOSDeviceTiming?.activeAppletVersion || '';
      const versionStatus =
        screenStatus === ScreenStatus.NOT_AVAILABLE
          ? ScreenAppStatus.NOT_AVAILABLE
          : appVersion !== device?.signageOSDeviceTiming?.activeAppletVersion
            ? ScreenAppStatus.OUTDATED
            : ScreenAppStatus.UPDATED;
      const appVersionStatus =
        screenStatus === ScreenStatus.NOT_AVAILABLE
          ? screenStatus
          : `${appVersion} : ${activeAppVersion} : ${versionStatus}`;

      channelRows.push({
        ...channel,
        status: this.getChannelStatus(channel),
        deviceStatus,
        screenStatus,
        appVersionStatus,
        isActionInProgress: false,
        actionMessage: '',
        controlActionMessage: '',
      });
    }

    return channelRows;
  }

  /** opens popup to delete a channel */
  deleteWithPopup(channel: ChannelRow) {
    this.openDeleteChannelDialog(channel);
  }

  openDeleteChannelDialog(channel: EventForListFragment) {
    if (channel) {
      const modal = this.modalService.open(DeleteChannelDialogComponent, {
        backdrop: 'static',
      });
      modal.componentInstance.channel = channel;
      modal.result
        .then(() => {
          this.delete(channel);
        })
        .catch(() => {})
        .finally(() => {});
    }
  }

  /** delete a channel without showing a popup */
  delete(channel: EventForListFragment) {
    if (channel && channel.id) {
      this.subs.sink = this.deleteChannelGQL
        .mutate({
          id: channel.id,
        })
        .subscribe({
          next: ({ data }) => {
            if (data && data.deleteChannel.isSuccessful) {
              const index = this.events().findIndex((x) => x.id === channel.id);
              if (index > -1) {
                this.events().splice(index, 1);
                this.toasterService.success('DELETE_CHANNEL_SUCCESS');
              }
            } else {
              this.toasterService.error('UNKNOWN_ERROR');
            }
          },
          error: (error: ApolloError) => {
            error.graphQLErrors.forEach((gqlError) => {
              console.error('delete event error', gqlError);
              this.toasterService.handleGqlError(gqlError);
            });
          },
        });
    }
  }
}

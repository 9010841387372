function getArrayIndex(requestedIndex: number, arrayLength: number) {
  if (arrayLength > 0) {
    while (requestedIndex < 0) {
      requestedIndex = requestedIndex + arrayLength;
    }
    return requestedIndex % arrayLength;
  }
  return requestedIndex;
}

export function arrayItemMove<T>(
  fromIdx: number,
  toIdx: number,
  fromArray: T[],
  toArray?: T[]
) {
  if (!toArray) {
    toArray = fromArray;
  }
  if (fromArray.length > 0) {
    const oldIdx = getArrayIndex(fromIdx, fromArray.length);
    const newIdx =
      toArray.length > 0 ? getArrayIndex(toIdx, toArray.length) : 0;
    toArray.splice(newIdx, 0, fromArray.splice(oldIdx, 1)[0]);
  }

  return fromArray; // for testing purposes
}
/* test 

const a = ['a', 'b', 'c', 'd', 'e'];
console.log('start ', a);
// console.log(' 2 % 5:', getArrayIndex(2, 5));
// console.log('-3 % 5:', getArrayIndex(-3, 5));
// console.log(' 7 % 5:', getArrayIndex(7, 5));
console.log('4 to 2:', arrayItemMove(4, 2, a));
console.log('2 to 4:', arrayItemMove(2, 4, a));
const b: string[] = [];
console.log('new a :', arrayItemMove(2, 4, a, b));
console.log('b     :', b);
*/

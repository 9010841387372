import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, Subject, interval, takeUntil } from 'rxjs';

@Injectable()
export class PauseableTimerService {
  private elapsedTime = 0; // Total elapsed time in milliseconds
  private intervalTime = 1000; // Interval time in milliseconds
  private isRunning = false;

  private timerSubject = new BehaviorSubject<number>(0); // Emits elapsed time
  private stop$ = new Subject<void>(); // Used to stop the timer
  private resolvePromise: ((value: void) => void) | null = null;
  private currentPromise: Promise<void> | null = null; // Persisted promise

  get elapsedTime$() {
    return this.timerSubject.asObservable(); // Observable for the elapsed time
  }

  elapsedTimeValue = toSignal(this.timerSubject.asObservable()); // Signal for the elapsed time

  /**
   * Starts the timer and resolves a promise when the timer reaches the target value.
   * @param duration Time (in ms) when the promise should resolve.
   * @param resumeFrom Time to resume from (in ms).
   * @param intervalTime Interval for the timer update (in ms).
   * @returns A promise that resolves when the timer reaches the targetTime.
   */
  start(
    duration: number,
    resumeFrom: number = 0,
    intervalTime: number = 100,
  ): Promise<void> {
    this.elapsedTime = resumeFrom; // Reset elapsed time
    this.intervalTime = intervalTime;
    this.isRunning = true;

    // Stop any previous timers
    this.stop$.next();

    if (!this.currentPromise) {
      this.currentPromise = new Promise<void>((resolve) => {
        this.resolvePromise = resolve;
      });
    }

    interval(this.intervalTime)
      .pipe(takeUntil(this.stop$))
      .subscribe(() => {
        this.elapsedTime += this.intervalTime;
        this.timerSubject.next(this.elapsedTime);

        if (this.elapsedTime >= duration) {
          this.stop();
          if (this.resolvePromise) {
            this.resolvePromise(); // Resolve the promise
            this.resolvePromise = null;
            this.currentPromise = null; // Clear the persisted promise
          }
        }
      });

    return this.currentPromise;
  }

  pause() {
    if (!this.isRunning) return;

    this.isRunning = false;
    this.stop$.next(); // Stop the interval
  }

  resume(duration: number) {
    if (this.isRunning) return;

    this.isRunning = true;
    this.stop$ = new Subject<void>(); // Reset the stop signal
    this.start(duration, this.elapsedTime, this.intervalTime); // Resume the timer with remaining time
  }

  reset() {
    this.isRunning = false;
    this.stop$.next(); // Stop the interval
    this.elapsedTime = 0;
    this.timerSubject.next(this.elapsedTime); // Emit the reset value
    this.currentPromise = null; // Clear the persisted promise
    this.resolvePromise = null; // Clear the resolve function
  }

  stop() {
    this.isRunning = false;
    this.stop$.next(); // Stop the interval
  }
}

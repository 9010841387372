import {
  Injectable,
  Signal,
  WritableSignal,
  computed,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { AuthService } from '@auth0/auth0-angular';
import { Maybe, MeProfileFragment } from '@designage/gql';
import { localStorageKeys } from '@desquare/constants';
import { environment } from '@desquare/environments';
import { IProfile, IUser } from '@desquare/interfaces';
import { LocalStorageService } from 'ngx-webstorage';
import { EMPTY, Observable, lastValueFrom } from 'rxjs';
import {
  sessionUser,
  sessionProfile,
  sessionUserProfiles,
  setAppName,
  currentDateTimeMomentSignal,
} from './session';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  get isAuthenticated$() {
    return this.authService.isAuthenticated$;
  }

  profiles = computed(() => sessionUserProfiles());
  profileId = computed(() => sessionProfile()?.id);
  profile = computed(() => sessionProfile());
  user = computed(() => sessionUser());

  currentMoment = computed(() => currentDateTimeMomentSignal());

  /** TEST ONLY caches expired token */
  private auth0Token: Signal<string | undefined>; // = signal(undefined);
  /** Observable */
  private auth0Token$: Observable<string>;

  /* NOTE: this service cannot have access to graphql queries because it's used in graphql.module*/
  constructor(
    private authService: AuthService,
    private localStorageService: LocalStorageService,
  ) {
    this.auth0Token$ = this.getAuth0TokenObs();

    // test only, never use in production since the observable is not updating the token
    // only way to update the token is regenerate the observable from inside service
    this.auth0Token = toSignal(this.auth0Token$);
  }

  /** returns Auth0 token as an observable. NOTE: the value will never renew in the observable */
  getAuth0TokenObs() {
    return this.authService.getAccessTokenSilently({
      authorizationParams: {
        audience: environment.auth0.apiAudience,
      },
    });
  }

  async getAuth0Token() {
    // console.log('getAuth0Token');
    try {
      return await lastValueFrom(this.auth0Token$);
    } catch (exc) {
      // console.error('token error', exc);
      return null;
    }
  }

  /** TEST ONLY get possibly expired token from signal or await lastValueFrom promise */
  async TEST_getCachedTokenOrAwait() {
    const cachedToken = this.auth0Token();

    return !!cachedToken && cachedToken !== ''
      ? cachedToken
      : await lastValueFrom(this.auth0Token$);
  }

  origin = environment.urls.designageApp;
  initLogin(origin: string) {
    this.origin = origin;
  }

  initAppName(appName: 'cms' | 'wt') {
    setAppName(appName);
  }
  /** Auth0 loginWithRedirect */
  login(target: string = '/') {
    const redirectUri = `${this.origin}/auth0-callback`;

    return this.authService.loginWithRedirect({
      authorizationParams: {
        audience: environment.auth0.apiAudience,
        redirect_uri: redirectUri,
      },
      appState: { target }, // https://community.auth0.com/t/redirect-uri-issue-after-successful-login-with-auth0-auth0-angular/58111/2
    });
  }

  logout(app: 'cms' | 'wt') {
    return this.authService.logout({
      logoutParams: {
        returnTo:
          app === 'cms'
            ? environment.urls.designageApp
            : environment.urls.watchtowerApp,
      },
    });
  }

  /** fast sync call */
  getCurrentProfileId(): string | undefined {
    return this.localStorageService.retrieve(
      localStorageKeys.ACTIVE_PROFILE_ID,
    );
  }
}

import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
  signal,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
} from '@angular/forms';
import { ProfileLocationsFragment } from '@designage/gql';
import { ILocationForm } from '@desquare/interfaces';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { LocationSelectionComponent } from '../location-selection/location-selection.component';
import { LocationFormComponent } from '../location-form/location-form.component';
import { Point } from 'mapbox-gl';
import { LocationsStore } from '@desquare/stores';

interface ITab {
  title: string;
  isCreateNewLocation: boolean;
}

const TAB_EXISTING = 'tabExisting';
const TAB_NEW = 'tabNew';

@Component({
  standalone: true,
  imports: [
    NgTemplateOutlet,
    FormsModule,
    TranslateModule,
    NgbNavModule,
    LocationSelectionComponent,
    LocationFormComponent,
  ],
  selector: 'app-location-tab-selection',
  template: `
    <nav
      ngbNav
      #nav="ngbNav"
      class="nav-tabs"
      [activeId]="selectedTab()"
      (activeIdChange)="onTabChanged($event)"
    >
      <ng-container ngbNavItem="tabExisting" [destroyOnHide]="true">
        <a ngbNavLink>{{ 'ADD_TO_EXISTING_LOCATION' | translate }}</a>
        <ng-template ngbNavContent>
          <ng-container
            *ngTemplateOutlet="addToExistingLocation"
          ></ng-container>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem="tabNew" [destroyOnHide]="true">
        <a ngbNavLink>{{ 'CREATE_NEW_LOCATION' | translate }}</a>
        <ng-template ngbNavContent>
          <ng-container *ngTemplateOutlet="createNewLocation"></ng-container>
        </ng-template>
      </ng-container>
    </nav>

    <div [ngbNavOutlet]="nav"></div>

    <ng-template #addToExistingLocation>
      <app-location-selection
        class="d-block my-2"
        [profileId]="profileId"
        [selectedLocationId]="defaultLocationId"
        [selectedLocationName]="defaultLocationName"
        [isHideAddLocationButton]="true"
        [isHideEditLocationButton]="false"
        [parentFormGroup]="parentFormGroup"
        (editLocationClicked)="editLocation($event)"
        (selectionChange)="setLocation($event)"
        (createNewLocation)="showCreateLocationTab($event)"
      />

      <fieldset [disabled]="!isEditMode">
        @if (parentFormGroup) {
          <app-location-form
            [showLocationName]="showLocationName()"
            [showLocationSearch]="showLocationSearch()"
            [location]="selectedLocation()"
            [showFooter]="false"
          />
        }
      </fieldset>
    </ng-template>

    <ng-template #createNewLocation>
      <fieldset [disabled]="!isEditMode">
        <app-location-form
          [showLocationName]="showLocationName()"
          [showLocationSearch]="showLocationSearch()"
          [location]="isNewLocation() ? null : selectedLocation()"
          [locationName]="locationName()"
          [isCreate]="true"
          [showDeleteButton]="false"
          (locationSaved)="locationCreated($event)"
        />
      </fieldset>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationTabSelectionComponent implements OnInit {
  locationsStore = inject(LocationsStore);
  @Input() profileId!: string;
  @Input() defaultLocationId!: string;
  @Input() defaultLocationName!: string;
  @Input() parentFormGroup?: FormGroup;

  @Output() locationChanged = new EventEmitter<ILocationForm>();
  @Output() newLocation = new EventEmitter<boolean>();
  @Output() valid = new EventEmitter<boolean>();

  isFormValid = false;

  tabs: ITab[] = [
    { title: 'Add to existing location', isCreateNewLocation: false },
    { title: 'Create new location', isCreateNewLocation: true },
  ];

  selectedLocation = signal<ILocationForm>({});
  showLocationName = signal<boolean>(false);
  showLocationSearch = signal<boolean>(false);
  isEditMode = signal<boolean>(false);
  isNewLocation = signal<boolean>(false);
  selectedTab = signal<string>(TAB_EXISTING);
  locationName = signal<string>('');

  locationTabFormControl: FormControl = new FormControl();

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initFormControl();
  }

  initFormControl() {
    const FORM_CONTROL_NAME = 'locationTab';

    this.locationTabFormControl =
      (this.parentFormGroup?.controls[FORM_CONTROL_NAME] as FormControl) ??
      this.formBuilder.control(this.selectedTab());

    this.selectedTab.set(this.locationTabFormControl.value);
    this.setVariablesDependingOnTab(this.selectedTab());

    if (this.parentFormGroup) {
      this.parentFormGroup?.addControl(
        FORM_CONTROL_NAME,
        this.locationTabFormControl,
      );
    }
  }
  setLocation(e: ProfileLocationsFragment) {
    const selectedLocation: ILocationForm = { ...this.convertToLocation(e) };
    this.selectedLocation.set(selectedLocation);
  }
  private convertToLocation(fragment: ProfileLocationsFragment): ILocationForm {
    return {
      ...fragment,
      profileId: this.profileId,
      streetAddress1: fragment.streetAddress1 ?? undefined,
      streetAddress2: fragment.streetAddress2 ?? undefined,
      zip: fragment.zip ?? undefined,
      phoneNumber: fragment.phoneNumber ?? undefined,
      country: fragment.country ?? undefined,
      region: fragment.region ?? undefined,
      city: fragment.city ?? undefined,
      coordinates: new Point(
        fragment.coordinates?.x ?? 0,
        fragment.coordinates?.y ?? 0,
      ),
    };
  }

  locationCreated(location: ILocationForm) {
    console.log('Location created', location);
    this.selectedLocation.set(location);
    this.defaultLocationId = location.id ?? '';
    this.defaultLocationName = location.name ?? '';
    this.selectedTab.set(TAB_EXISTING);
    this.setVariablesDependingOnTab(TAB_EXISTING);
  }

  setVariablesDependingOnTab(tab: string) {
    if (tab === TAB_NEW) {
      this.isEditMode.set(true);
      this.isNewLocation.set(true);
      this.showLocationName.set(true);
      this.showLocationSearch.set(true);
    } else {
      this.isEditMode.set(false);
      this.isNewLocation.set(false);
      this.showLocationName.set(false);
      this.showLocationSearch.set(false);
    }
  }

  onTabChanged(tab: string) {
    this.selectedTab.set(tab);

    this.setVariablesDependingOnTab(tab);

    this.locationTabFormControl.setValue(tab);

    // These are the form group names from the child components
    const LOCATION_SELECTION = 'locationSelection';
    const LOCATION = 'location';

    // clear both form groups on tab change
    this.parentFormGroup?.removeControl(LOCATION_SELECTION);
    this.parentFormGroup?.removeControl(LOCATION);
  }
  editLocation(isEdit: boolean) {
    if (isEdit) {
      this.isEditMode.set(true);
      this.showLocationSearch.set(true);
    }
  }

  updateLocation(location: ILocationForm) {
    this.valid.emit(this.isFormValid);
    this.locationChanged.emit(location);
  }

  showCreateLocationTab(locationName: string) {
    this.locationName.set(locationName);
    this.onTabChanged(TAB_NEW);
  }
}
